
<div class="no-payment-container">
  <div class="no-payment-icon">
    <span class="fa fa-credit-card fa-8x col1 row1"></span>
  </div>
  <div class="no-payment-content">
    <div class="no-payment-title">
      <div class="no-payment-main-title">No default payment method selected.</div>
      <div class="no-payment-subtitle">Please set a default payment method to view search results.</div>
    </div>
    <div class="no-payment-button"
         data-toggle="modal"
         data-target="#createPaymentMethod"
         data-backdrop="none"
         (click)="onAddPaymentMethodClick()">
      <div class="font-weight-bold text-center no-payment-text">Update default payment method</div>
    </div>
  </div>
</div>

<div>
  <app-no-payment-method-modal (addedPaymentMethod)="onAddPaymentMethodClick()"></app-no-payment-method-modal>
</div>
