import { Pipe, PipeTransform } from '@angular/core';
import { AddressViewModel } from '../../interfaces/address/addressViewModel';

@Pipe({
  name: 'address'
})
export class AddressPipe implements PipeTransform {

  public transform(address: AddressViewModel): string {
    const addressString =
      address.address2 ?
        `${address.address1} ${address.address2}, ${address.city}, ${address.state} ${address.zip}` :
        `${address.address1}, ${address.city}, ${address.state} ${address.zip}`;
    return addressString;
  }

}
