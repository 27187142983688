<div [ngClass]="'account-header'">Transaction History </div>

  <section *ngIf="hasError === false || hasTransactions === true" style="margin-bottom:150px;">
    <div [ngClass]="'action-btn top'">
      <div [ngClass]="'btn-left'">
        <button [disabled]="currentPage === 1 || totalRows === 0" [ngClass]="{'more-records' : previousRecords, 'previous': !previousRecords || previousRecords}" (click)="firstPage()">First</button>
        <button [disabled]="!previousRecords" [ngClass]="{'more-records' : previousRecords, 'previous': !previousRecords || previousRecords}" (click)="previousPage()">Previous</button>
      </div>
      <div [ngClass]="'btn-mid'" *ngIf="currentPage > 0">{{currentPage}}/{{totalPageCount}}</div>
      <div [ngClass]="'btn-right'">
        <button [disabled]="!moreRecords" [ngClass]="{'more-records' : moreRecords}" (click)="nextPage()">Next</button>
        <button [disabled]="currentPage === totalPageCount || totalRows === 0" [ngClass]="{'more-records' : moreRecords}" (click)="lastPage()">Last</button>
      </div>
    </div>
    <table>
      <thead class="grid-header">
        <tr>
          <th (click)="sortList('Address')">Address<span [ngClass]="'fas fa-sort left8'"></span></th>
          <th (click)="sortList('Insurer')">Insurer<span [ngClass]="'fas fa-sort left8'"></span></th>
          <th (click)="sortList('Date')">Date<span [ngClass]="'fas fa-sort left8'"></span></th>
          <th class="popup" (click)="showPopup()" (mouseleave)="hidePopup()">
            <span>Amount</span>
            <span [ngClass]="'popup-text'" id="amountPopup">
              This displays the payment total that was charged. Any refunds will appear in red like the following <span [ngClass]="'red-text'">
                ($0.00).
              </span>
            </span>
            <span [ngClass]="'fas fa-exclamation-circle left8'"></span>
          </th>
          <th>Card #</th>
          <th [ngClass]="'transaction-id-header'">Transaction ID</th>
          <th *ngIf="this.showLossPayee === true" [ngClass]="'update-mortgagee-header'">Update Mortgagee</th>
        </tr>
      </thead>
      <tbody *ngIf="totalRows > 0">
        <tr *ngFor="let transaction of this.transactionsHistory">
          <td [innerHTML]="transaction.searchAddress | address"></td>
          <td [innerHTML]="transaction.insurer"></td>
          <td [innerHTML]="transaction.eoiPurchaseDate | date : 'shortDate' "></td>
          <td *ngIf="transaction.isRefunded === false" [innerHTML]="transaction.amount | currency"></td>
          <td *ngIf="transaction.isRefunded" [ngClass]="{'red-text': transaction.isRefunded }">({{ 0 | currency }})</td>
          <td [innerHTML]="transaction.lastFourDigits"></td>
          <td [innerHTML]="transaction.transactionId" [ngClass]="'transaction-id'"></td>
          <td  *ngIf="transaction.isLossPayeeSupported && this.showLossPayee === true "><button (click)="updateMortgagee(transaction.eoiPurchaseId)" [ngClass]="'primary'">Update</button></td>
          <td  *ngIf="!transaction.isLossPayeeSupported && this.showLossPayee === true" [ngClass]="'update-mortgagee-not-supported-message'">Not available for this transaction</td>
        </tr>
      </tbody>
    </table>
    <div [ngClass]="'action-btn btm'">
      <div [ngClass]="'btn-left'">
        <button [disabled]="currentPage === 1 || totalRows === 0" [ngClass]="{'more-records' : previousRecords, 'previous': !previousRecords || previousRecords}" (click)="firstPage()">First</button>
        <button [disabled]="!previousRecords" [ngClass]="{'more-records' : previousRecords, 'previous': !previousRecords || previousRecords}" (click)="previousPage()">Previous</button>
      </div>
      <div [ngClass]="'btn-mid'" *ngIf="currentPage > 0">{{currentPage}}/{{totalPageCount}}</div>
      <div [ngClass]="'btn-right'">
        <button [disabled]="!moreRecords" [ngClass]="{'more-records' : this.moreRecords===true}" (click)="nextPage()">Next</button>
        <button [disabled]="currentPage === totalPageCount  || totalRows === 0" [ngClass]="{'more-records' : this.moreRecords===true}" (click)="lastPage()">Last</button>
      </div>
    </div>
  </section>

