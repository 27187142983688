import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AccountInfo } from '@azure/msal-browser';
import { Router, ActivatedRoute, CanActivate } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { HttpResponse } from '@angular/common/http';

import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { CreateUserRequest } from '../../../interfaces/users/create-user-request';
import { UsersService } from '../../../services/users/users.service';
import { AccountStatusService } from '../../../services/accountStatus/account-status.service';
import { statusType } from '../../../interfaces/users/statusType';
import { ApiResponse } from '../../../interfaces/api/apiResponse';
import { GetUserProfileResponse } from '../../../interfaces/users/get-user-profile-response';



@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss']
})
export class AccountDetailsComponent implements OnInit, OnDestroy, CanActivate {

  //TODO: Add Spinner when page loads and when making request
  //TODO: Hide sign out button when on page two
  private _status: string;
  private _canActivate = false;
  private _subs: Subscription[] = [];

  public accountDetailsForm: FormGroup;
  public msalFirstName: string;
  public msalEmail: string;
  public msalLastName: string;
  public msalUser: AccountInfo;
  public userSecondPage: CreateUserRequest = <CreateUserRequest>{};
  public extensionFormat = '^-?[0-9]\\d*(\\.\\d{1,2})?$';
  public isSubmitting = false;
  public errorMessages: string;
  public displayValidationErrors = false;
  public accountStatus = '';


  constructor(
    private _formBuilder:           FormBuilder,
    private _router:                Router,
    private _activatedRoute:        ActivatedRoute,
    private _authService:           MsalService,
    private _userService:           UsersService,
    private _accountStatusService:  AccountStatusService,
    public _toastr:                 ToastrService
  ) {
    this.canActivate();
  }

  //  We are checking for an MSAL account. The RegistrationGuard is the entry point and account status is set there. If the userProfile is nothing
  //  the status is set to '' .
  canActivate() {
    this.msalUser = this._authService.instance.getActiveAccount();

      this._canActivate = this.msalUser === null;
      return this._canActivate ;
  }

  ngOnInit(): void {
    if (this._canActivate) {
      this._router.navigate(['/home']);
    }

    if (this.msalUser) {
      this.msalFirstName = this.msalUser.idTokenClaims['given_name'].toString();
      this.msalEmail = this.msalUser.username;
      this.msalLastName = this.msalUser.idTokenClaims['family_name'].toString();
    }

    this.accountDetailsForm = this._formBuilder.group({
      name: this._formBuilder.group({
        first: [{ value: this.msalFirstName, disabled: true }, [Validators.required]],
        last: [{ value: this.msalLastName, disabled: true }, Validators.required],
      }),
      email: [{ value: this.msalEmail, disabled: true }, [Validators.required, Validators.email]],
      phone: this._formBuilder.group({
        workPhone: ['', [Validators.required, Validators.min(10)]],
        extension: ['', [Validators.maxLength(10), Validators.pattern(this.extensionFormat)]],
      }),
      companyName: ['', [Validators.required, Validators.maxLength(50)]],
      jobTitle: ['', [Validators.required, Validators.maxLength(50)]]
    })

  }

  ngOnDestroy(): void {
    if (this._subs && this._subs.length > 0) {
      while (this._subs.length > 0) {
        let sub = this._subs.pop();
        sub.unsubscribe();
        sub = null;
      }
    }
  }

  public clearMessages(): void {
    this.errorMessages = '';
  }

  mapSecondStepResponse() {
    if (this.accountDetailsForm !== null) {

      var phoneTrim = this.accountDetailsForm.get('phone').get('workPhone').value;
      phoneTrim = phoneTrim.replace(/\D/g, '');
      var phoneConvert: number = +phoneTrim;
      var extensionConvert: number = +this.accountDetailsForm.get('phone').get('extension').value;

      this.userSecondPage.firstName = this.accountDetailsForm.get('name').get('first').value;
      this.userSecondPage.lastName = this.accountDetailsForm.get('name').get('last').value;
      this.userSecondPage.email = this.accountDetailsForm.controls['email'].value;
      this.userSecondPage.company = this.accountDetailsForm.controls['companyName'].value;
      this.userSecondPage.title = this.accountDetailsForm.controls['jobTitle'].value;
      this.userSecondPage.phone = phoneConvert;
      this.userSecondPage.phoneExtension = extensionConvert;
    }
  }

  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }
  scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-invalid');
    this.scrollTo(firstElementWithError);
  }

  submit() {
    if (!this.accountDetailsForm.valid) {
      this.displayValidationErrors = true;
      this.scrollToError();
      return;
    }

    this.isSubmitting = true;
    this.clearMessages();

    this.mapSecondStepResponse();

    this._userService.registerUser(this.userSecondPage)
      .subscribe((result: HttpResponse<ApiResponse<GetUserProfileResponse>>) => {

        if (result['status'] === 201) {
          this.accountStatus = this._accountStatusService.setAccountStatus((result['body']['data']['profile']));

          switch (this.accountStatus) {
            case 'Pending':
            case 'Rejected':
            case 'Locked':
              this._router.navigate(['/account-status'], { queryParams: { accountStatus: statusType[this.accountStatus] } });
              break;
            default:
              this._router.navigate(['/home']);
              break;
          }

        } else {
          this._toastr.error('Failure to register user');
          this._router.navigate(['/error']);
        }
      },
        (err) => {
          console.error(err);
        });

  }

}
