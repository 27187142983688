<div class="content {{enableUI}}">
  <h1>404 Error</h1>
  <div>
    <h2>Page Not Found</h2>
    <p>Our Apologies, but the page you are looking for could not be found.</p>
    <h3>For technical support contact <a href="mailto:MERSHOI.SUPPORT@ice.com">MERS HOI Hub Support</a>: </h3>
    <div class="subtitle-error-400-contact">
      <span class="fa fa-phone-alt nex-fa-span" aria-hidden="true"></span><span class="error-pad-left">(888) 680-6377</span> <br />
      <span class="fa fa-envelope nex-fa-span" aria-hidden="true"></span><span class="error-pad-left"><a href="mailto:MERSHOI.SUPPORT@ice.com">MERSHOI.SUPPORT@ice.com</a></span> <br />
      <span class="far fa-clock nex-fa-span" aria-hidden="true"></span><span class="error-pad-left">Monday-Friday</span> <span class="error-pad-left-double"> 8:00 AM - 6:00 PM ET</span>
    </div>
  </div>
</div>
