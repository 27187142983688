import { Component, Input, ViewChild, Output, EventEmitter, ElementRef, AfterViewInit} from '@angular/core';
import { FileValidator } from './services/file-validator.service';
import { FileSizeUnit } from './file-size-unit';
import { LossPayeeService } from '../../services/loss-payee/loss-payee.service';

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss']
})
export class UploaderComponent implements AfterViewInit {
  @Input() acceptedFileTypes = '.pdf';
  @Input() canRestrictFileTypes = true;
  @Input() canAllowMultipleFiles = false;
  @Input() disableUploadButtonAfter = 1;
  @Input() canDisableUploadPButton = true;
  @Input() uploadedFile: Set<File>;
  @Output() fileAdded = new EventEmitter();
  @ViewChild('file') file: ElementRef;

  public readonly fileValidator: FileValidator;
  public files: Set<File> = new Set();
  public validations: Array<string>;

  private readonly _fileSizeErrorMessageBase: string;
  private _shouldDisableUploadButton = false;

  constructor(private _lossPayeeService: LossPayeeService) {
    this.fileValidator = new FileValidator(this.acceptedFileTypes);
    this.validations = new Array<string>();
    this._fileSizeErrorMessageBase = 'File size cannot exceed';
  }


  ngAfterViewInit(): void {
    if (this._shouldDisableUploadButton === true) {
      this.disableUploadButton();
    }
  }

  public onFilesAdded(): void {
    const files: { [key: string]: File } = this.file.nativeElement.files;
    for (const key in files) {
      if (!isNaN(+key) && this.evaluateFile(files[key])) {
        this.files.add(files[key]);
        this.validations = new Array<string>();
        this.fileAdded.emit(this.files);
        this._lossPayeeService.filesToUpload = this.files;
        this.disableUploadButton();
      }
    }
  }

  public evaluateFile(file: File): boolean {
    this.validations = [];
    if (this.fileValidator.isValidFileSize(file) && this.fileValidator.isValidFileType(file)) {
      return true;
    } else if (!this.fileValidator.isValidFileSize(file)) {
      // eslint-disable-next-line max-len
      this.validations.push(`${this._fileSizeErrorMessageBase} ${this.fileValidator.maxFileSize} ${FileSizeUnit[this.fileValidator.maxFileSizeUnit]}`);
    } else if (!this.fileValidator.isValidFileType(file)) {
      this.validations.push(`Invalid file format. Please select a valid ${this.acceptedFileTypes.toUpperCase()}  and try again.`);
    } else {
      return false;
    }
  }

  public addFiles(): void {
    this.file.nativeElement.files = null;
    this.file.nativeElement.click();
  }

  public removeFile(file: File): void {
    this.files.delete(file);
    this.fileAdded.emit(this.files);
    this.file.nativeElement.value = '';
    this.enableUploadButton();
  }

  public resetUploader(): void {
    this.validations = new Array<string>();
    this.file.nativeElement.files = null;
    this.file.nativeElement.value = '';
    this.files = new Set();
    this.enableUploadButton();
  }

  private disableUploadButton(): void {
    let button = document.getElementById('uploadButton');
    button.setAttribute('disabled', 'true');
  }

  private enableUploadButton(): void {
    let button = document.getElementById('uploadButton');
    button.removeAttribute('disabled');
  }
}
