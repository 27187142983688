import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { lastValueFrom } from 'rxjs';
import { statusType } from '../../interfaces/users/statusType';
import { UserProfile } from '../../interfaces/users/user-profile';
import { UsersService } from '../../services/users/users.service';

@Injectable({
  providedIn: 'root'
})
export class MyAccountGuard implements CanActivate {

  private _accountStatus = '';
  public userProfile: UserProfile;

  constructor(
    private _userService: UsersService,
    private _router: Router,
    private _msalService: MsalService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {

    return this.accountStatusIsOK();

  }

  private accountStatusIsOK(): Promise<boolean> {

    let msalUser = this._msalService.instance.getActiveAccount();

    if (!msalUser) {
      return Promise.resolve(false);
    }    
   
    return new Promise((resolve) => {

      lastValueFrom(this._userService.fetchUserProfile()).then( () => {
        this._accountStatus = this._userService.accountStatus;

        if (this._accountStatus === 'Pending' || this._accountStatus === 'Rejected' || this._accountStatus === 'Locked') {
          resolve(false);
          this._router.navigate(['/account-status'], { queryParams: { accountStatus: statusType[this._accountStatus] } });
        }
        else if (this._userService.termsAndConditionsRequired !== null) {
          resolve(false);
        } else {
          resolve(true);
        }

        if (this._userService.termsAndConditionsRequired !== null) {
          resolve(false);
          this._router.navigate(['/terms'], { queryParams: { termsId: this._userService.termsAndConditionsRequired.termsAndConditionsId } });
        } else {
          resolve(true);
        }
      })
    });
  }

}
