import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppInsightsService {

  instance: ApplicationInsights;
  constructor() {
    this.instance = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsights.instrumentationKey,
        enableAutoRouteTracking: true,
        enableCorsCorrelation: true
      }
    });

    this.instance.loadAppInsights();
    this.instance.trackPageView();

  }

  logEvent(name: string, properties?: { [key: string]: object }): void {
    this.instance.trackEvent({ name: name }, properties);
  }

  logException(exception: Error, severityLevel?: number): void {
    this.instance.trackException({ exception: exception, severityLevel: severityLevel });
  }

  logTrace(message: string, properties?: { [key: string]: object }): void {
    this.instance.trackTrace({ message: message }, properties);
  }

} 
