import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { StatesService } from '../../../../../services/states/states.service';
import { State } from '../../../../../interfaces/search/state';
import { ModalService } from '../../../../../services/modals/modals.service';
import { UsersService } from '../../../../../services/users/users.service';
import { CreateMortgageeRequest } from '../../../../../interfaces/mortgagee/create-mortgagee-request'
import { CreateMortgageeResponse } from '../../../../../interfaces/mortgagee/create-mortgagee-response';
import { MortgageeService } from '../../../../../services/mortgagee.service';
import { ErrorMessage } from 'src/app/shared/models/error-message.enum';
import { AddMortgageeSuggestionsModalComponent } from '../../add-mortgaee-suggestion-modal/add-mortgagee-suggestions-modal.component';
import { MortgageeViewModel } from 'src/app/interfaces/loss-payee/mortgagee-view-model';
import { MortgageeExistsModalComponent } from '../../mortgagee-exists-modal/mortgagee-exists-modal.component';

@Component({
  selector: 'app-new-mortgagee-request-modal',
  templateUrl: './new-mortgagee-request-modal.component.html',
  styleUrls: ['./new-mortgagee-request-modal.component.css']
})
export class NewMortgageeRequestModalComponent implements OnInit {
  @ViewChild(AddMortgageeSuggestionsModalComponent) _mortgageeSuggestionsModal: AddMortgageeSuggestionsModalComponent;
  @ViewChild(MortgageeExistsModalComponent) _mortgageeExistsModal: MortgageeExistsModalComponent;

  states: Array<State>;
  createMortgageeRequest: CreateMortgageeRequest;
  createMortgageeResponse: CreateMortgageeResponse;
  public modalForm: FormGroup;

  constructor(
    private _formBuilder: FormBuilder,
    private _statesService: StatesService,
    private _modalService: ModalService,
    private _usersService: UsersService,
    private _mortgageeService: MortgageeService,
    private _toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.modalForm = this.buildForm();
    this.createMortgageeRequest = {} as CreateMortgageeRequest;
    this.states = this._statesService.getStates();
  }

  buildForm(): FormGroup {
    return this._formBuilder.group({
      mortgageeName: ['', [Validators.required]],
      address1: ['', [Validators.required]],
      address2: [''],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      zip: ['', [Validators.required]]
    })
  }

  closeModal(id: string) {
    this._modalService.close(id);
    this.resetForm();
    this._mortgageeService.isRequestSent(true);
  }

  openModal(id: string) : void {
    this._modalService.open(id);
  }

  openRequestSentModal() {
    this.closeModal('newMortgageeRequest');
    this._modalService.open('requestSent');
  }

  public hasFieldValidationError(
    controlName: string,
    errorCode: string,
    formGroup: FormGroup
  ): boolean {
    return (
      (formGroup.get(controlName).touched) &&
      formGroup.get(controlName).hasError(errorCode)
    );
  }

  buildModalForm(): FormGroup {
    return this._formBuilder.group({
      mortgageeName: [null, Validators.required],
      addresses: this._formBuilder.array([this.buildAddressForm()])
    });
  }

  buildAddressForm(): FormGroup {
    return this._formBuilder.group({
      zip: [null, [Validators.required, Validators.minLength(5)]],
      streetAddressOne: [null, Validators.required],
      streetAddressTwo: [null],
      city: [null, Validators.required],  
      state: [null, Validators.required],
    });
  }

  submit(): void {

    if (!this.modalForm.valid) {
      this.markAllControlsAsTouched(this.modalForm);
      return;
    }
    
    let request = this.mapFormToModel();
    this._mortgageeService.getMortgageesByMortgageeRequest(request)
        .pipe(take(1))
        .subscribe(response => {
          let exactMatch = this._mortgageeService.findExactMortgageeMatch(response.data.mortgagees, request);
          if (exactMatch !== null && exactMatch !== undefined) {
            this.closeModal('newMortgageeRequest');
            this._mortgageeExistsModal.existingMortgagee = exactMatch;
            this._modalService.open('mortgagee-exists');
          } else {
            this._modalService.close('newMortgageeRequest');
            this.setUpSuggestionModal(request, response.data.mortgagees);
            this.openModal('add-mortgagee-suggestions');
            this._mortgageeSuggestionsModal.Success.subscribe((wasSuccesful: boolean) => {
              if (wasSuccesful) {
                this.resetForm();
              }
            });
          }
        },
        error => {
          this._toastr.error(ErrorMessage.GenericError);
        });
  }

  setUpSuggestionModal(addedMortgagee: CreateMortgageeRequest, mortgagees: MortgageeViewModel[]) {
    this._mortgageeSuggestionsModal.addedMortgagee = addedMortgagee;
    this._mortgageeSuggestionsModal.mortgagees = mortgagees;
    this._mortgageeSuggestionsModal.modalTitle = 'Request New Mortgagee';
    this._mortgageeSuggestionsModal.userId = this._usersService.userId;
    this._mortgageeSuggestionsModal.isFromLossPayee = false;
  }

  markAllControlsAsTouched(formGroup: FormGroup): void {
    if (!!formGroup) {
      Object.keys(formGroup.controls).forEach(formControlKey => {
        formGroup.controls[formControlKey].markAsTouched();
      });
    }
  }

  resetForm() {
    this.modalForm.reset();
  }

  private mapFormToModel(): CreateMortgageeRequest {
    this.createMortgageeRequest.action = 'Add';
    this.createMortgageeRequest.name = this.modalForm.controls.mortgageeName.value;
    this.createMortgageeRequest.address1 = this.modalForm.controls.address1.value;
    this.createMortgageeRequest.address2 = this.modalForm.controls.address2.value;
    this.createMortgageeRequest.city = this.modalForm.controls.city.value;
    this.createMortgageeRequest.state = this.modalForm.controls.state.value;
    this.createMortgageeRequest.zip = this.modalForm.controls.zip.value;


    return this.createMortgageeRequest;
  }


}
