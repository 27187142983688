import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Routes } from '@angular/router';
import { take } from 'rxjs';

import { FeatureFlagService } from '../../../services/feature-flag.service';
import { PaymentDataEvents } from '../../payment/payment-data.events/payment-data.events';


export enum accountOptions {
  PAYMENT = 0,
  TRANSACTIONS = 1,
  MORTGAGEE = 2
}
@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.css']
})

export class MyAccountComponent implements OnInit {

  private readonly _lossPayeeFeatureName = 'losspayee'
  private readonly _lossPayeeExpressFeatureName = 'losspayeeexpress'

  public accountOption: string;
  public openPaymentAfterRoute = false;
  public lossPayeeEnabled = false;
  public lossPayeeExpressEnabled = false;

  constructor(
    private _paymentDataEvents: PaymentDataEvents,
    private _activatedRoute: ActivatedRoute,
    private _featureFlagService: FeatureFlagService,
    private _router: Router) {}

  ngOnInit() {

    this._featureFlagService.isFeatureEnabled(this._lossPayeeFeatureName)
      .then(f => this.lossPayeeEnabled = f);
    this._featureFlagService.isFeatureEnabled(this._lossPayeeExpressFeatureName)
      .then(f => this.lossPayeeExpressEnabled = f);

    this.setAccountOption();
  }

  setAccountOption(): void {
    this._activatedRoute
      .queryParams
      .subscribe(params => {
        let openAfterRoute = params['openAfterRoute'];
        let setOption = params['setOption'];
        if (openAfterRoute) {
          this.openPaymentAfterRoute = true;
          this._paymentDataEvents.paymentOpenAfterRedirect(true);
          this.accountOption = this.accountOption = accountOptions[0].toString();
        } else if (setOption) {
          let optionNumber = Number(setOption);
          this.accountOption = accountOptions[optionNumber].toString();
        } else {
          this._router.navigate(['/my-account'], { queryParams: { setOption: 0 } });
        }
      });
  }

  filterInactiveOptions(currentAccountOption: string): boolean {
    return isNaN(Number(currentAccountOption)) && this.accountOption !== currentAccountOption;
  }
}
