import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { ApiResponse } from '../../interfaces/api/apiResponse';
import { PaymentMethod } from '../../interfaces/payment/get-payment-method-request';
import { PaymentMethodService } from '../../services/payment/payment.method.service';
import { UsersService } from '../../services/users/users.service';

@Injectable({
  providedIn: 'root'
})

export class PaymentResolver implements Resolve<Observable<HttpResponse<ApiResponse<PaymentMethod>>>> {

  constructor(private _usersService: UsersService, private _payment: PaymentMethodService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<HttpResponse<ApiResponse<PaymentMethod>>> {
    return this._payment.getPaymentMethod(this._usersService.userId);
  }
}
