<div *ngIf="this.requestSent === false" [ngClass]="{'container': this.showAllRecords === false, 'container-no-records': this.showAllRecords === true } ">
  <div [ngClass]="'header'" *ngIf="this.totalRecords > 0 && this.showAllRecords === false">We found your mortgagee</div>
  <div [ngClass]="'header'" *ngIf="this.totalRecords === 0 && this.showAllRecords === false">We couldn't find your mortgagee</div>
  <div [ngClass]="'header-all-records'" *ngIf="this.showAllRecords === true"> Don't see your mortgagee?</div>
  <p [ngClass]="'business'" *ngIf="this.showAllRecords === false">
    {{ business }}
  </p>
  <p [ngClass]="'address1'" *ngIf="this.showAllRecords === false && this.totalRecords > 0">
    {{ address1 }} &nbsp; <span *ngIf="address2.length>0">{{ address2 }} &nbsp;</span> {{ city }} &nbsp; {{ state }} &nbsp; {{ zip }}
  </p>

  <button id='addMortgagee' [ngClass]="'add-mortgagee-button'" *ngIf="this.showAllRecords === true || this.totalRecords === 0" (click)="openMortgageeRequestModal()">Add your mortgagee</button>
  <p [ngClass]="'footer'" *ngIf="this.showAllRecords === false && this.mortgageeAdded === false">If you have any other mortgagees you would like to verify, please use the search again, otherwise use Skip Setup below.</p>
  <p [ngClass]="'footer'" *ngIf="this.showAllRecords === false && this.mortgageeAdded === true">If you have any other mortgagees you would like to verify, please use the search again, otherwise use Finish Setup below.</p>
</div>
<app-new-mortgagee-request-modal (addMortgagee)="openMortgageeRequestModal()"></app-new-mortgagee-request-modal>
