<div class="modal-content">
  <div class="modal-header">
    <h2>New Payment Option</h2>
  </div>
  <div class="modal-body">
    <form novalidate [formGroup]="paymentMethodForm"
          class="form-group m-auto nex-typography payment-method-nickname">
      <div class="row">
        <div class="col-lg form-group m-1">
          <label for="cardNickname">Payment Nickname (Optional)</label>
          <input class="form-control nex-input-regular nex-input-regular payment-nickname-inp"
                 formControlName="cardNickname"
                 type="text"
                 name="cardNickname"
                 aria-labelledby="Nickname for the new card option"
                 id="cardNickname"
                 maxlength="50"
                 placeholder="Payment Nickname" />
        </div>
      </div>
    </form>
    <div #paymentFrame id="payment-form"></div>
  </div>
</div>
