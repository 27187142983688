<div class="search-container-main">
  <section>
    <app-policy-search [userHasPaymentMethod]="this.userHasPaymentMethod"></app-policy-search>
  </section>
  <section>
    <div id="search-results">
      <h2 id="search-header">{{searchHeader}}</h2>
      <p id="search-criteria" *ngIf="person !==null">Results for <strong>{{person | person}}</strong> and <strong>{{address | address}}</strong></p>
      <app-policy-search-results [userHasPaymentMethod]="this.userHasPaymentMethod" class="search-results-found"></app-policy-search-results>
    </div>
  </section>
</div>
