import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { environment } from '../../../environments/environment';
import { ApiResponse } from '../../interfaces/api/apiResponse'
import { AcceptTermsAndConditionsRequest } from '../../interfaces/termsAndConditions/acceptTermsAndConditionsRequest';
import { ErrorMessage } from '../../shared/models/error-message.enum';
import { FeatureFlagService } from '../../services/feature-flag.service';


@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit, OnDestroy {

  // eslint-disable-no-input-rename
  @Input('termsAndConditions') termsAndConditions: string;
  private _authService: MsalService;
  private _subs: Subscription[] = [];
  private _httpClient: HttpClient;
  private _termsAndConditions = {} as AcceptTermsAndConditionsRequest;
  private _route: ActivatedRoute;
  private _toastrService: ToastrService;
  private readonly _featureName = 'losspayee';
  private _showFeature = false;

  public hasReadTerms = false;
  public termsLoaded = false;
  public termsfailed = false;

  constructor(
    authService: MsalService,
    httpClient: HttpClient,
    private _router: Router,
    route: ActivatedRoute,
    toastrService: ToastrService,
    private _featureFlagService: FeatureFlagService
  ) {
    this._authService = authService;
    this._httpClient = httpClient;
    this._route = route;
    this._toastrService = toastrService;
  }

  ngOnInit(): void {
    this.getTerms();
    this._termsAndConditions.termsAndConditionsId = this._route.snapshot.queryParamMap.get('termsId');
    this._featureFlagService.isFeatureEnabled(this._featureName)
      .then(f => this._showFeature = f)
  }

  ngOnDestroy(): void {
    if (this._subs && this._subs.length > 0) {
      while (this._subs.length > 0) {
        let sub = this._subs.pop();
        sub.unsubscribe();
        sub = null;
      }
    }
  }

  acceptTerms() {
    if (!this.hasReadTerms) {
      this.toggleTermsMessage();
    } else {
      this._httpClient
        .post<ApiResponse<AcceptTermsAndConditionsRequest>>(`${environment.portalApiUrl}v1/users/termsandconditions/`, this._termsAndConditions)
        .toPromise()
        .then(
          () => {
            if (this._showFeature === true) {
              this._router.navigate(['home']);
            } else {
              this._router.navigate(['loss-payee']);
            }
          },
          error => {
            this._toastrService.error(ErrorMessage.GenericError);
          }
        )
    }
  }

  declineTerms(): void {
    this._authService.logout();
  }

  getTerms() {
    this._subs.push(this.downloadTerms()
      .subscribe(
        (result) => {
          if (result !== null) {
            result.text().then(
              (t: string) => {
                this.termsAndConditions = t;
                this.termsLoaded = true;
                this.termsfailed = false;
              }
            )
          } else {
            this._toastrService.error(ErrorMessage.TermsError);
            this.termsfailed = true;
          }

        }, e => {
          this.termsfailed = true;
          this._toastrService.error(ErrorMessage.TermsError);
        }));
  }

  readTerms() {
    this.hasReadTerms = !this.hasReadTerms;
    this.toggleTermsMessage();
  }

  private toggleTermsMessage() {
    let e = document.getElementById('termsMessage');
    e.className = this.hasReadTerms ? 'terms-message' : 'terms-message-error';
  }

  private downloadTerms(): Observable<Blob> {
    let termsAndConditionId = this._route.snapshot.queryParamMap.get('termsId');
    return this._httpClient
      .get(`${environment.portalApiUrl}v1/termsandconditions/${termsAndConditionId}`,
      {
        responseType: 'blob'
      });
  }

}
