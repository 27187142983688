<div class="main-container">
  <div id="navigation">
    <ul class="nav-bar">
      <li class="heading">Help</li>
      <li>
        <a (click)="setOption(0);" id="FAQ">Frequently Asked Questions</a>
      </li>
      <li><a (click)="setOption(1);" id="TRAINING">Training Materials</a></li>
    </ul>
  </div>
  <div class="content">
    <h1>Help</h1>
    <app-faq *ngIf="this.navbarOption ==='FAQ'" (childNavbarOption)="childNavbarOption()"></app-faq>
    <app-training-materials *ngIf="this.navbarOption ==='TRAINING'"></app-training-materials>
  </div>
</div>

