import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs';
import { AddressViewModel } from '../../../../interfaces/address/addressViewModel';
import { ContactEmail } from '../../../../interfaces/loss-payee-express/contact-email';
import { InsuredDetails } from '../../../../interfaces/loss-payee-express/insured-details';
import { LossPayeeExpressRequest } from '../../../../interfaces/loss-payee-express/loss-payee-express-request';
import { LossPayeeService } from '../../../../services/loss-payee/loss-payee.service';
import { MortgageeService } from '../../../../services/mortgagee.service';
import { UsersService } from '../../../../services/users/users.service';
import { ErrorMessage } from '../../../../shared/models/error-message.enum';


@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.css']
})
export class OrderSummaryComponent {

  @Input() activeScreen: number;

  private _lossPayeeExpressRequest = {} as LossPayeeExpressRequest;

  constructor(
    private _lossPayeeService: LossPayeeService,
    private _mortgageeService: MortgageeService,
    private _router: Router,
    private _userService: UsersService,
    private _toastr: ToastrService  ) { }

  submit() {

    this._lossPayeeExpressRequest = this.createRequest();

    if (this._lossPayeeService.addingNewMortgagee === true) {
      this._mortgageeService.createMortgagee(this._userService.userId, this._lossPayeeService.newMortgagee)
        .pipe(take(1))
        .subscribe(createMortgageeResponse => {
          this._lossPayeeExpressRequest.mortgageeId = createMortgageeResponse.data.mortgageeRequest.mortgageeId.toString();
          this.createLossPayeeRequest();
        }, error => {
          this._toastr.error(ErrorMessage.GenericError);
        });
    }
    else {
      this.createLossPayeeRequest();
    }

    this._router.navigate(['/loss-payee-confirm']);
  }

  private createLossPayeeRequest() {
    this._lossPayeeService.createLossPayeeRequest(this._lossPayeeExpressRequest, this._lossPayeeService.singleFileUpload)
      .pipe(take(1))
      .subscribe(response => {
        this._lossPayeeService.clearLossPayeeRequest();
        return response.data.status;
      }, error => {
        this._toastr.error(ErrorMessage.GenericError);
      });
  }

  private createRequest(): LossPayeeExpressRequest {
          
    return {
      operation: this._lossPayeeService.mortgageeDetails.action,
      position: this._lossPayeeService.mortgageeDetails.position,
      loanAmount: this._lossPayeeService.mortgageeDetails.loanAmount,
      loanNumber: this._lossPayeeService.mortgageeDetails.loanNumber,
      policyNumber: this._lossPayeeService.policyInformation.policyNumber,
      policyTypeCode: this._lossPayeeService.policyInformation.policyType,
      effectiveDate: this._lossPayeeService.mortgageeDetails.effectiveDate,
      isEscrowed: this._lossPayeeService.mortgageeDetails.isEscrowed,
      mortgageeId: this._lossPayeeService.mortgageeDetails.mortgageeId,
      client: this.getClientDetails(),
      propertyAddress: this.getAddress(),
      contact: this.getContactAndConsent()
    } as LossPayeeExpressRequest;
  }


  private getClientDetails(): InsuredDetails {

    return {
      firstName: this._lossPayeeService.policyInformation.insuredDetails.firstName,
      lastName: this._lossPayeeService.policyInformation.insuredDetails.lastName,
      middleInitial: this._lossPayeeService.policyInformation.insuredDetails.middleInitial,
      suffix: this._lossPayeeService.policyInformation.insuredDetails.suffix
    } as InsuredDetails;

  }

  private getAddress(): AddressViewModel {

    return {
      address1: this._lossPayeeService.policyInformation.propertyAddress.address1,
      address2: this._lossPayeeService.policyInformation.propertyAddress.address2,
      city: this._lossPayeeService.policyInformation.propertyAddress.city,
      state: this._lossPayeeService.policyInformation.propertyAddress.state,
      zip: this._lossPayeeService.policyInformation.propertyAddress.zip
    } as AddressViewModel;

  }

  private getContactAndConsent(): ContactEmail {
    return { email: this._lossPayeeService.contactAndConsent.email } as ContactEmail ;
  }

}
