import { Injectable } from '@angular/core';
import { PolicyType } from '../../interfaces/search/policy-type'

@Injectable({
  providedIn: 'root'
})
export class PolicyTypeService {
  public getPolicyTypes(): Array<PolicyType> {
    return [
      {
        name: 'Home'
      },
      {
        name: 'Condo'
      }
    ];
  }
}
