<div class="content">
  <h1>Error</h1>
  <div>
    <h2>Account Setup Not Complete</h2>
    <p>We are currently having problems setting up your account. Please try to sign in again. If you continue to
      experience this issue, reach out to us at the number listed below.</p>

    <h3>For technical support contact <a href="mailto:MERSHOI.SUPPORT@ice.com">MERS HOI Hub Support</a>: </h3>
    <div class="subtitle-error-contact">
      <span class="fa fa-phone-alt nex-fa-span error-fa-green" aria-hidden="true"></span><span class="error-pad-left">(888) 680-6377</span> <br />
      <span class="fa fa-envelope nex-fa-span error-fa-green" aria-hidden="true"></span><span class="error-pad-left"><a href="mailto:MERSHOI.SUPPORT@ice.com">MERSHOI.SUPPORT@ice.com</a></span> <br />
      <span class="far fa-clock nex-fa-span error-fa-green" aria-hidden="true"></span><span class="error-pad-left">Monday-Friday</span> <span class="error-pad-left-double"> 8:00 AM - 6:00 PM ET</span>
    </div>
  </div>
</div>
