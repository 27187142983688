<div [ngClass]="'registration-container'">

  <div [ngClass]="'header'">
    Loss Payee Setup
  </div>

  <p [ngClass]="'optional'">(Optional)</p>
  <p [ngClass]="'instructions'">Let’s make sure we have your mortgagee details. Please search for it here so we can set you up for success.</p>

  <input [(ngModel)]="searchString" type="text" id="searchBox" [ngClass]="{'search local': this.inError === false, 'input-error': this.inError === true }" (input)="this.search()" maxlength="100" />
  <div id="error" *ngIf="this.inError === true" [ngClass]="'error'">Mortgagee details are required to run a search</div>
  <ul [ngClass]="'listings'" *ngIf="this.isSearching === true && this.showAllRecords === false && this.itemSelected === false && this.noMortgageesFound === false">
    <li *ngFor="let mortgagee of this.mortgagees.data.mortgagees; let i=index" (click)="this.selectItem(i)">
      <span [ngClass]="'mortgagee-name'">{{ mortgagee.name }}</span> <br /> <span [ngClass]="'mortgagee-address1'">{{ mortgagee.address1 }} <span *ngIf="this.mortgagee.address2.length > 0" [ngClass]="'mortgagee-address1'">{{ mortgagee.address2 }}</span> {{ mortgagee.city }} {{ mortgagee.state }} {{ mortgagee.zip }}</span>
    </li>
  </ul>
  <button id='search' (click)='getAllMortgagees()' [ngClass]="'search-button'">Search</button>

  <div [ngClass]="'full-list'" id="fullList" *ngIf="showAllRecords === true"></div>

  <div [ngClass]="'add-mortgagee'" *ngIf="this.showAllRecords === true || (this.requestSent === true && this.showAllRecords === false)">
    <app-mortgagee-request [requestSent]="this.requestSent" [showAll]="this.showAllRecords" [records]="this.records"></app-mortgagee-request>
  </div>
  <div [ngClass]="'mortgagee-request'" *ngIf="this.itemSelected === true || (this.noMortgageesFound === true && this.searchValue.length > 2) ">
    <app-mortgagee-request [mortgageeAdded]="this.mortgageeAdded" [requestSent]="this.requestSent" [businessName]="this.businessName" [businessAddress1]="this.businessAddress1" [businessAddress2]="this.businessAddress2" [businessCity]="this.businessCity" [businessState]="this.businessState" [businessZip]="this.businessZip" [records]="this.records" [itemSelected]="this.itemSelected"></app-mortgagee-request>
  </div>
  <p></p>

  <p [ngClass]="{'skip': this.showAllRecords === false, 'skip-after-search': this.showAllRecords === true}" *ngIf="this.mortgageeAdded === false">Don't plan on using this feature? Use <span [ngClass]="'skip-bold'">Skip Setup </span>below.</p>
  <button [ngClass]="'skip-button'" (click)="this.finish()" *ngIf="this.mortgageeAdded === false">Skip Setup</button>

  <p [ngClass]="{'skip': this.showAllRecords === false, 'skip-after-search': this.showAllRecords === true}" *ngIf="this.mortgageeAdded === true">Done verifying mortgagees? Use <span [ngClass]="'skip-bold'">Finish Setup </span>below.</p>
  <button [ngClass]="{'skip-button': this.showAllRecords === false, 'skip-button-after-search': this.showAllRecords === true}" *ngIf="this.mortgageeAdded === true" (click)="this.finish()">Finish Setup</button>
</div>

<app-request-sent-modal (sendRequest)="openRequestSentModal()"></app-request-sent-modal>
