import { Component, OnInit, OnDestroy, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { UsersService } from '../../../services/users/users.service';
import { environment } from '../../../../environments/environment';
import { PaymentMethodSuccessResponse } from '../../../interfaces/payment/payment-method-success-response';
import { ErrorMessage } from '../../../shared/models/error-message.enum';
import { PaymentMethodService } from '../../../services/payment/payment.method.service';
import { PaymentDataEvents } from '../payment-data.events/payment-data.events';
import { ModalService } from '../../../services/modals/modals.service';


declare let HostedForm: Function;

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss']
})

export class PaymentMethodComponent implements OnInit, OnDestroy {
  @Output() paymentMethodAdded = new EventEmitter<boolean>();
  @Output() paymentMethodCancelled = new EventEmitter<boolean>();

  private _subs: Subscription[] = [];
  private _usersId = '';
  public hostedForm = {};
  public paymentMethodForm: FormGroup;
  public readonly paymentFormId = 'payment-form';
  public isHostedFormConfigured = false;

  constructor(
    private _formBuilder: FormBuilder,
    private _toastrService: ToastrService,
    private _userService: UsersService,
    private _paymentMethodService: PaymentMethodService,
    private _paymentDataEvents: PaymentDataEvents,
    private _modalService: ModalService
  ) {
    this.paymentMethodForm = this.buildPaymentMethodFormGroup();
    this.onPaymentAdded();
    this.onPaymentCancelled();
  }

  ngOnInit() {
    this._userService
      .userProfile_()
      .subscribe(userProfile => (this._usersId = userProfile.userId));

    this.paymentMethodCancelled.subscribe((x: boolean) => {
      if (x) {
        this.onCancel();
        this.isHostedFormConfigured = false;
        this.destroyHostedPaymentMethodForm();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyHostedPaymentMethodForm();

    if (this._subs && this._subs.length > 0) {
      while (this._subs.length > 0) {
        let sub = this._subs.pop();
        sub.unsubscribe();
        sub = null;
      }
    }
  }

  public buildPaymentMethodFormGroup(): FormGroup {
    return this._formBuilder.group({
      cardNickname: new FormControl(null)
    });
  }

  public destroyHostedPaymentMethodForm(): void {
    this.paymentMethodForm.get('cardNickname').setValue(null);
    // this.destroyHostedForm();
  }

  public mountHostedPaymentMethodForm(): void {
    try {
      this.hostedForm['frame'] = this.configureHostedPaymentForm();
      this.hostedForm['frame'].mount();
      this.syncPaymentMethodIFrame(true);

    } catch (error) {
      this.syncPaymentMethodIFrame(false);
      this.destroyHostedPaymentMethodForm();
      this._toastrService.error(ErrorMessage.GenericError);
    }
  }

  public get isHostedPaymentMethodFormConfigured(): boolean {
    return this.isHostedFormConfigured;
  }

  public onPaymentAdded(): void {
    this.paymentMethodAdded.subscribe((x: boolean) => {
      if (x) {
        this._paymentDataEvents.paymentMethodAdded(true);
        this.onCancel();
        this._toastrService.success('Payment method added successfully');
      }
    })
  }

  public onPaymentCancelled(): void {
    this.paymentMethodCancelled.emit(true);
  }

  public onCancel(): void {
    this._modalService.close('paymentMethod');
    this.paymentMethodForm.get('cardNickname').setValue(null);
  }

  private syncPaymentMethodIFrame(isHosted: boolean) {
    this._paymentDataEvents.updatePaymentMethodIFrame(isHosted);
  }

  private configureHostedPaymentForm(): Function {
    const paymentService = this._paymentMethodService;
    const paymentMethodTransformer = this._paymentMethodService.transform;
    const toastFactoryService = this._toastrService;
    const paymentMethodForm = this.paymentMethodForm;
    const paymentMethodAddedEvent = this.paymentMethodAdded;
    const paymentMethodCancelledEvent = this.paymentMethodCancelled;
    let userId: string;

    this._userService
      .userProfile_()
      .subscribe(userProfile => (userId = userProfile.userId));

    const config = {
      apiKey: environment.payment.apiKey,
      showLabels: true,
      id: `#${this.paymentFormId}`,
      autoSubmit: false,
      disableAfterSubmit: true,
      showMessages: true,
      buttonGroupAlignment: 'right',
      submitButtonText: 'Add Payment Option',
      showCancelButton: true,
      showHelp: true,
      helpText: {
        cardName: 'Enter first and last name',
        cardNumber: 'Enter 16-digit card number',
        cardZip: 'Enter 5-digit zip code',
        cardExpiry: 'Enter a valid date',
        cardSecurity: 'CVC/CVV',
        streetAddress: 'Enter a street address'
      },
      onCancel() {
        paymentMethodCancelledEvent.emit(true);
      },
      onSuccess(paymentMethodResponse: PaymentMethodSuccessResponse): void {
        const cardNickname = paymentMethodForm.get('cardNickname').value;
        const transformedPaymentMethod = paymentMethodTransformer(
          paymentMethodResponse,
          cardNickname
        );

        transformedPaymentMethod.userId = userId;
        paymentService.addPaymentMethod(transformedPaymentMethod)
          .subscribe(
            () => {
              paymentMethodAddedEvent.emit(true);
            },
            () => paymentMethodAddedEvent.emit(false)
          );

      },
      onError(response: { message: string, errors: any }): void {
        const preMessage = 'We are currently unable to process your request. Please email us at MERSHOI.SUPPORT@ice.com or call (888) 680-6377';

        if (response.message === 'Unable to determine cardtype') {
          toastFactoryService.error(`${preMessage} Please enter a valid card number and try again.`);
        }

        if (response.message === 'Invalid expdate') {
          toastFactoryService.error(`${preMessage} Please check your card number and try again.`);
        }

        if (response.message === 'LUHN/MOD10 check on account number failed') {
          toastFactoryService.error(preMessage);
        }

        if (response.message === 'INVALID ZIPCODE. NOT VALID FOR US OR CANADA.') {
          toastFactoryService.error(`${preMessage} Please enter a valid zip code and try again.`);
        }
      },
      styles: this.hostedPaymentMethodFormStyles
    };

    return HostedForm(config);
  }

  private get hostedPaymentMethodFormStyles(): object {
    return {
      base: {
        fontFamily: '"proxima nova", proxima-nova, -apple-system, BlinkMacSystemFont, "Segoe UI", "open sans", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
        fontSize: 13,
        lineHeight: 1.5,
        padding: '0 5px'
      },
      error: {
        display: 'block',
        background: '#E3342F',
        fontSize: 13,
        color: 'white',
        padding: '7px 19px',
        textAlign: 'center',
        borderRadius: '4px',
        marginBottom: 3
      },
      success: {
        display: 'block',
        background: '#38C172',
        fontSize: 13,
        color: 'white',
        padding: '7px 19px',
        textAlign: 'center',
        borderRadius: '4px',
        marginBottom: 3
      },
      formGroup: {
        display: 'block',
        padding: '3px 0',
        borderRadius: '4px',
        marginBottom: 3
      },
      label: {
        display: 'block',
        fontSize: 13,
        marginBottom: 5,
        fontWeight: 500,
        color: "#231f20"
      },
      helpText: {
        fontSize: 13,
        fontStyle: 'italic',
        color: '#aaa',
        height: 30,
        marginBottom: 5
      },
      helpError: {
        fontSize: 13,
        fontStyle: 'italic',
        color: '#E3342F',
        height: 30,
        marginBottom: 5
      },
      input: {
        fontFamily: '"Open Sans", sans-serif',
        ':focus': {
          outline: 'none',
          border: '1px solid #1189b0'
        },
        padding: '5px',
        '::-webkit-input-placeholder': { color: '#777' },
        '::-moz-placeholder': { color: '#777' },
        ':-ms-input-placeholder': { color: '#777' },
        ':-moz-placeholder': { color: '#777' },
        display: 'block',
        border: '1px solid #c4c4c4',
        paddingLeft: 9,
        paddingRight: 9,
        height: '30px',
        borderRadius: '2px',
        marginBottom: 3,
        width: '100%',
      },
      cancel: {
        display: 'block',
        background: 'white',
        color: '#1e79c2',
        fontsize: 13,
        padding: '6px 20px',
        border: '1px solid #1e79c2',
        cursor: 'pointer',
        font: 'weight: bold',
        borderRadius: '2px',
        marginRight: '10px',
        marginTop: '20px',
        ":hover": {
          color: "#006aa9",
          borderColor: "#006aa9",
        },
        ":focus": {
          color: "#006aa9",
          borderColor: "#006aa9",
          outline: 'none'
        }
      },
      button: {
        backgroundColor: '#1e79c2',
        border: '1px solid #006aa9',
        color: 'white',
        borderRadius: '2px',
        padding: '6px 20px',
        marginTop: '20px',
        fontSize: 13,
        ':hover': {
          backgroundColor: "#006aa9"
        },
        ':focus': {
          backgroundColor: "#006aa9",
          outline: 'none'
        }
      }
    };
  }

  private destroyHostedForm() {
    let element = document.getElementById('payment-form');
    while (element != null && element.firstChild) {
      element.removeChild(element.firstChild);
    }
  }

}
