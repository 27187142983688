import { Component, OnInit, OnDestroy, ViewChild, EventEmitter, Input, Output } from '@angular/core';
import { Location } from '@angular/common';
import { switchMap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { PaymentMethodViewModel } from '../../../../interfaces/payment/get-payment-method-view-model-request';
import { PaymentMethod } from '../../../../interfaces/payment/get-payment-method-request';
import { UsersService } from '../../../../services/users/users.service';
import { PaymentMethodService } from '../../../../services/payment/payment.method.service';
import { ErrorMessage } from '../../../../shared/models/error-message.enum';
import { ModalService } from '../../../../services/modals/modals.service';
import { PaymentDataEvents } from '../../../payment/payment-data.events/payment-data.events';
import { SetDefaultPaymentMethodRequest } from '../../../../interfaces/payment/set-default-payment-method-request';
import { PaymentMethodModalComponent } from '../../../../shared/components/modal-dialogs/payment-method-modal/payment-method-modal.component';
import { PaymentMethodComponent } from '../../../payment/payment-method/payment-method.component';

@Component({
  selector: 'app-payment-options',
  templateUrl: './payment-options.component.html',
  styleUrls: ['./payment-options.component.scss']
})

export class PaymentOptionsComponent implements OnInit, OnDestroy {
  @ViewChild(PaymentMethodModalComponent) _paymentMethodModalComponent: PaymentMethodModalComponent;
  @Input() payment: PaymentMethodViewModel = {} as PaymentMethodViewModel;
  @Output() paymentSet: EventEmitter<PaymentMethodViewModel> = new EventEmitter<PaymentMethodViewModel>();

  private _subs = [] as Subscription[];
  private _paymentMethods = {} as PaymentMethod;

  public showPaymentOptions = false;
  public paymentMethodToDelete: PaymentMethodViewModel;
  public paymentAdded = false;
  public modalCreated = false;

  constructor(
    private _usersService: UsersService,
    private _paymentMethodService: PaymentMethodService,
    private _toastrService: ToastrService,
    private _modalService: ModalService,
    private _paymentDataEvents: PaymentDataEvents,
    private _location: Location,
    private _paymentMethodComponent: PaymentMethodComponent
  ) { }

  ngOnInit() {
    this.getBillingMethods();
    this.openAfterRoute();
    this.subscribeToPaymentService();
  }

  ngOnDestroy() {
    this.unSubscribe();
  }

  public openAfterRoute(): void {
    this._subs.push(this._paymentDataEvents.paymentOpenAfterRedirect_.subscribe(openModalAfterRedirect => {
      if (openModalAfterRedirect !== null && openModalAfterRedirect) {
        this._location.replaceState('/my-account');
        this._paymentDataEvents.paymentOpenAfterRedirect(false);
        setTimeout(() => this.onAddPaymentMethodClick());
      }
    }))
  }

  public onAddPaymentMethodClick(): void {
    if (!this.modalCreated) {
      this._paymentDataEvents.updatePaymentMethodIFrame(true);
      this._paymentMethodModalComponent.configurePaymentMethod();
      this.modalCreated = true;
    }

    this._modalService.open('paymentMethod');
  }

  public onDeletePaymentMethodClick(): void {
    this._paymentDataEvents.paymentMethodDeleteObject(this.paymentMethodToDelete);
    this._modalService.open('deletePaymentMethod');
  }

  public get isUsingPaymentMethod(): boolean {
    return (!!this.selectedPaymentMethod);
  }

  public get hasBillingMethods(): boolean {
    return !!this._paymentMethods;
  }

  public get hasPaymentMethods(): boolean {
    return this.availablePaymentMethods && this.availablePaymentMethods.length > 0;
  }

  public get availablePaymentMethods(): Array<PaymentMethodViewModel> {
    if (!this._paymentMethods || !this._paymentMethods.paymentMethods) {
      return null;
    }
    return this._paymentMethods.paymentMethods;
  }

  public get selectedPaymentMethod(): PaymentMethodViewModel {
    if (!this._paymentMethods || !this._paymentMethods.selectedPaymentMethod) {
      return null;
    }
    return this._paymentMethods.selectedPaymentMethod;
  }

  public onClickDeletePaymentMethod(paymentMethod: PaymentMethodViewModel): void {
    this._paymentDataEvents.paymentMethodDeleteObject(paymentMethod);
    this._modalService.open('deletePaymentMethod');
  }

  public onClickUsePaymentMethod(paymentMethod: PaymentMethodViewModel): void {
    var payment: SetDefaultPaymentMethodRequest = { paymentMethodId: null };
    payment.paymentMethodId = paymentMethod.paymentMethodId

    this._usersService
      .userProfile_()
      .pipe(switchMap(userProfile => this._paymentMethodService.setDefaultPaymentMethod(userProfile.userId, payment)))
      .subscribe(
        success => {
          this._toastrService.success('Default payment method updated successfully');
          this.getBillingMethods();
        },
        error => {
          this._toastrService.error(ErrorMessage.GenericError);
          this.showPaymentOptions = false;
        }
      );
  }

  showPopup(id: string): void {
    id = 'ellipsis' + id;
    let popup = document.getElementById(id);
    popup.className = 'popup';
    popup.classList.toggle('show');
  }

  hidePopup(id: string): void {
    id = 'ellipsis' + id;
    let popup = document.getElementById(id);
    popup.className = 'hide';
  }

  private getBillingMethods(): void {
    this._usersService
      .userProfile_()
      .pipe(switchMap(() => this._paymentMethodService.getPaymentMethod(this._usersService.userId)))
      .subscribe(response => {
        this._paymentMethods = response.body.data;
        if (response.body.data.paymentMethods.length > 0 && !response.body.data.selectedPaymentMethod) {
          this.setDefaultPayment(response.body.data.paymentMethods[0]);
          this._paymentMethods.selectedPaymentMethod = response.body.data.paymentMethods[0];
          this.paymentSet.emit(this._paymentMethods.selectedPaymentMethod);
        }
        this.showPaymentOptions = true;
      }, error => {
        this._toastrService.error(ErrorMessage.PaymentError);
        this._paymentMethods = null;
        this.showPaymentOptions = false;
      });
  }

  private setDefaultPayment(paymentMethodId: SetDefaultPaymentMethodRequest): void {
    this._usersService
      .userProfile_()
      .pipe(switchMap(userProfile => this._paymentMethodService.setDefaultPaymentMethod(userProfile.userId, paymentMethodId)))
      .subscribe(
        success => {
          this._toastrService.success('Default payment method updated successfully');
        },
        error => {
          this._toastrService.error(ErrorMessage.GenericError);
          this.showPaymentOptions = false;
        }
    );
  }

  private unSubscribe(): void {
    if (this._subs && this._subs.length > 0) {
      while (this._subs.length > 0) {
        let sub = this._subs.pop();
        sub.unsubscribe();
        sub = null;
      }
    }
  }

  private subscribeToPaymentService(): void {
    this._subs.push(this._paymentMethodService.cancelPayment_.subscribe(() => {
      this._modalService.close('paymentMethod');
      this._paymentMethodComponent.destroyHostedPaymentMethodForm();
    }))

    this._subs.push(this._paymentMethodService.addPayment_.subscribe(() => {
      this._paymentMethodComponent.destroyHostedPaymentMethodForm();
      this._modalService.close('paymentMethod');
      this.paymentAdded = true;
      this._toastrService.success('Payment method added successfully');
      this.getBillingMethods();
    }))

    this._subs.push(this._paymentMethodService.deletePayment_.subscribe(() => {
      this._modalService.close('deletePaymentMethod');
      this._paymentMethodComponent.destroyHostedPaymentMethodForm();
      this.getBillingMethods();
    }))

  }

}
