import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';

import { UsersService } from '../../../../services/users/users.service';
import { IndividualTransactionLogResponse } from '../../../../interfaces/transactions/individual-transaction-log-response';
import { ApiResponse } from '../../../../interfaces/api/apiResponse';
import { TransactionViewModel } from '../../../../interfaces/transactions/transaction-view-model';
import { environment } from '../../../../../environments/environment';
import { ErrorMessage } from '../../../../shared/models/error-message.enum';
import { PolicyProperty } from 'src/app/interfaces/loss-payee/policy-property';
import { MortgageeService } from 'src/app/services/mortgagee.service';
import { FeatureFlagService } from '../../../../services/feature-flag.service';
import { Observable } from 'rxjs';


export enum columSort {
  Date = 'eoiPurchaseDate',
  TransactionId = 'transactionId',
  Insurer = 'insurer',
  Last4 = 'lastFourDigits',
  Address = 'searchAddress',
  Refunded = 'isRefunded'
}

@Component({
  selector: 'app-transaction-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.scss']
})

export class TransactionHistoryComponent implements OnInit {

  static readonly maxRecordsPerPage = 10;
  static readonly startPage = 1;
  private _httpClient: HttpClient;
  private _toastr: ToastrService;
  private readonly _featureName = 'losspayee'

  public transactionsHistory: Array<TransactionViewModel>;
  public totalRows = 0;
  public totalPageCount = 0;
  public currentPage = 0;
  public moreRecords = false;
  public previousRecords = false;
  public hasError = false;
  public hasTransactions = false;
  public showLossPayee = false;

  constructor(
    private _userService: UsersService,
    private _router: Router,
    private _mortgageeService: MortgageeService,
    private _featureFlagService: FeatureFlagService,
    public toastr: ToastrService,
    public httpClient: HttpClient
    ) {
    this._toastr = toastr;
    this._httpClient = httpClient;
  }

  ngOnInit(): void {
    this._featureFlagService.isFeatureEnabled(this._featureName)
      .then(f => this.showLossPayee = f);

    this.transactionsHistory = this.prepareToRender(TransactionHistoryComponent.startPage, TransactionHistoryComponent.maxRecordsPerPage);
  }

  prepareToRender(pageNumber: number, maxRows: number): TransactionViewModel[] {
    let tvm = Array<TransactionViewModel>();

    //  using take(1) will automatically unsubscribe and prevent memory leaks
    this.getTransactionHistory(pageNumber, maxRows).pipe(take(1)).subscribe(results => {

      this.transactionsHistory = results.data.transactions;

      if (results.data.transactions.length > 0) {
        this.currentPage = TransactionHistoryComponent.startPage;
        this.totalRows = results.data.totalCount;
        this.totalPageCount = Math.ceil(this.totalRows / TransactionHistoryComponent.maxRecordsPerPage);
        this.moreRecords = this.totalRows > TransactionHistoryComponent.maxRecordsPerPage;
        this.hasTransactions = true;
      }

    },
      error => {
        this.showError();
      }
    );

    return tvm;

  }

  nextPage(): void {
    if (this.moreRecords && this.currentPage < this.totalPageCount) {

      let tvm = Array<TransactionViewModel>();
      this.getTransactionHistory((this.currentPage + 1), TransactionHistoryComponent.maxRecordsPerPage).pipe(take(1)).subscribe(results => {
        this.transactionsHistory = results.data.transactions;
      },
        error => {
          this.showError();
        }
      );

      this.transactionsHistory = tvm;
      this.currentPage += 1;
      this.previousRecords = true;
      this.setButtonsStatus();
    }
  }

  previousPage(): void {
    if (this.previousRecords && this.currentPage > TransactionHistoryComponent.startPage) {

      let tvm = Array<TransactionViewModel>();
      this.getTransactionHistory((this.currentPage - 1), TransactionHistoryComponent.maxRecordsPerPage).subscribe(results => {
        this.transactionsHistory = results.data.transactions;
      },
        error => {
          this.showError();
        }
      );

      this.transactionsHistory = tvm;
      this.currentPage -= 1;
      this.setButtonsStatus();
    }
  }

  firstPage(): void {
    let tvm = Array<TransactionViewModel>();
    this.getTransactionHistory(TransactionHistoryComponent.startPage, TransactionHistoryComponent.maxRecordsPerPage).pipe(take(1)).subscribe(results => {
      this.transactionsHistory = results.data.transactions;
    },
      error => {
        this.showError();
      }
    );

    this.transactionsHistory = tvm;
    this.currentPage = TransactionHistoryComponent.startPage;
    this.previousRecords = false;
    this.setButtonsStatus();
  }

  lastPage(): void {
    let tvm = Array<TransactionViewModel>();
    this.getTransactionHistory(this.totalPageCount, TransactionHistoryComponent.maxRecordsPerPage).subscribe(results => {
      this.transactionsHistory = results.data.transactions;
    },
      error => {
        this.showError();
      }
    );

    this.transactionsHistory = tvm;
    this.currentPage = this.totalPageCount;
    this.setButtonsStatus();
  }

  loadResults(results: ApiResponse<IndividualTransactionLogResponse>): TransactionViewModel[] {
    let tvm = Array<TransactionViewModel>();

    for (let i = 0; i < results.data.transactions.length; i++) {
      tvm.push(results.data.transactions[i]);
    }
    return tvm;
  }

  sortList(column: string): TransactionViewModel[] {
    if ((column in columSort)) {
      return this.transactionsHistory.reverse();
    }
  }

  getTransactionHistory(page: number, pageSize: number): Observable<ApiResponse<IndividualTransactionLogResponse>> {
    const options = {
      params: new HttpParams()
        .set('page', page.toString())
        .set('pageSize', pageSize.toString())
    };

    return this._httpClient
      .get<ApiResponse<IndividualTransactionLogResponse>>
      (`${environment.portalApiUrl}v1/users/${this._userService.userId}/transactions`, options);
  }

  showPopup(): void {
    let popup = document.getElementById('amountPopup');
    popup.className = 'popup-text';
    popup.classList.toggle('show');
  }

  hidePopup(): void {
    let popup = document.getElementById('amountPopup');
    popup.className = 'hide popup-text';
  }

  showError(): void {
    this.hasError = true;
    this._toastr.error(ErrorMessage.TransactionError);
  }

  setButtonsStatus(): void {
    this.previousRecords = this.currentPage > TransactionHistoryComponent.startPage
      ? this.previousRecords = true
      : this.previousRecords = false;

    this.moreRecords = this.currentPage === this.totalPageCount
      ? this.moreRecords = false
      : this.moreRecords = true;
  }

  updateMortgagee(eoiPurchaseId: string): void {
    this.getEoiPurchaseInformation(eoiPurchaseId)
      .subscribe(result => {
        let policy = result.data;
        policy.eoiPurchaseId = eoiPurchaseId;
        this._mortgageeService.mortgageePolicyInformation(policy);
        this._router.navigate(['/update-mortgagee']);
      },
        error => {
          this._toastr.error(ErrorMessage.GenericError);
        }
      );
  }

  getEoiPurchaseInformation(eoiPurchaseId: string): Observable<ApiResponse<PolicyProperty>> {
    return this._httpClient
      .get<ApiResponse<PolicyProperty>>
      (`${environment.portalApiUrl}v1/policies/evidence/${eoiPurchaseId}/details`);
  }
}
