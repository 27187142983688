import {Component, OnInit, OnDestroy, Inject, ViewChild} from '@angular/core';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, AuthError, EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { environment } from '../environments/environment';
import { AppInsightsService } from './services/app-insights.service';
import { TitleService } from './services/title.service';
import { IdTokenClaims } from './interfaces/id-token-claims';
import { SessionTimeoutComponent } from "./shared/components/modal-dialogs/session-timeout-modal/session-timeout.component";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent implements OnInit, OnDestroy {
  @ViewChild(SessionTimeoutComponent) sessionModal:SessionTimeoutComponent;
  title = 'ClearHOI Portal';
  loggedIn: boolean;
  private subscription: Subscription;
  private passwordResetError = 'AADB2C90118';
  passwordResetTfp = 'B2C_1_CHOI_ForgotPassword_24_2';
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private readonly broadcastService: MsalBroadcastService,
    private readonly authService: MsalService,
    private readonly appInsights: AppInsightsService,
    private titleService: TitleService
  ) {}

  ngAfterViewInit() {
    this.sessionModal.setSessionEndCallback(() => { this.authService.logout(); });
  }

  ngOnInit() {

    this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      );

    this.broadcastService.msalSubject$.pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
      takeUntil(this._destroying$))
      .subscribe((result: EventMessage) => {
        let payload: IdTokenClaims = (<AuthenticationResult>(result.payload)) as IdTokenClaims;

        // We need to reject id tokens that were not issued with the default sign-in policy.
        // "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr")
        // To learn more about b2c tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
        if (payload.account) {
          this.loggedIn = true;
          this.sessionModal.sessionWatch();
        }

        if (payload.idTokenClaims?.tfp === this.passwordResetTfp) {
          this.sessionModal.sessionStop();
          return this.logout();
        }

        return result;
      });

    this.broadcastService.msalSubject$.pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
      takeUntil(this._destroying$)
    )
      .subscribe((result: EventMessage) => {
        if (result.error instanceof AuthError) {
          // Check for forgot password error
          // Learn more about AAD error codes at https://docs.microsoft.com/azure/active-directory/develop/reference-aadsts-error-codes
          if (result.error.message.includes('AADB2C90118')) {
            // login request with reset authority
            let resetPasswordFlowRequest = {
              scopes: [environment.apiScope],
              authority: environment.azureB2C.passwordResetPolicy,
            };

            this.login(resetPasswordFlowRequest);
          }
        }
      });

    this.authService.handleRedirectObservable().subscribe({
      next: (result: AuthenticationResult) => {
        if (result) {
          this.authService.instance.setActiveAccount(result.account);
        }
      }
    });

    this.titleService.boot();
  }

  ngOnDestroy() {
    this._destroying$.next(null);
    this._destroying$.complete();
    this.subscription.unsubscribe();
  }

  login(userFlowRequest: RedirectRequest) {
    this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
      )
      .subscribe(() => {
        if (this.msalGuardConfig.authRequest) {
          this.authService.loginRedirect({
            ...this.msalGuardConfig.authRequest,
            ...userFlowRequest,
          } as RedirectRequest);
        } else {
          this.authService.loginRedirect(userFlowRequest);
        }
      });
  }

  logout() {
    this.authService.logoutRedirect();
  }

}
