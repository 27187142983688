import { Component, OnInit} from '@angular/core';

export enum navbarOption {
  FAQ = 0,
  TRAINING = 1
}
@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit  {

  public navbarOption: string;

  ngOnInit() {
    this.setOption(0);
  }


  public setOption(option: number): void {
    if (navbarOption[option] !== null) {
      this.navbarOption = navbarOption[option].toString();

      let activeTab = document.getElementById(this.navbarOption);
      activeTab.className = 'active';

      let inactiveTab = (this.navbarOption === navbarOption[0].toString())
        ? navbarOption[1].toString()
        : navbarOption[0].toString();

      let e = document.getElementById(inactiveTab);
      e.className = '';
    }
  }

  public childNavbarOption(): void {
    this.setOption(1);
  }

}
