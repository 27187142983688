<div class="content">
  <h1>Terms &amp; Conditions</h1>
  <div *ngIf="termsfailed === false">
    <div *ngIf="termsLoaded === true">
      <form>
        <div class="input-wrapper">
          <div class="terms" [innerHTML]="termsAndConditions" ></div>
          <div class="input checkbox fld-str">
            <input id="readTerms" type="checkbox" (click)="readTerms()">
            <label for="readTerms">By checking this box I acknowledge that I have read, understand and agree to the MERS HOI Hub Portal Terms and Conditions<br />
              <span [ngClass]="'terms-message'" id="termsMessage">Please check the box acknowledging you have read the terms and conditions before accepting</span></label>
            <!--<div class="checkbox-container">
              <label class="checkbox-label">
                <input type="checkbox" (click)="readTerms()">
                <span class="checkbox-custom "></span>
              </label>
            </div>
            <div class="terms-container">
              <div class="terms-have-read">
                By checking this box I acknowledge that I have read, understand and agree to the MERS HOI Hub Portal Terms and Conditions<br />
                <span [ngClass]="'terms-message'" id="termsMessage">Please check the box acknowledging you have read the terms and conditions before accepting</span>
              </div>
            </div>-->
          </div>
        </div>
        <div class="input-btn border">
          <button (click)="declineTerms()">Decline</button>
          <button class="primary" (click)="acceptTerms()" [disabled]="!this.hasReadTerms">Accept</button>
        </div>
      </form>
    </div>
  </div>
</div>



