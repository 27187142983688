<form [formGroup]="accountDetailsForm" (ngSubmit)="submit()" class="sr-form">
  <div class="account-details-container">
    <div class="title">Finish Account Setup</div>
    <div class="sub-title">An easier and more streamlined approach to getting proof of insurance awaits you.</div>
    <div class="heading">User Information</div>
    <br />
    <div class="names-group" formGroupName="name">
      <label class="first-label" for="firstName">First Name:</label>
      <input type="text" class="form-control first-input form-control-create" formControlName="first" name="firstName" id="firstName" [readonly]="true" />
      <div class="first-error"><span *ngIf="!accountDetailsForm.get('name.first').valid && accountDetailsForm.get('name.first').touched" class="help-block">Please enter a first name.</span></div>

      <label class="last-label" for="lastName">Last Name:</label>
      <input type="text" class="form-control last-input form-control-create" formControlName="last" id="lastName" [readonly]="true" />
      <div class="last-error"><span *ngIf="!accountDetailsForm.get('name.last').valid && accountDetailsForm.get('name.last').touched" class="help-block">Please enter a last name.</span></div>
    </div>
    <div class="email">
      <label class="email-label" for="emailAddress">Email Address:</label>
      <input type="text" class="form-control spanAllColumns email-input form-control-create" formControlName="email" id="emailAddress" [readonly]="true" />
      <div class="email-error">
        <span *ngIf="!accountDetailsForm.get('email').valid && accountDetailsForm.get('email').touched"
              class="help-block">Please enter a valid email address.</span>
      </div>
    </div>
    <div class="phone-group" formGroupName="phone">
      <label class="phone-label" for="workPhone">Work Phone:</label>
      <input type="text" class="form-control phone-input form-control-create" formControlName="workPhone" id="workPhone" mask="(000)-000-0000" placeholder="(555)-555-5555" />
      <div class="phone-error"><span *ngIf="(displayValidationErrors && !accountDetailsForm.get('phone.workPhone').valid) || (!accountDetailsForm.get('phone.workPhone').valid && accountDetailsForm.get('phone.workPhone').touched)" class="help-block">Please enter a valid work phone.</span></div>

      <label class="extension-label" for="phoneExtension">Phone Extension:</label>
      <input type="text" class="form-control extension-input form-control-create" formControlName="extension" id="phoneExtension" mask="99999" maxlength="10" />
      <div class="extension-error"><span *ngIf="(displayValidationErrors && !accountDetailsForm.get('phone.extension').valid) || (!accountDetailsForm.get('phone.extension').valid && accountDetailsForm.get('phone.extension').touched)" class="help-block">Please enter a valid phone extension</span></div>
    </div>
    <div class="company">
      <label class="company-label" for="companyName">Company Name:</label>
      <input type="text" class="form-control company-input form-control-create" formControlName="companyName" id="companyName" placeholder="Enter a company name" maxlength="50" />
      <div class="company-error"><span *ngIf="(displayValidationErrors && !accountDetailsForm.get('companyName').valid) || (!accountDetailsForm.get('companyName').valid && accountDetailsForm.get('companyName').touched)" class="help-block" maxLength="50">Company name is required</span></div>
    </div>
    <div class="job">
      <label class="job-label" for="jobTitle">Job Title:</label>
      <input type="text" class="form-control job-input form-control-create" formControlName="jobTitle" id="jobTitle" placeholder="Enter a job title" maxlength="50" />
      <div class="job-error"><span *ngIf="(displayValidationErrors && !accountDetailsForm.get('jobTitle').valid) || (!accountDetailsForm.get('jobTitle').valid && accountDetailsForm.get('jobTitle').touched)" class="help-block" maxLength="50">Job title is required</span></div>
    </div>

    <div class="submit"><button type="submit" class="btn-block form-field-wrapper button">Finish Setup</button></div>
  </div>
</form>
