<div class="hoi-footer">
  <div class="footer-right">
    &copy; {{getCurrentYear()}} {{getCopyRightText()}}
  </div>
  <div class="footer-links">
    <a class="footer__terms-of-use-link"
       target="blank"
       href="https://www.icemortgagetechnology.com/terms-of-use">
      Terms of Use
    </a>

    <a class="footer__privacy-policy-link"
       target="blank"
       href="https://www.icemortgagetechnology.com/privacy-policy">
      Privacy Policy
    </a>
  </div>
</div>
