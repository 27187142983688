import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { of, Observable, Subject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { ApiResponse } from '../../interfaces/api/apiResponse';
import { environment } from '../../../environments/environment';
import { PaymentMethod } from '../../interfaces/payment/get-payment-method-request';
import { PaymentMethodSuccessResponse } from '../../interfaces/payment/payment-method-success-response';
import { CreatePaymentMethodRequest } from '../../interfaces/payment/create-payment-method-request';
import { SetDefaultPaymentMethodRequest } from '../../interfaces/payment/set-default-payment-method-request';


@Injectable({
  providedIn: 'root',
})

export class PaymentMethodService {

  private _isProcessing = false;
  private _cancelPaymentObserver = new Subject();
  private _addPaymentMethod = new Subject();
  private _deletePaymentMethod = new Subject();
  private _setDefaultPayment = new Subject();
  private _httpClient: HttpClient;

  public setDefaultPayment_ = this._setDefaultPayment.asObservable();
  public deletePayment_ = this._deletePaymentMethod.asObservable();
  public cancelPayment_ = this._cancelPaymentObserver.asObservable();
  public addPayment_ = this._addPaymentMethod.asObservable();
  public toaster: ToastrService;
  

  constructor(toastr: ToastrService, httpClient: HttpClient) { this.toaster = toastr; this._httpClient = httpClient; }

  public transform(paymentMethod: PaymentMethodSuccessResponse, paymentMethodNickname?: string): CreatePaymentMethodRequest {
    if (!paymentMethod) {
      throw new Error('A payment method is required.');
    }

    return {
      userId: paymentMethod.token,
      lastFourDigits: paymentMethod.lastFour,
      cardType: paymentMethod.brand,
      expiryDate: paymentMethod.expiration,
      processorToken: paymentMethod.token,
      nickname: paymentMethodNickname 
    } as CreatePaymentMethodRequest;
  }

  public getPaymentMethod(userId: string): Observable<HttpResponse<ApiResponse<PaymentMethod>>> {
    return this._httpClient
      .get<ApiResponse<PaymentMethod>>(
        `${environment.portalApiUrl}v1/users/${userId}/paymentmethods`, { observe: 'response' }
      );
  }

  public addPaymentMethod(paymentMethod: CreatePaymentMethodRequest): Observable<HttpResponse<ApiResponse<CreatePaymentMethodRequest>>> {
    if (this._isProcessing) {
      return of(new HttpResponse<ApiResponse<CreatePaymentMethodRequest>>());
    } else {
      this._isProcessing = true;
    }

    return this._httpClient
      .post<ApiResponse<CreatePaymentMethodRequest>>(`${environment.portalApiUrl}v1/paymentmethods`, paymentMethod)
      .pipe(
        tap(() => {
          this.addPaymentMethodEmitter(true);
          this._isProcessing = false
          },
        ),
        catchError(error => {
          this._isProcessing = false;
          return of(error);
        })
      );

  }

  public cancelPayment(): void {
    this.cancelPaymentEmitter(true);
  }

  public setDefaultPaymentMethod(userId: string, paymentMethod: SetDefaultPaymentMethodRequest): Observable<ApiResponse<SetDefaultPaymentMethodRequest>> {
    return this._httpClient.put<ApiResponse<SetDefaultPaymentMethodRequest>>(
      `${environment.portalApiUrl}v1/users/${userId}/paymentmethod`,
      paymentMethod
    )
      .pipe(tap(() => (this.setDefaultPaymentEmitter(true)))
      );
  }

  public deletePaymentMethod(userId: string, paymentMethodId: string): Observable<ApiResponse<null>> {
    return this._httpClient.put<ApiResponse<null>>(
      `${environment.portalApiUrl}v1/users/${userId}/paymentmethods/${paymentMethodId}/archive`,
      null
    )
      .pipe(
        tap(() => (this.deletePaymentEmitter(true)))
      );
  }

  private setDefaultPaymentEmitter(data: boolean) :void {
    this._setDefaultPayment.next(data);
  }

  private deletePaymentEmitter(data: boolean) :void {
    this._deletePaymentMethod.next(data);
  }

  private cancelPaymentEmitter(data: boolean) :void {
    this._cancelPaymentObserver.next(data);
  }

  private addPaymentMethodEmitter(data: boolean) :void {
    this._addPaymentMethod.next(data);
  }

}
