import { Component, OnInit, ViewChild, OnDestroy, Injector, Input } from '@angular/core';
import { FormArray, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { StatesService } from '../../../services/states/states.service';
import { SuffixService } from '../../../services/suffixes/suffixes.service';
import { SearchService } from '../../../services/search/search-service';
import { State } from '../../../interfaces/search/state';
import { Suffix } from '../../../interfaces/search/suffix';
import { BriefPolicySearchDataEvents } from '../policy-search-data.events/policy-search-data.events';
import { BriefSearchRequest, policyTypeCode } from '../../../interfaces/search/brief-search-request';
import { AddressViewModel } from '../../../interfaces/address/addressViewModel';
import { BriefSearchResponse } from '../../../interfaces/search/brief-search-response';
import { ErrorMessage } from '../../../shared/models/error-message.enum';
import { ModalService } from '../../../services/modals/modals.service';
import { ProgressBarComponent } from '../../../shared/components/modal-dialogs/progress-bar-modal/progress-bar.component';
import { PolicyType } from '../../../interfaces/search/policy-type';
import { PolicyTypeService } from '../../../services/policyType/policy-type.service';


@Component({
  selector: 'app-policy-search',
  templateUrl: './policy-search.component.html',
  styleUrls: ['./policy-search.component.scss']
})
export class PolicySearchComponent implements OnInit, OnDestroy {
  @ViewChild(ProgressBarComponent) _pbc: ProgressBarComponent;
  @Input('userHasPaymentMethod') hasPaymentMethod: boolean;
  private _addressesFormArray: FormArray;
  private _briefSearchRequest: BriefSearchRequest;
  private _resetLiteForm = true;
  private _statesService: StatesService;
  private _suffixService: SuffixService;
  private _policyService: PolicyTypeService;
  private _searchService: SearchService;
  private _modalService: ModalService;

  public briefPolicySearchForm: FormGroup;
  public states: Array<State>;
  public suffixes: Array<Suffix>;
  public policyTypes: Array<PolicyType>;
  public buttonName = 'Search';
  public isSearching = false;
  public resetSearch: BriefSearchRequest;
  public resetResponse: BriefSearchResponse;

  //  max of 7 constructor arguments, so moving non-expensive services to use Inject
  constructor(private _injector: Injector,
    private _formBuilder: FormBuilder,
    private _dataService: BriefPolicySearchDataEvents,
    private _toastService: ToastrService
  ) {
    //  inject services
    this._statesService = _injector.get(StatesService);
    this._suffixService = _injector.get(SuffixService);
    this._policyService = _injector.get(PolicyTypeService);
    this._searchService = _injector.get(SearchService);
    this._modalService = _injector.get(ModalService);

    //  invoke functions
    this.states = this._statesService.getStates();
    this.suffixes = this._suffixService.getSuffixes();
    this.policyTypes = this._policyService.getPolicyTypes();
  }

  ngOnDestroy(): void {
    this.onReset();
  }

  public ngOnInit(): void {
    this._briefSearchRequest = {} as BriefSearchRequest;
    this._briefSearchRequest.propertyAddress = {} as AddressViewModel;
    this.resetSearch = {} as BriefSearchRequest;
    this.resetResponse = {} as BriefSearchResponse;

    this.briefPolicySearchForm = this.buildBriefPolicySearchFormGroup();
    this._addressesFormArray = <FormArray>(this.briefPolicySearchForm.get('addresses'));
    let previousSearch = this._searchService.getSavedSearch();

    if (previousSearch.firstName !== undefined) {
      this.mapSavedSearchToForm(previousSearch);
    }

  }

  public getAddressFormGroup(index: number = 0): FormGroup {
    return <FormGroup>this._addressesFormArray.controls[index];
  }

  public onSubmit(): void {
    if (this.briefPolicySearchForm.invalid) {
      this.markAllControlsAsTouched(this.briefPolicySearchForm);
      this.markAllControlsAsTouched(this.getAddressFormGroup());
      this.markAllControlsAsTouched(this.getAddressFormGroup(1));
    }
  }

  public onReset(): void {
    if (this._resetLiteForm) {
      this.briefPolicySearchForm.reset();
    }
    this.removeAddress(1);
    this._dataService.updateAddressSearched(null);
    this._dataService.updateBriefPolicySearch(null);
    this._dataService.resetSearch();
  }

  public hasFieldValidationError(controlName: string, errorCode: string, formGroup: FormGroup): boolean {
    return (
      (formGroup.get(controlName).touched) &&
      formGroup.get(controlName).hasError(errorCode)
    );
  }

  submit(): void {
    if (this.briefPolicySearchForm.invalid) {
      this.markRequiredFieldsAsInvalid();
    } else {
      this._resetLiteForm = false;
      this.onReset();
      this._resetLiteForm = true;
      this.getBriefSearchResults();
    }
  }

  addNewAddress(): void {
    this._addressesFormArray.push(this.buildAddressFormGroup());
  }

  removeAddress(index: number): void {
    if (index !== 0) {
      this._addressesFormArray.removeAt(index);
    }
  }

  buildBriefPolicySearchFormGroup(): FormGroup {
    return this._formBuilder.group({
      firstName: [null, Validators.required],
      middleInitial: [null],
      lastName: [null, Validators.required],
      suffix: [null],
      policyType: [null, [Validators.required]],
      addresses: this._formBuilder.array([this.buildAddressFormGroup()])
    });
  }

  buildAddressFormGroup(): FormGroup {
    return this._formBuilder.group({
      zip: [null, [Validators.required, Validators.minLength(5)]],
      streetAddressOne: [null, Validators.required],
      streetAddressTwo: [null],
      city: [null, Validators.required],
      state: [null, Validators.required],
    });
  }

  markAllControlsAsTouched(formGroup: FormGroup): void {
    if (!!formGroup) {
      Object.keys(formGroup.controls).forEach(formControlKey => {
        formGroup.controls[formControlKey].markAsTouched();
      });
    }
  }

  markRequiredFieldsAsInvalid(): void {
    this.markAllControlsAsTouched(this.briefPolicySearchForm);
    this.markAllControlsAsTouched(this.getAddressFormGroup());
    this.markAllControlsAsTouched(this.getAddressFormGroup(1));
  }

  getBriefSearchResults(): void {
    this.buttonName = 'Searching';
    let request = this.mapFormToModel();
    this.search();

    this._searchService.postBriefSearch(request).subscribe(response => {
      if (response.data !== null) {
        this._dataService.updateBriefPolicySearch(response.data);
        this._dataService.updateAddressSearched(request);
        this.buttonName = 'Search';
      } else {
        this.buttonName = 'Search';
        this._toastService.error(ErrorMessage.GenericError);
      }
      this.isSearching = this._pbc.isSearching = false;
      this._modalService.close('searching');
    }, networkerror => {
      if (networkerror !== null) {
        this.isSearching = this._pbc.isSearching = false;
        this._modalService.close('searching');
        this.buttonName = 'Search';
        if (networkerror.error !== null && networkerror.error.errors !== null) {
          this._toastService.error(networkerror.error.errors[0].message);
        } else {
          this._toastService.error(ErrorMessage.GenericError);
        }
      }
    });

  }

  mapFormToModel(): BriefSearchRequest {
    this._briefSearchRequest.firstName = this.briefPolicySearchForm.get('firstName').value;
    this._briefSearchRequest.lastName = this.briefPolicySearchForm.get('lastName').value;
    this._briefSearchRequest.middleInitial = this.briefPolicySearchForm.get('middleInitial').value;
    this._briefSearchRequest.suffix = this.briefPolicySearchForm.get('suffix').value;
    var policyType = this.briefPolicySearchForm.get('policyType').value.toUpperCase();
    this._briefSearchRequest.policyTypeCode = policyTypeCode[policyType as keyof typeof policyTypeCode];
    this._briefSearchRequest.propertyAddress.address1 = this.getAddressFormGroup().get('streetAddressOne').value;
    this._briefSearchRequest.propertyAddress.address2 = this.getAddressFormGroup().get('streetAddressTwo').value;
    this._briefSearchRequest.propertyAddress.city = this.getAddressFormGroup().get('city').value
    this._briefSearchRequest.propertyAddress.state = this.getAddressFormGroup().get('state').value;
    this._briefSearchRequest.propertyAddress.zip = this.getAddressFormGroup().get('zip').value

    return this._briefSearchRequest;
  }

  mapSavedSearchToForm(searched: BriefSearchRequest): void {
    this.briefPolicySearchForm.get('firstName').setValue(searched.firstName);
    this.briefPolicySearchForm.get('lastName').setValue(searched.lastName);
    this.briefPolicySearchForm.get('middleInitial').setValue(searched.middleInitial);
    this.briefPolicySearchForm.get('suffix').setValue(searched.suffix);
    this._briefSearchRequest.policyTypeCode = policyTypeCode.HOME;
    this.getAddressFormGroup().get('streetAddressOne').setValue(searched.propertyAddress.address1);
    this.getAddressFormGroup().get('streetAddressTwo').setValue(searched.propertyAddress.address2);
    this.getAddressFormGroup().get('city').setValue(searched.propertyAddress.city);
    this.getAddressFormGroup().get('state').setValue(searched.propertyAddress.state);
    this.getAddressFormGroup().get('zip').setValue(searched.propertyAddress.zip);
  }

  search(): void {
    this.isSearching = this._pbc.isSearching = true;
    this._modalService.open('searching');
    this._pbc.showProgressBar();
  }

}
