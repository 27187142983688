<div class="section confirm">
  <div class="grid-container">
    <div>
      <h2 *ngIf="status==='Pending'">Your Account Is Currently Pending Review</h2>
      <h2 *ngIf="status==='Activation-Unsuccessful' || status==='NoStatus'">Unable to activate account</h2>
      <h2 *ngIf="status==='Locked'">Your Account Is Locked</h2>

      <p *ngIf="status==='Pending'">
        We're currently reviewing your account registration details and
        will follow up with you within 1 business day. If you have any questions, please email us at <a href="mailto:MERSHOI.SUPPORT@ice.com">MERSHOI.SUPPORT@ice.com</a>
        or call <a href="tel:8886806377">(888) 680-6377</a>.
      </p>

      <p *ngIf="status==='Activation-Unsuccessful' || status==='NoStatus'">
        Thank you for your patience as we reviewed your registration details. At this time, we are unable to activate your account
        based on the information provided.
        If you have any questions, please email us at <a href="mailto:MERSHOI.SUPPORT@ice.com">MERSHOI.SUPPORT@ice.com</a>
        or call <a href="tel:8886806377">(888) 680-6377</a>.
      </p>

      <p *ngIf="status==='Locked'">
        If you have any questions, please email us at <a href="mailto:MERSHOI.SUPPORT@ice.com">MERSHOI.SUPPORT@ice.com</a>
        or call <a href="tel:8886806377">(888) 680-6377</a>.
      </p>
      <div class="input-btn">
        <button
          (click)="returnToHome()"
          type="submit"
          role="button"
          aria-label="Get Started">
          Return To Home
        </button>
      </div>
    </div>
  </div>
</div>
