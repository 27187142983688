import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { UsersService } from '../../services/users/users.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(userService: UsersService) {
    this._usersService = userService
  }

  private _usersService: UsersService;
 

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authKey = '';
    if (this._usersService.userProfile_() !== null) {
      authKey = this._usersService.authorizationKey;
    }

    if (authKey !== null) {
      request = request.clone({
        setHeaders: {
          'auth-settings': 'user-Bearer',
          'Ocp-Apim-Subscription-Key': `${environment.subscriptionKey}`,
          'X-AuthorizationKey': authKey,
          'observe': 'response',
          'Access-Control-Allow-Headers': '*',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET, POST, PUT'
        }
      });
    } else {
      request = request.clone({
        setHeaders: {
          'auth-settings': 'user-Bearer',
          'Ocp-Apim-Subscription-Key': `${environment.subscriptionKey}`,
          'observe': 'response',
          'Access-Control-Allow-Headers': '*',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET, POST, PUT'
        }
      });
    }

    return next.handle(request);
  }
}
