<app-custom-modal id="noPaymentMethod">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2>Default Payment Method Required</h2>
      </div>
      <div class="modal-body">
        <p>For a more streamlined experience, please set a default payment method for this account. </p>
        <p><strong>Get Access to:</strong></p>
        <ul class="payment-method-modal-list">
          <li>View Search Results</li>
          <li>Purchase Results</li>
          <li>Download Results</li>
        </ul>
      </div>
      <div class="modal-action">
        <div class="input-btn">
          <button routerLinkActive="router-link-active"
                  type="reset"
                  role="button"
                  aria-label="Cancel"
                  (click)="closeModal('noPaymentMethod')">
            Cancel
          </button>
          <button routerLinkActive="router-link-active"
                  type="submit"
                  role="button"
                  aria-label="Add Payment Method"
                  (click)="onAddPaymentMethodClick()">
            Update Default Payment Method
          </button>
        </div>
      </div>
    </div>
  </div>
</app-custom-modal>
