import { Component, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactAndConsent } from '../../../../interfaces/loss-payee-express/contact-and-consent';
import { LossPayeeService } from '../../../../services/loss-payee/loss-payee.service';
import { UploaderComponent } from '../../../../shared/uploader/uploader.component';

@Component({
  selector: 'app-contact-and-consent',
  templateUrl: './contact-and-consent.component.html',
  styleUrls: ['./contact-and-consent.component.css']
})
export class ContactAndConsentComponent implements OnInit, AfterViewInit {
  @Input() consent: ContactAndConsent;
  @Input() reviewing: boolean;
  @ViewChild(UploaderComponent) fileUploader: UploaderComponent;

  consentForm: FormGroup;
  fileToUpload: File;
  fileToBeUploaded = '';
  isReviewing = false;


  constructor(
    private _formBuilder: FormBuilder,
    public lossPayeeService: LossPayeeService) {
  }

  ngOnInit(): void {
    this.consentForm = this.buildForm();
  }

  ngAfterViewInit(): void {
    this.isReviewing = this.reviewing;
    let c = this.lossPayeeService.contactAndConsent;

    if (c === this.consent && this.lossPayeeService.contactAndConsent !== null) {
      this.mapModelToForm(c);
    }
    
    if (this.isReviewing === true) {
      this.consentForm = this.noValidationForm();
      this.mapModelToForm(c);
    }
  }

  mapFormToModel(): void {
    let contact = {} as ContactAndConsent; 
    contact.email = this.consentForm.get('email').value;
    contact.consentFile = this.fileUploader.files;
    this.lossPayeeService.contactAndConsent = contact;
  }

  mapModelToForm(c: ContactAndConsent): void {
    this.consentForm.get('email').setValue(c.email);    
    this.consentForm.get('file').setValue(this.lossPayeeService.singleFileUpload.name);

    if (this.lossPayeeService.singleFileUpload !== undefined) {
      this.fileUploader.files = this.lossPayeeService.contactAndConsent.consentFile;
      this.fileToBeUploaded = this.lossPayeeService.singleFileUpload.name;
    }
  }

  processFiles(files: Set<File>): void {
    if (files.size > 0) {
      this.lossPayeeService.contactAndConsent.consentFile = files;

      let n = files.values();
      for (let f of n) {
        this.lossPayeeService.singleFileUpload = f;
        this.fileToBeUploaded = f.name;
        this.consentForm.get('file').setValue(f.name);
      }
    }
    else {
      this.consentForm.get('file').setValue('');
    }
  }

  resetForm() {
    this.consentForm.reset();
    this.lossPayeeService.contactAndConsent = null;
    this.consentForm = this.buildForm();
    this.consentForm.updateValueAndValidity();
  }

  private buildForm(): FormGroup {
    return this._formBuilder.group({
      email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')]],
      file:['', [Validators.required, Validators.minLength(1)]]
    })
  }

  private noValidationForm(): FormGroup {
    return this._formBuilder.group({
      email: [''],
      file:['']
    })
  }

}

