import { Component, ViewChild } from '@angular/core';
import { ModalService } from '../../../../services/modals/modals.service';
import { PaymentOptionsComponent } from '../../../../modules/account/my-account/payment-options/payment-options.component';
import { PaymentDataEvents } from '../../../../modules/payment/payment-data.events/payment-data.events';
import { PaymentMethodViewModel } from '../../../../interfaces/payment/get-payment-method-view-model-request';
import { switchMap } from 'rxjs/operators';
import { UsersService } from '../../../../services/users/users.service';
import { PaymentMethodService } from '../../../../services/payment/payment.method.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorMessage } from '../../../models/error-message.enum';

@Component({
  selector: 'app-delete-payment-method-modal',
  templateUrl: './delete-payment-method-modal.component.html',
  styleUrls: ['./delete-payment-method-modal.component.scss']
})
export class DeletePaymentMethodModalComponent {
  @ViewChild(PaymentOptionsComponent)
  public _paymentOptionsComponent: PaymentOptionsComponent;
  public selectedPaymentMethod: string;
  public paymentToDelete: PaymentMethodViewModel = null;
  public containsPaymentToDelete = false;
  constructor(
    private _modalService: ModalService,
    private _paymentDataEvents: PaymentDataEvents,
    private _usersService: UsersService,
    private _paymentMethodService: PaymentMethodService,
    private _toastrService: ToastrService) {
    this.getPaymentToDelete();
  }

  public deletePaymentMethod(paymentMethod: PaymentMethodViewModel) {
    this._usersService
      .userProfile_()
      .pipe(switchMap(userProfile => this._paymentMethodService.deletePaymentMethod(userProfile.userId, paymentMethod.paymentMethodId)))
      .subscribe(
        success => {
          this._toastrService.success('Payment method deleted successfully');
        },
        error => this.onError()
      );
  }

  public onDeletePaymentMethodClick(): void {
    this.deletePaymentMethod(this.paymentToDelete);
    this.closeModal('deletePaymentMethod');
  }

  public getPaymentToDelete(): void {
    this._paymentDataEvents.paymentMethodDeleteObject_.subscribe((paymentToDelete) => {
      if (paymentToDelete !== null) {
        this.containsPaymentToDelete = true;
        this.paymentToDelete = paymentToDelete;
      }
    })
  }

  closeModal(id: string) {
    this._modalService.close(id);
  }

  private onError() {
    this._toastrService.error(ErrorMessage.GenericError);
  }

}
