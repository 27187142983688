import { Component, OnInit, AfterViewChecked, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { BriefPolicySearchDataEvents } from './policy-search-data.events/policy-search-data.events';
import { PaymentMethod } from '../../interfaces/payment/get-payment-method-request';
import { AddressViewModel } from '../../interfaces/address/addressViewModel';
import { BriefSearchRequest } from '../../interfaces/search/brief-search-request';
import { Person } from '../../interfaces/search/person';
import { PaymentDataEvents } from '../payment/payment-data.events/payment-data.events';
import { ApiResponse } from '../../interfaces/api/apiResponse';
import { Header } from '../../shared/enum/headers.enum';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})

export class SearchComponent implements OnInit, OnDestroy, AfterViewChecked {
  private _subscriptions: Subscription[] = [];

  public paymentMethod: PaymentMethod;
  public property: BriefSearchRequest;
  public person = {} as Person;
  public formattedPerson: string;
  public address = {} as AddressViewModel;
  public userPaymentMethod: HttpResponse<ApiResponse<PaymentMethod>>;
  public userHasPaymentMethod = false;
  searchHeader: Header = Header.SearchResults;

  constructor(
    private _dataEvents: BriefPolicySearchDataEvents,
    private _paymentDataEvents: PaymentDataEvents,
    private _activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.userPaymentMethod = this._activatedRoute.snapshot.data['payment'];
    this.userHasPaymentMethod = this.userPaymentMethod.body.data.paymentMethods.length > 0;
    this.syncSearchResults();
  }

  ngOnDestroy(): void {
    if (this._subscriptions && this._subscriptions.length > 0) {
      while (this._subscriptions.length > 0) {
        let sub = this._subscriptions.pop();
        sub.unsubscribe();
        sub = null;
      }
    }
  }

  syncSearchResults(): void {
    this._subscriptions.push(this._dataEvents.propertySearched_.subscribe(searchProperty => {
      if (searchProperty !== null) {
        this.property = searchProperty;
        this.address = searchProperty.propertyAddress;
        this.person = this.mapPersonToModel(searchProperty);
      } else {
        this.address = null;
        this.person = null;
      }
    }));
  }

  mapPersonToModel(request: BriefSearchRequest): Person {
    let p = {} as Person;
    p.firstName = request.firstName;
    p.middleInitial = request.middleInitial;
    p.lastName = request.lastName;
    p.policyTypeCode = '0';
    p.suffix = request.suffix;
    return p;
  }

  ngAfterViewChecked(): void {
    this._subscriptions.push(this._paymentDataEvents.paymentMethod_.pipe(take(1)).subscribe(paymentMethod => {
      this.paymentMethod = paymentMethod;
    }));
  }

}
