import { Pipe, PipeTransform } from '@angular/core';
import {CardTypes} from '../../shared/models/card-types.enum';

@Pipe({
    name: 'fullCardName'
})

export class FullCardNamePipe implements PipeTransform {
    transform(value: string) : string {
        if (value) {
            var cardType = CardTypes[value.toUpperCase()];
            return cardType ?? value.toUpperCase();
        }
        return null;
    }
}