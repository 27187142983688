<div [ngClass]="'container'">
  <h2>Training Materials</h2>

  <p>
    Below you will find our training guide that will help you make the most of your experience with MERS HOI Hub. This in-depth guide will walk you
    through several critical topics necessary to retrieve homeowners insurance.
  </p>
  <h3>Our training guide covers:</h3>

  <div [ngClass]="'category-grid'">
    <div [ngClass]="'create-account'">Creating an Account</div>
    <div [ngClass]="'finish-setup'">Finishing Account Setup</div>
    <div [ngClass]="'create-payment'">Creating Payment Methods</div>
    <div [ngClass]="'search-consumers'">Searching for Homeowners Insurance</div>
    <div [ngClass]="'review-transactions'">Reviewing Past Transactions</div>
    <label [ngClass]="'download-button'"><a href="../../assets/user-guide.pdf" download="MERS HOI Hub User Guide.pdf"> Download Training Guide</a> </label>
  </div>

</div>
