<div [formGroup]="group"
     class="form-group"
     [className]="hasErrorClassName">
  <label style="margin-top: 25px" for="{{controlName}}">{{labelName}}:
    <strong class="nex-color-grey-nex"
            *ngIf="isRequired && canMarkAsOptional">(optional)</strong>
  </label>
  <input class="form-control nex-input-regular"
         type="{{type}}"
         formControlName="{{controlName}}"
         placeholder="{{placeholder}}"
         name="{{controlName}}"
         id="{{controlName}}"
         minlength="{{minLength}}"
         maxlength="{{maxlength}}"
         [attr.appnumberinput]="numbersOnly ? numbersOnly : null"
         (input)="input.emit($event)">
  <ng-content></ng-content>
  <div *ngFor="let validation of validationMessages['answer']">
    <span class="help-block"
          *ngIf="hasFieldValidationError(controlName, validation.type)">{{validation.message}}</span>
  </div>
</div>
