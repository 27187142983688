<div [formGroup]="group"
     class="form-group"
     [className]="hasErrorClassName">
  <label for="{{controlName}}">{{labelName}}:
     </label>
  <input class="form-control nex-input-regular"
         type="{{type}}"
         formControlName="{{controlName}}"
         placeholder="{{placeholder}}"
         name="{{controlName}}"
         id="{{controlName}}"
         minLength="{{minLength}}"
         maxLength="{{maxLength}}"
         appnumberinput
         numberOnly="true"
         (input)="input.emit($event)"
         mask="99999">
  <ng-content></ng-content>
  <div *ngFor="let validation of validationMessages['answer']">
    <span class="help-block"
          *ngIf="hasFieldValidationError(controlName, validation.type)">{{validation.message}}</span>
  </div>
</div>
