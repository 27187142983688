import { Injectable, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TitleService implements OnDestroy{
  defaultTitle = 'MERS HOI Hub'
  private subs: Subscription[] = [];

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private title: Title) { }

  ngOnDestroy(): void {

    if (this.subs && this.subs.length > 0) {
      while (this.subs.length > 0) {
        let sub = this.subs.pop();
        sub.unsubscribe();
        sub = null;
      }
    }
  }

  boot(): void {
    this.subs.push(this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let child = this.activatedRoute.firstChild;
        if (child !== null) {
          while (child.firstChild) {
            child = child.firstChild;
          }
          if (child.snapshot.data['title']) {
            return child.snapshot.data['title'];
          }
        }
        return this.defaultTitle;
      }),
    ).subscribe((currentTitle) => this.title.setTitle(currentTitle)));
  }
}
