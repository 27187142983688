import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RouteTrackerService {

  private _currentUrl = '';

  constructor(private _router: Router) {

    _router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this._currentUrl = e.url;
      }
    });
  }

  public getPreviousUrl(): string {
    return this._router.url;
  }

  public hasFilteredUrl (): boolean {
    const _filter = [
      '/state',
      '/error',
      '/terms'
    ];
    const prevUrl = this.getPreviousUrl();
    for (let x=0; x<_filter.length; x++) {
      if (prevUrl === '/' || prevUrl.indexOf(_filter[x]) === 0) {
        return true;
      }
    }
    return false;
  }

}
