<div *ngIf="searchResults">
  <div class="client-consent-modal-button"
     data-toggle="modal"
     data-target="#openClientConsent"
     data-backdrop="none"
     (click)="onClientConsentOpenClick()">
  <div class="font-weight-bold client-consent-text">Upload Borrower Consent</div>
  </div>

  <app-client-consent-modal (clientConsentUploaded)="onClientConsentOpenClick()"></app-client-consent-modal>
</div>
<app-no-payment-method-modal (addedPaymentMethod)="onAddPaymentMethodClick()"></app-no-payment-method-modal>
