import { Component, Output, EventEmitter, ViewChild, OnInit } from '@angular/core';
import { tap, switchMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { ModalService } from '../../../../services/modals/modals.service';
import { UploaderComponent } from '../../../uploader/uploader.component';
import { ClientConsentService } from '../../../../services/client-consent/client-consent.service';
import { BriefPolicySearchDataEvents } from '../../../../modules/search/policy-search-data.events/policy-search-data.events';
import { SearchService } from '../../../../services/search/search-service';
import { BriefSearchResponse } from '../../../../interfaces/search/brief-search-response';
import { BriefDetailsRequest } from '../../../../interfaces/search/brief-search-details-request';
import { ErrorMessage } from '../../../models/error-message.enum';

@Component({
  selector: 'app-client-consent-modal',
  templateUrl: './client-consent-modal.component.html',
  styleUrls: ['./client-consent-modal.component.scss']
})
export class ClientConsentModalComponent implements OnInit {

  @Output() addedClientConsent = new EventEmitter<boolean>();
  @ViewChild(UploaderComponent)
  private _uploaderComponent: UploaderComponent;
  public briefSearchID: string;
  public isLoading: boolean;
  public _briefSearchResults: BriefSearchResponse;
  public _briefSearchDetails: BriefDetailsRequest;
  public uploadedFiles: Set<File>;
  private evidenceKeys: string[] = [];

  constructor(
    private _modalService:          ModalService,
    private _clientConsentService:  ClientConsentService,
    private _toastrService:         ToastrService,
    private _dataService:           BriefPolicySearchDataEvents,
    private _searchService:         SearchService) {

  }

  public ngOnInit(): void {
    this.uploadedFiles = new Set<File>();
    this._dataService.briefPolicySearch.subscribe(searchResult => {
      if (searchResult && searchResult.briefSearchResults) {
        this.briefSearchID = searchResult.briefSearchId;
      }
    });

    this._dataService.briefPolicySearch.subscribe(
      searchResults => {
        if (searchResults) {
          this._briefSearchResults = searchResults;
        }
      }
    );

  }

  public onClientConsentAdded(isSuccessful: boolean): void {
    if (isSuccessful) {
      this.addedClientConsent.emit(true);
    }
  }

  public processFiles(files: Set<File>): void {
    this.uploadedFiles = files;
  }

  mapDetailsRequest(): BriefDetailsRequest{
    this._briefSearchDetails = {briefSearchId: null, evidenceKeys: null};
    this._briefSearchDetails.briefSearchId = this._briefSearchResults.briefSearchId;

    this._briefSearchResults.briefSearchResults.forEach(element => {
      this.evidenceKeys.push(element['evidenceKey'])
    });

    this._briefSearchDetails.evidenceKeys = this.evidenceKeys;

    return this._briefSearchDetails;
  }

  private getDetailBriefs() {
    return this._searchService
      .getDetailsBriefs(this.mapDetailsRequest())
      .pipe(
        tap(briefDetailsSearchResults => {
          this._dataService.updateBriefDetailsSearchResults(
            briefDetailsSearchResults.data
          );
        })
      );
  }

  public onSubmitClick(): void {
    if (this.uploadedFiles.size === 0) {
      if (this.doesSelectFileExistWithinValidations()) {
        this._uploaderComponent.validations.push('Please select a file');
      }
      return;
    }
    this.uploadClientConsent();
  }

  private uploadClientConsent(): void {
    this.isLoading = true;
    this._clientConsentService.uploadCertificateOfAuthorization(this.briefSearchID, this.uploadedFiles)
      .pipe(switchMap(() => this.getDetailBriefs().toPromise()
        .then(() => this.onUploadSuccess()))
    ).subscribe(
      res => {
        if (res !== null) {
          this.onUploadSuccess();
        }
      },
      e => {
        this.onUploadFailure();
      }
    )
  }

  private onUploadSuccess() {
    this.isLoading = false;
    this._dataService.updateFileUploadCompleted(true);
    this.onCancelClick();
  }

  private onUploadFailure() {
    this.isLoading = false;
    this._toastrService.error(ErrorMessage.GenericError);
    this.onCancelClick();
  }

  public onCancelClick(): void {
    this.uploadedFiles = new Set<File>();
    this._uploaderComponent.resetUploader();
    this.closeModal('clientConsent');
  }

  closeModal(id: string) {
    this._modalService.close(id);
  }

  private doesSelectFileExistWithinValidations(): boolean {
    return (
      this._uploaderComponent.validations.indexOf('Please select a file') === -1
    );
  }
}
