import { Component, Output, EventEmitter } from '@angular/core';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent  {
  // eslint-disable-no-output-rename
  @Output() childNavbarOption: EventEmitter<string> = new EventEmitter<string>();
  public navbarOption: string;

  public scrollToTop(): void {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  public createAccountUrl(): void {
      window.location.assign(`${environment.clearHoiUrl}\#`);
  }

  setParentOption(): void {
    this.childNavbarOption.emit('1');
  }

}
