import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UsersService } from '../../services/users/users.service';
import { UserProfile } from '../../interfaces/users/user-profile';
import { AccountStatusService } from '../../services/accountStatus/account-status.service';
import { statusType } from '../../interfaces/users/statusType';
import { AccountInfo } from '@azure/msal-browser';


@Injectable({
  providedIn: 'root'
})

export class TermsAndConditionsGuard implements CanActivate {

  private _accountStatus = '';
  private _user: AccountInfo;

  public userProfile: UserProfile;
  

  constructor(
    private _accountStatusService: AccountStatusService,
    private _router: Router,
    private _userService: UsersService) {
      this._user = _userService.getMSALUser();
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    return this.termsAndConditionsRequired();
  }

  private termsAndConditionsRequired(): Promise<boolean> {
    return new Promise((resolve) => {
      this._userService.fetchUserProfile().toPromise().then(
        (userProfile) => {
          this.userProfile = userProfile;

          this._accountStatus = this._accountStatusService.setAccountStatus(userProfile);

          if (this._accountStatus === 'Pending' || this._accountStatus === 'Rejected' || this._accountStatus === 'Locked') {
            resolve(false);
            this._router.navigate(['/account-status'], { queryParams: { accountStatus: statusType[this._accountStatus] } });
          } else if (this.userProfile.termsAndConditionsRequired === null) {
            resolve(false);
          } else {
            resolve(true);
          }
        });
    });

  }

}
