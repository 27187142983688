import { Component, Input } from '@angular/core';
import { ModalService } from '../../../services/modals/modals.service';

@Component({
  selector: 'app-mortgagee-request',
  templateUrl: './mortgagee-request.component.html',
  styleUrls: ['./mortgagee-request.component.css']
})
export class MortgageeRequestComponent {

  //  These input variables receive information from the LossPayeeComponent
  // eslint-disable-no-input-rename
  @Input('requestSent') requestSent = false;
  // eslint-disable-no-input-rename
  @Input('businessName') business: string;
  // eslint-disable-no-input-rename
  @Input('businessAddress1') address1: string;
  // eslint-disable-no-input-rename
  @Input('businessAddress2') address2: string;
  // eslint-disable-no-input-rename
  @Input('businessCity') city: string;
  // eslint-disable-no-input-rename
  @Input('businessState') state: string;
  // eslint-disable-no-input-rename
  @Input('businessZip') zip: string;
  // eslint-disable-no-input-rename
  @Input('records') totalRecords: number;
  // eslint-disable-no-input-rename
  @Input('showAll') showAllRecords = false;
  // eslint-disable-no-input-rename
  @Input('itemSelected') finish = false;
  // eslint-disable-no-input-rename
  @Input('mortgageeAdded') mortgageeAdded = false;

  constructor(private _modalService: ModalService) { }

  openMortgageeRequestModal() {
    this._modalService.open('newMortgageeRequest');
  }
}
