<app-custom-modal id="contact-insurance">
  <div class="modal-dialog"  role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2>Contact Insurer</h2>
      </div>
      <div class="modal-body">
        <p>
          The insurer requires that you reach out to them regarding this mortgagee update.
        </p>
        <p *ngIf="insurer !== null && (insurer.lossPayeePhone !== '' || insurer.lossPayeeSupportUrl !== '')" class="contact-insurer-text">
         You can contact {{insurer.name}}  <span *ngIf="insurer.lossPayeePhone !== ''" > at: <a class="loss-payee-support-phone" [href]="'tel:' + insurer.lossPayeePhone">{{insurer.lossPayeePhone | phoneFormat}}</a></span><span *ngIf="insurer.lossPayeeSupportUrl !== ''">
          <span *ngIf="insurer.lossPayeeSupportUrl !== '' && insurer.lossPayeePhone !== ''"> or</span> by clicking <a [href]="insurer.lossPayeeSupportUrl"  target="_blank">here</a></span>.
        </p>
        <p *ngIf="insurer === null || (insurer.lossPayeePhone === '' && insurer.lossPayeeSupportUrl === '')">
          You can contact us at MERSHOI.SUPPORT@ice.com to discuss next steps
        </p>
        <p>
          <span *ngIf="insurer !== null && (insurer.lossPayeePhone !== '' || insurer.lossPayeeSupportUrl !== '')">Please have the following available when you contact them.</span>
          <span *ngIf="insurer === null || (insurer.lossPayeePhone === '' && insurer.lossPayeeSupportUrl === '')">Please have the following available when you reach out.</span><br/>
        </p>
        <ul>
          <li>Policy number</li>
          <li>Mortgagee update details</li>
        </ul>
      </div>
    </div>
    <div class="modal-action">
      <div class="input-btn">
        <button (click)="closeModal()">Close</button>
      </div>
    </div>
  </div>
</app-custom-modal>
