import { Component, OnInit } from '@angular/core';
import { Insurer } from 'src/app/interfaces/loss-payee/loss-payee-insurer';
import { ModalService } from 'src/app/services/modals/modals.service';

@Component({
  selector: 'app-contact-insurer-modal',
  templateUrl: './contact-insurer-modal.component.html',
  styleUrls: ['./contact-insurer-modal.component.scss']
})
export class ContactInsurerModalComponent {
  public insurer = {} as  Insurer;

  constructor(
    private _modalService: ModalService) { }

  closeModal() {
    this._modalService.close('contact-insurance');
  }
}
