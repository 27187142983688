import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ContactAndConsent } from '../../../../interfaces/loss-payee-express/contact-and-consent';
import { MortgageeDetails } from '../../../../interfaces/loss-payee-express/mortgagee-details';
import { PolicyInformation } from '../../../../interfaces/loss-payee-express/policy-information';
import { LossPayeeService } from '../../../../services/loss-payee/loss-payee.service';

@Component({
  selector: 'app-review-submit',
  templateUrl: './review-submit.component.html',
  styleUrls: ['./review-submit.component.scss']
})
export class ReviewSubmitComponent implements OnInit {

  @Input() policyInformation: PolicyInformation;
  @Input() mortgageeDetailsInput: MortgageeDetails;
  @Input() consentInformation: ContactAndConsent;
  @Input() addingNewMortgagee: boolean;
  @Input() review: boolean;

  @Output() changeScreen = new EventEmitter<number>();

  mortgageeDetails = {} as MortgageeDetails;
  policyDetails = {} as PolicyInformation;
  consentDetails = {} as ContactAndConsent;
  isReviewing = true;


  constructor(private _lossPayeeService: LossPayeeService) {}

  ngOnInit(): void {
    this.listenForEvents();
    this.policyDetails = this._lossPayeeService.policyInformation;
    this.mortgageeDetails = this._lossPayeeService.mortgageeDetails;
    this.consentDetails = this.consentInformation;
  }

  listenForEvents() {

    let acc = document.getElementsByClassName('accordion');

    for (let i = 0; i < acc.length; i++) {
      acc[i].addEventListener('click', function () {
        this.classList.toggle('active');
        var panel = this.nextElementSibling;
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = panel.scrollHeight + 'px';
        }
      });
    }

  }

  navigateToScreen(screen: number) {
    this.changeScreen.emit(screen);
    console.log('review change screen ' + screen);
  }

}
