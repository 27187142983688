<div class="main-container">
  <div id="navigation" class="nav-bar">
    <ul>
      <li class="heading">My Account</li>
      <li>
        <a [ngClass]="this.accountOption === 'PAYMENT' ? 'active' : ''" [routerLink]="['/my-account']" [queryParams]="{setOption: 0}">
          <div class="static" id="PAYMENT">Payment Options</div>
        </a>
      </li>
      <li>
        <a [ngClass]="this.accountOption === 'TRANSACTIONS' ? 'active' : ''" [routerLink]="['/my-account']" [queryParams]="{setOption: 1}">
          <div class="static" id="TRANSACTIONS">Transaction History</div>
        </a>
      </li>
      <li>
        <a [ngClass]="this.accountOption === 'MORTGAGEE' ? 'active' : ''" *ngIf="this.lossPayeeEnabled === true || this.lossPayeeExpressEnabled === true" [routerLink]="['/my-account']" [queryParams]="{setOption: 2}">
          <div class="static" id="MORTGAGEE">Mortgagee Update History</div>
        </a>
      </li>
    </ul>
  </div>
  <div class="content">
    <h1>My Account</h1>
    <div class="main-section">
      <app-payment-options *ngIf="this.accountOption ==='PAYMENT'"></app-payment-options>
      <app-transaction-history *ngIf="this.accountOption ==='TRANSACTIONS'"></app-transaction-history>
      <app-mortgagee-update-history *ngIf="this.accountOption ==='MORTGAGEE' && (this.lossPayeeEnabled === true || this.lossPayeeExpressEnabled === true)"></app-mortgagee-update-history>
    </div>
  </div>
</div>
