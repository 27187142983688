import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ApplicationRef, DoBootstrap } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { UrlSerializer } from '@angular/router';
import {
  MsalInterceptor, MsalBroadcastService,
  MsalInterceptorConfiguration, MsalModule, MsalService,
  MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration
} from '@azure/msal-angular';

import { PublicClientApplication, InteractionType, BrowserCacheLocation, IPublicClientApplication } from '@azure/msal-browser';

import { ToastrModule, } from 'ngx-toastr';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from 'ngx-mask'

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SearchComponent } from './modules/search/search.component';
import { AccountStatusComponent } from './modules/account/account-status/account-status.component';
import { AccountDetailsComponent } from './modules/account/account-details/account-details.component';
import { PolicySearchComponent } from './modules/search/policy-search/policy-search.component';
import { NoPaymentMethodComponent } from './modules/payment/no-payment-method/no-payment-method.component';
import { PolicySearchResultsComponent } from './modules/search/policy-search-results/policy-search-results.component';

import { PaymentMethodModalComponent } from './shared/components/modal-dialogs/payment-method-modal/payment-method-modal.component';
import { NoPaymentMethodModalComponent } from './shared/components/modal-dialogs/no-payment-method-modal/no-payment-method-modal.component';
import { DeletePaymentMethodModalComponent } from './shared/components/modal-dialogs/delete-payment-method-modal/delete-payment-method-modal.component';

import { HeaderComponent } from './modules/layout/header/header.component';
import { NavbarComponent } from './modules/layout/navbar/navbar.component';
import { FooterComponent } from './modules/layout/footer/footer.component';
import { ModalsComponent } from './shared/components/modal-dialogs/modals.component';
import { InputComponent } from './shared/components/input/input.component';
import { ZipCodeComponent } from './shared/components/zip-code/zip-code.component';
import { LoginComponent } from './modules/login/login.component';
import { ErrorComponent } from './modules/error/error.component';
import { Error500Component } from './modules/error/500/error500.component';
import { TransactionHistoryComponent } from './modules/account/my-account/transaction-history/transaction-history.component';
import { PaymentOptionsComponent } from './modules/account/my-account/payment-options/payment-options.component';

import { AddressPipe } from './shared/pipes/address.pipe';
import { SafePipe } from './shared/pipes/safe.pipe';
import { PersonPipe } from './shared/pipes/person.pipe';

import { AuthInterceptor } from './shared/interceptors/auth.interceptors';
import { UsersService } from './services/users/users.service';
import { ModalService } from './services/modals/modals.service';
import { ClientConsentComponent } from './modules/client-consent/client-consent.component';
import { ClientConsentModalComponent } from './shared/components/modal-dialogs/client-consent-modal/client-consent-modal.component';
import { UploaderComponent } from './shared/uploader/uploader.component';
import { FileValidator } from './shared/uploader/services/file-validator.service';
import { PaymentMethodService } from './services/payment/payment.method.service';
import { ClientConsentService } from './services/client-consent/client-consent.service';
import { ToUpperPipe } from './shared/pipes/to-upper.pipe';
import { TruncateTextPipe } from './shared/pipes/truncate.pipe';
import { TitleService } from './services/title.service';
import { MyAccountComponent } from './modules/account/my-account/my-account.component';
import { FullCardNamePipe } from './shared/pipes/full-card-name.pipe';
import { TermsComponent } from './modules/terms/terms.component';
import { ProgressBarComponent } from './shared/components/modal-dialogs/progress-bar-modal/progress-bar.component';
import { BannerComponent } from './modules/banner/banner.component';
import { HelpComponent } from './modules/help/help.component';
import { FaqComponent } from './modules/help/faq/faq.component';
import { TrainingMaterialsComponent } from './modules/help/training-materials/training-materials.component';
import { FeatureFlagDirective } from './directives/feature-flag.directive';
import { LossPayeeComponent } from './modules/loss-payee/loss-payee.component';
import { MortgageeRequestComponent } from './modules/loss-payee/mortgagee-request/mortgagee-request.component';
import { NewMortgageeRequestModalComponent } from './shared/components/modal-dialogs/mortgagee/new-mortgagee-request-modal/new-mortgagee-request-modal.component';
import { RequestSentModalComponent } from './shared/components/modal-dialogs/mortgagee/request-sent-modal/request-sent-modal.component';
import { LossPayeeRequestComponent } from './modules/loss-payee/loss-payee-request/loss-payee-request.component';
import { LossPayeeSuccessComponent } from './modules/loss-payee/loss-payee-success/loss-payee-success.component';
import { MortgageeUpdateHistoryComponent } from './modules/account/my-account/mortgagee-update-history/mortgagee-update-history.component';
import { ContactInsurerModalComponent } from './shared/components/modal-dialogs/contact-insurer-modal/contact-insurer-modal.component';
import { PhoneFormatPipe } from './shared/pipes/phone-format.pipe';
import { AddMortgageeSuggestionsModalComponent } from './shared/components/modal-dialogs/add-mortgaee-suggestion-modal/add-mortgagee-suggestions-modal.component';
import { MortgageeExistsModalComponent } from './shared/components/modal-dialogs/mortgagee-exists-modal/mortgagee-exists-modal.component';
import { PaymentMethodComponent } from './modules/payment/payment-method/payment-method.component';
import { LossPayeeExpressComponent } from './modules/loss-payee/loss-payee-express/loss-payee-express.component';
import { HomeComponent } from './modules/home/home.component';
import { PolicyInformationComponent } from 'src/app/modules/loss-payee/loss-payee-express/policy-information/policy-information.component';
import { MortgageeDetailsComponent } from './modules/loss-payee/loss-payee-express/mortgagee-details/mortgagee-details.component';
import { ContactAndConsentComponent } from './modules/loss-payee/loss-payee-express/contact-and-consent/contact-and-consent.component';
import { DeliveredToInsurerModalComponent } from './shared/components/modal-dialogs/delivered-to-insurer-modal/delivered-to-insurer-modal.component';
import { ReviewSubmitComponent } from './modules/loss-payee/loss-payee-express/review-submit/review-submit.component';
import { OrderSummaryComponent } from './modules/loss-payee/loss-payee-express/order-summary/order-summary.component';
import { LossPayeeConfirmComponent } from './modules/loss-payee/loss-payee-express/loss-payee-confirm/loss-payee-confirm.component';
import { SessionTimeoutComponent } from './shared/components/modal-dialogs/session-timeout-modal/session-timeout.component';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.azureB2C.clientID,
      authority: environment.azureB2C.authority,
      redirectUri: environment.azureB2C.redirectUri,
      postLogoutRedirectUri: environment.azureB2C.redirectUri,
      knownAuthorities: [
        environment.azureB2C.authority,
        environment.azureB2C.passwordResetPolicy
      ]
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.portalApiUrl, [environment.apiScope]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['clearhoib2cnp.onmicrosoft.com']
    },
    loginFailedRoute: './login-failed'
  };
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    NavbarComponent,
    FooterComponent,
    SearchComponent,
    PolicySearchComponent,
    ModalsComponent,
    ClientConsentComponent,
    ClientConsentModalComponent,
    NoPaymentMethodComponent,
    PolicySearchResultsComponent,
    NoPaymentMethodModalComponent,
    PaymentMethodModalComponent,
    DeletePaymentMethodModalComponent,
    InputComponent,
    ZipCodeComponent,
    LoginComponent,
    ErrorComponent,
    Error500Component,
    AccountStatusComponent,
    AccountDetailsComponent,
    UploaderComponent,
    AddressPipe,
    SafePipe,
    ToUpperPipe,
    PersonPipe,
    PhoneFormatPipe,
    TruncateTextPipe,
    MyAccountComponent,
    TransactionHistoryComponent,
    PaymentOptionsComponent,
    FullCardNamePipe,
    TermsComponent,
    ProgressBarComponent,
    BannerComponent,
    HelpComponent,
    FaqComponent,
    TrainingMaterialsComponent,
    FeatureFlagDirective,
    LossPayeeComponent,
    MortgageeRequestComponent,
    NewMortgageeRequestModalComponent,
    RequestSentModalComponent,
    LossPayeeRequestComponent,
    LossPayeeSuccessComponent,
    MortgageeUpdateHistoryComponent,
    ContactInsurerModalComponent,
    AddMortgageeSuggestionsModalComponent,
    MortgageeExistsModalComponent,
    PaymentMethodComponent,
    HomeComponent,
    PaymentMethodComponent,
    LossPayeeExpressComponent,
    PolicyInformationComponent,
    MortgageeDetailsComponent,
    ContactAndConsentComponent,
    DeliveredToInsurerModalComponent,
    ReviewSubmitComponent,
    OrderSummaryComponent,
    LossPayeeConfirmComponent,
    SessionTimeoutComponent
  ],
  exports: [
    ModalsComponent,
    NoPaymentMethodModalComponent,
    PaymentMethodModalComponent,
    PaymentMethodComponent,
    ClientConsentComponent,
    UploaderComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-full-width',
      preventDuplicates: true,
    }),
    MsalModule,
    AppRoutingModule,
    NgIdleKeepaliveModule.forRoot()
  ],
  providers: [UsersService, HttpClientModule, ModalService, PaymentMethodService, ClientConsentService, TitleService,
    PaymentMethodComponent, AddressPipe, provideEnvironmentNgxMask(),
    {
      provide: FileValidator,
      useFactory: FileValidator,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useValue: {
        interactionType: InteractionType.Redirect
      } as MsalGuardConfiguration
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalBroadcastService,
    MsalInterceptor
  ],
  //bootstrap: [AppComponent]
})
export class AppModule implements DoBootstrap {
  constructor() {}

  // manually bootstrap the app
  ngDoBootstrap(appRef: ApplicationRef): void {
    // Check that the window is an iframe and not popup
    if (window !== window.parent && !window.opener) {
      // appRef.bootstrap(MsalComponent);
    } else {
      appRef.bootstrap(AppComponent);
    }
  }
}
