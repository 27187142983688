import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';


import { ApiResponse } from '../interfaces/api/apiResponse';
import { environment } from '../../environments/environment';
import { GetMortgageesResponse } from '../interfaces/loss-payee/get-mortgagees-response';
import { PolicyProperty } from '../interfaces/loss-payee/policy-property';
import { GetLossPayeeRequestsResponse } from '../interfaces/loss-payee/get-loss-payee-update-requests-response';
import { GetInsurersResponse } from '../interfaces/loss-payee/loss-payee-insurer';
import { CreateMortgageeRequest } from '../interfaces/mortgagee/create-mortgagee-request';
import { MortgageeViewModel } from '../interfaces/loss-payee/mortgagee-view-model';
import { CreateMortgageeResponse } from '../interfaces/mortgagee/create-mortgagee-response';


@Injectable({
  providedIn: 'root'
})
export class MortgageeService {

  private _httpClient: HttpClient;
  private _propertyInfo = new BehaviorSubject<PolicyProperty>(null);

  public requestSubject = new Subject<boolean>();
  public policyProperty = this._propertyInfo.asObservable();

  constructor(httpClient: HttpClient) { this._httpClient = httpClient; }
  

  isRequestSent(status: boolean): void {
    this.requestSubject.next(status);
  }

  mortgageePolicyInformation(policy: PolicyProperty): void {
    this._propertyInfo.next(policy); 
  }

  getMortgageesByMortgageeRequest(mortgagee: CreateMortgageeRequest, page?: number, pageSize?: number) : Observable<ApiResponse<GetMortgageesResponse>> {
    return this.getMortgageesByName(page, pageSize, this.getSearchTermFromMortgageeRequest(mortgagee));
  }

  getMortgageesByName(page: number, pageSize: number, mortgageeName: string): Observable<ApiResponse<GetMortgageesResponse>> {
    const options = {
      params: new HttpParams()
        .set('page', page?.toString())
        .set('pageSize', pageSize?.toString())
        .set('name', mortgageeName)
    }

    return this._httpClient.get<ApiResponse<GetMortgageesResponse>>
      (`${environment.portalApiUrl}v1/mortgagees`, options);

  }  

  getMortgageeUpdateRequests(page: number, pageSize: number): Observable<ApiResponse<GetLossPayeeRequestsResponse>> {
    
    const options = {
      params: new HttpParams()
        .set('page', page.toString())
        .set('pageSize', pageSize.toString())
    };

    return this._httpClient.get<ApiResponse<GetLossPayeeRequestsResponse>>
      (`${environment.portalApiUrl}v1/losspayee`, options);
  }

  getUpdatedEoiByLossPayeeRequestId(lossPayeeRequestId: string): Observable<HttpResponse<ArrayBuffer>> {
    return this._httpClient.get(`${environment.portalApiUrl}v1/policies/lossPayee/${lossPayeeRequestId}/eoiPdf`,
    {
      observe: 'response',
      responseType: 'arraybuffer'
    });
  }

  getInsurersForMortageeUpdateRequest(): Observable<ApiResponse<GetInsurersResponse>> {
    return this._httpClient.get<ApiResponse<GetInsurersResponse>>
      (`${environment.portalApiUrl}v1/policies/insurers`);
  }

  createMortgagee(userId: string, newMortgagee: CreateMortgageeRequest) : Observable<ApiResponse<CreateMortgageeResponse>> {
    return this._httpClient.post<ApiResponse<CreateMortgageeResponse>>
      (`${environment.portalApiUrl}v1/users/${userId}/mortgageerequests`, newMortgagee)
  }

  findExactMortgageeMatch(mortgagees: Array<MortgageeViewModel>, addedMortgagee: CreateMortgageeRequest) : MortgageeViewModel {
    return mortgagees.find(mortgagee => {
      return this.toLowerAndTrim(mortgagee.name) === this.toLowerAndTrim(addedMortgagee.name)
        && this.toLowerAndTrim(mortgagee.address1) === this.toLowerAndTrim(addedMortgagee.address1)
        && this.toLowerAndTrim(mortgagee.address2) === this.toLowerAndTrim(addedMortgagee.address2 ?? '')
        && this.toLowerAndTrim(mortgagee.city) === this.toLowerAndTrim(addedMortgagee.city)
        && this.toLowerAndTrim(mortgagee.state) === this.toLowerAndTrim(addedMortgagee.state)
        && this.toLowerAndTrim(mortgagee.zip.toString()) === this.toLowerAndTrim(addedMortgagee.zip)
    });
  }

  getMortgagees(addedMortgagee: CreateMortgageeRequest): Observable<ApiResponse<GetMortgageesResponse>> {
    let mortgageeSearchValue = `${addedMortgagee.name} ${this.transformAddressToString(addedMortgagee.address1, addedMortgagee.address2)}` +
      `${addedMortgagee.city} ${addedMortgagee.state} ${addedMortgagee.zip}`;

    let options = {
      params: new HttpParams()
        .set('name', mortgageeSearchValue)
    }

    return this._httpClient.get<ApiResponse<GetMortgageesResponse>>(`${environment.portalApiUrl}v1/mortgagees`, options);
  }

  private getSearchTermFromMortgageeRequest(addedMortgagee: CreateMortgageeRequest): string {
    return `${addedMortgagee.name} ${this.transformAddressToString(addedMortgagee.address1, addedMortgagee.address2)}` +
      `${addedMortgagee.city} ${addedMortgagee.state} ${addedMortgagee.zip}`;
  }

  private transformAddressToString(address1: string, address2: string): string {
    return address2 ? `${address1} ${address2} ` : `${address1} `;
  } 

  private toLowerAndTrim(field: string): string {
    return field?.toLowerCase().trim();
  }
}
