<h2>Payment Options</h2>
<section>
  <div>
    <div class="grid-container" *ngIf="showPaymentOptions">
      <div class="no-default-payment selected-payment nex-elevate-2" *ngIf="!isUsingPaymentMethod">
        <div class="nex-color-grey-nex nex-font-weight-bold no-default-payment-bold">No Default <br />Payment Method Selected</div>
        <div class="nex-color-grey-nex">(Use Actions To Set Default Payment Method)</div>
      </div>
        <div class="default-payment selected-payment nex-elevate-2" *ngIf="isUsingPaymentMethod">
          <div class="nickname nex-color-grey-nex nex-font-weight-bold">{{selectedPaymentMethod.nickname | toUpper }}<div class="nex-color-dark-green nex-font-weight-bold">{{selectedPaymentMethod.cardType | fullCardName }}</div></div>
          <div class="card-number nex-color-grey-nex nex-font-weight-bold" *ngIf="isUsingPaymentMethod">XXXX-XXXX-XXXX-{{selectedPaymentMethod.lastFourDigits}}</div>
          <div class="expiry nex-color-grey-nex nex-font-weight-bold">EXP {{selectedPaymentMethod.expiryDate}}<div class="nex-color-dark-green nex-font-weight-bold">DEFAULT</div></div>
        </div>
      <div class="add-new-payment nex-elevate-2" (click)="onAddPaymentMethodClick()">
        <i class="fas fa-plus-circle fa-5x mx-2 nex-color-grey-nex payment-icon"></i>
        <span class="mx-2 nex-color-grey-nex nex-font-weight-bold">Add New <br /> Payment Method</span>
      </div>
    </div>

    <section *ngIf="showPaymentOptions">
      <h3 class="nex-color-grey-nex">Your Credit/Debit Cards</h3>
      <div>
        <table class="table table-striped table-hover">
          <thead>
            <tr class="payment-table-border">
              <th width="21%" class="table-bot-pad table-start-pad">Payment Name</th>
              <th width="21%" class="table-bot-pad"> Payment Type</th>
              <th width="21%" class="table-bot-pad"> Ending In</th>
              <th width="21%" class="table-bot-pad"> Expires</th>
              <th width="16%" class="table-bot-pad table-end-pad">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let paymentMethod of availablePaymentMethods; let indexOfElement = index" class="payment-table-border payment-table-rows">
              <td scope="row" class="table-start-pad">{{paymentMethod.nickname}}</td>
              <td>{{paymentMethod.cardType | fullCardName }}</td>
              <td>{{paymentMethod.lastFourDigits}}</td>
              <td>{{paymentMethod.expiryDate}}</td>
              <td class="table-end-pad">
                <div [ngClass]="'popup'" style="font-size: 16px; font-weight: 700;" (click)="showPopup(indexOfElement)" (mouseleave)="hidePopup(indexOfElement)">
                  <span class="payment-actions-dots">...</span>
                  <div class="popup-text " [id]="'ellipsis'+ indexOfElement">
                      <div  *ngIf="paymentMethod.paymentMethodId !== selectedPaymentMethod.paymentMethodId"
                            [ngClass]="'ellipsis-row'"
                            data-toggle="modal"
                            data-target="#setDefault"
                            (click)="onClickUsePaymentMethod(paymentMethod)"><i class="fas fa-check-circle nex-color-success"></i> Set as default</div>
                      <div
                            [ngClass]="'ellipsis-row'"
                            data-toggle="modal"
                            data-target="#deletePaymentMethod"
                            (click)="onClickDeletePaymentMethod(paymentMethod)"><i class="fas fa-times-circle nex-color-error"></i> Delete</div>
                    </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>

    <app-payment-method-modal (addedPaymentMethod)="onAddPaymentMethodClick()"></app-payment-method-modal>
    <app-delete-payment-method-modal (deletedPaymentMethod)="onDeletePaymentMethodClick()"></app-delete-payment-method-modal>
  </div>
</section>
