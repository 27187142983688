import { DatePipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, Input, OnInit, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { ApiResponse } from '../../../../interfaces/api/apiResponse';
import { MortgageeDetails } from '../../../../interfaces/loss-payee-express/mortgagee-details';
import { GetMortgageesResponse } from '../../../../interfaces/loss-payee/get-mortgagees-response';
import { MortgageeViewModel } from '../../../../interfaces/loss-payee/mortgagee-view-model';
import { CreateMortgageeRequest } from '../../../../interfaces/mortgagee/create-mortgagee-request';
import { State } from '../../../../interfaces/search/state';
import { LossPayeeService } from '../../../../services/loss-payee/loss-payee.service';
import { StatesService } from '../../../../services/states/states.service';

const MINIMUM_CHARACTERS_FOR_SEARCH = 2;

@Component({
  selector: 'app-mortgagee-details',
  templateUrl: './mortgagee-details.component.html',
  styleUrls: ['./mortgagee-details.component.scss']
})
export class MortgageeDetailsComponent implements OnInit, AfterViewInit {
  @Input() mortgageeDetails: MortgageeDetails;
  @Input() newMortgagee: CreateMortgageeRequest;
  @Input() addingNewMortgagee: boolean;
  @Input() reviewing: boolean;

  @Output() newMortgageeAdded = new EventEmitter<boolean>()

  private _pipe = new DatePipe('en-US');
  private _mortgageeId = '';
  private _httpClient: HttpClient;

  mortgageeForm: FormGroup;
  states: Array<State>;
  searchValue = '';
  mortgagees: ApiResponse<GetMortgageesResponse>;
  noMortgageesFound = true;
  creatingNewMortgagee = false;
  isReviewing = false;

  constructor(
    private _formBuilder: FormBuilder,
    private _statesService: StatesService,
    private _lossPayeeService: LossPayeeService,
    httpClient: HttpClient) { this._httpClient = httpClient; }

  ngOnInit(): void {
    this.mortgageeForm = this.buildForm();
    this.states = this._statesService.getStates();
  }

  //  we use ngAfterViewInit because the user can navigate back here. So ngOnInit has already fired and won't fire again.
  ngAfterViewInit(): void {

    this.creatingNewMortgagee = this.addingNewMortgagee;
    this.isReviewing = this.reviewing;
    this.setMinCalendarDate();

    let md = this._lossPayeeService.mortgageeDetails;

    if (this.creatingNewMortgagee === true) {
      this.mortgageeForm = this.buildNewForm();
    }

    if (md === this.mortgageeDetails) {
      this.mapModelToForm(md);
    }

    if (this.isReviewing === true) {
      this.mortgageeForm = this.noValidationForm();
      this.mapModelToForm(md);
    }
  }

  mapFormToModel(): void {
    let mortgagee = {} as MortgageeDetails;

    mortgagee.mortgageeName = this.mortgageeForm.get('mortgagee').value;
    mortgagee.position = this.mortgageeForm.get('position').value;
    mortgagee.action = this.mortgageeForm.get('action').value;
    mortgagee.loanNumber = this.mortgageeForm.get('loanNumber').value;
    mortgagee.loanAmount = this.mortgageeForm.get('loanAmount').value;
    mortgagee.effectiveDate = this.mortgageeForm.get('effectiveDate').value;
    mortgagee.isEscrowed = this.mortgageeForm.get('escrow').value;
    mortgagee.mortgageeId = this._mortgageeId;

    if (this.creatingNewMortgagee === true) {
      let form = this.mortgageeForm;
      let addedMortgagee = {} as CreateMortgageeRequest;
      addedMortgagee.name = form.get('addMortgagee').value;
      addedMortgagee.address1 = form.get('address1').value;
      addedMortgagee.address2 = form.get('address2').value;
      addedMortgagee.city = form.get('city').value;
      addedMortgagee.state = form.get('state').value;
      addedMortgagee.zip = form.get('zip').value;
      addedMortgagee.action = 'Add';  //  can only be add when creating a new mortgagee
      this.createNewMortgagee(addedMortgagee);
      this._lossPayeeService.newMortgagee = addedMortgagee;
    }

    this._lossPayeeService.mortgageeDetails = mortgagee;
  }

  mapModelToForm(m: MortgageeDetails): void {
    this.mortgageeForm.get('mortgagee').setValue(m.mortgageeName);
    this.mortgageeForm.get('position').setValue(m.position);
    this.mortgageeForm.get('action').setValue(m.action);
    this.mortgageeForm.get('loanNumber').setValue(m.loanNumber);
    this.mortgageeForm.get('loanAmount').setValue(m.loanAmount);
    this.mortgageeForm.get('effectiveDate').setValue(m.effectiveDate);
    this.mortgageeForm.get('escrow').setValue(m.isEscrowed);
    this._mortgageeId = m.mortgageeId;

    if (this.creatingNewMortgagee === true) {
      let newMortgagee = this._lossPayeeService.newMortgagee;
      this.mortgageeForm.get('addMortgagee').setValue(newMortgagee.name);
      this.mortgageeForm.get('address1').setValue(newMortgagee.address1);
      this.mortgageeForm.get('address2').setValue(newMortgagee.address2);
      this.mortgageeForm.get('city').setValue(newMortgagee.city);
      this.mortgageeForm.get('state').setValue(newMortgagee.state);
      this.mortgageeForm.get('zip').setValue(newMortgagee.zip);
      this._mortgageeId = this._lossPayeeService.newMortgageeId;

      if(this.isReviewing){
        this.mortgageeForm.get('mortgagee').setValue(this.mapNewMortgageeToString(newMortgagee));
      }
    }
  }

  search(): void {
    this.searchValue = this.mortgageeForm.get('mortgagee').value;

    let options = {
      params: new HttpParams()
        .set('page', '1')
        .set('pageSize', '10')
        .set('name', this.searchValue)
    }

    if (this.searchValue.length > MINIMUM_CHARACTERS_FOR_SEARCH) {
      this._httpClient.get<ApiResponse<GetMortgageesResponse>>(`${environment.portalApiUrl}v1/mortgagees`, options).pipe(
        distinctUntilChanged(),
      ).subscribe(result => {
        this.mortgagees = result;
        let addNewMortgagee = {} as MortgageeViewModel;
        addNewMortgagee.name = 'Add New Mortgagee';
        addNewMortgagee.address1 = '';
        addNewMortgagee.address2 = '';
        addNewMortgagee.city = '';
        addNewMortgagee.state = '';
        addNewMortgagee.zip = null;
        this.mortgagees.data.mortgagees.unshift(addNewMortgagee);
        this.noMortgageesFound = false;
      })
    } else {
      this.noMortgageesFound = true;
    }
  }

  selectItem(i: number) {
    let s = this.mapMortgageeResponseToString(this.mortgagees.data.mortgagees[i]);
    if (this.creatingNewMortgagee === true) {
      this.mortgageeForm.get('mortgagee').setValue('');
    } else {
      this.mortgageeForm.get('mortgagee').setValue(s);
    }

    //  there won't be a mortgageeId if they select 'Add New Mortgagee'
    if (this.mortgagees.data.mortgagees[i].name === 'Add New Mortgagee') {
      this.creatingNewMortgagee = true;
      this.newMortgageeAdded.emit(true);
      this.mortgageeForm = this.removeOutterMortgageeNameValidation(this.mortgageeForm);
    } else {
      this._mortgageeId = this.mortgagees.data.mortgagees[i].mortgageeId.toString();
    }

    this.noMortgageesFound = true;
    this.mortgagees.data.mortgagees = null;
  }

  createNewMortgagee(addedMortgagee: CreateMortgageeRequest) {
    //  we are not adding the new mortgagee to the DB at this time. All DB work is done during the submit phase
    this._lossPayeeService.addingNewMortgagee = true;
  }

  resetForm() {
    this.mortgageeForm.reset();
    this._lossPayeeService.mortgageeDetails = null;
    this.mortgageeForm = this.buildForm();
    this.mortgageeForm.updateValueAndValidity();
    this.creatingNewMortgagee = false;
    this.addingNewMortgagee = false;
    this._lossPayeeService.addingNewMortgagee = false;
  }

  clearInternalForm(){
    if (this.mortgagees !== undefined) {
      this.mortgagees.data.mortgagees = null;
    }
    this.creatingNewMortgagee = false;
    this._lossPayeeService.addingNewMortgagee = false;
  }

  mapMortgageeResponseToString(response: MortgageeViewModel): string {
    let streetAddress = response.address2 !== null ? `${response.address1} ${response.address2}` : `${response.address1}`;

    if (response.name === 'Add New Mortgagee') {
      return response.name;
    }
    return response.name + ' ' + streetAddress + ' ' + response.city + ', ' + response.state + ' ' + response.zip;
  }

  mapNewMortgageeToString(response: CreateMortgageeRequest): string {
    let streetAddress = response.address2 !== null ? `${response.address1} ${response.address2}` : `${response.address1}`;

    return response.name + ' ' + streetAddress + ' ' + response.city + ', ' + response.state + ' ' + response.zip;
  }

  setMortgageeId(id : string){
    this._mortgageeId = id;
  }

  private setMinCalendarDate(): void {
    let today = Date.now();
    let minDate = this._pipe.transform(today, 'yyyy-MM-dd');
    let datePicker = document.getElementById('effectiveDate');
    datePicker.setAttribute('min', minDate);
  }

  private buildForm(): FormGroup {
    return this._formBuilder.group({
      mortgagee: ['', [Validators.required]],
      position: ['Select Position', [Validators.required, this.defaultPosition]],
      action: ['Select Action', [Validators.required, this.defaultAction]],
      loanNumber: ['', [Validators.required]],
      effectiveDate: ['', [Validators.required, this.dateValidator]],
      loanAmount: ['$', [Validators.required, this.defaultLoanAmount]],
      escrow: ['', [Validators.required]],
      addMortgagee: ['', [Validators.required]],
      address1: ['', [Validators.required]],
      address2: [''],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      zip: ['', [Validators.required]]
    },
    {
      validators: [this.replaceAllPositionValidator, this.escrowPositionValidator]
    })
  }

  private noValidationForm(): FormGroup {
    return this._formBuilder.group({
      mortgagee: [''],
      position: [''],
      action: [''],
      loanNumber: [''],
      effectiveDate: [''],
      loanAmount: ['$'],
      escrow: [''],
      addMortgagee: [''],
      address1: [''],
      address2: [''],
      city: [''],
      state: [''],
      zip: ['']
    })
  }

  private defaultPosition(fc: FormControl): { defaultValue: 'Cannot use defualt value' } {
    const isInvalid = 'Select Position' === fc.value;
    return isInvalid ? { defaultValue: 'Cannot use defualt value' } : null;
  }

  private defaultAction(fc: FormControl) {
    let isInvalid = 'Select Action' === fc.value;
    return isInvalid ? { defaultValue: 'Cannot use defualt value' } : null;
  }

  private defaultLoanAmount(fc: FormControl) {
    let isInvalid = '$' === fc.value;
    return isInvalid ? { defaultValue: 'Cannot use defualt value' } : null;
  }

  private dateValidator(control: FormControl): { [s: string]: boolean } | null {
    let today = new Date().toLocaleDateString();
    let datePipe = new DatePipe('en-US');
    let minDate = datePipe.transform(today, 'yyyy-MM-dd');
    if (control.value < minDate) {
      return { 'badDate': true };
    }
  }

  private replaceAllPositionValidator(control: FormGroup): ValidationErrors | null {
    const action = control.get('action');
    const position = control.get('position');

    if (action.value === 'replaceAll' && position.value === '2') {
      return { InvalidActionPositionCombination: true }
    }

    return null;
  }

  private escrowPositionValidator(control: FormGroup): ValidationErrors | null {
    const escrow = control.get('escrow');
    const position = control.get('position');

    if (escrow.value === 'true' && position.value === '2') {
      return { InvalidEscrowPositionCombination: true }
    }

    return null;
  }

  private removeOutterMortgageeNameValidation(form: FormGroup): FormGroup {

    //  hate doing it this way but 3 different ways to remove a validator from a form control failed
    //  will check gitHub to see if others have reported a bug in Angular 13
    let newForm = this.buildNewForm();
    newForm.updateValueAndValidity();
    return newForm;

  }

  private buildNewForm(): FormGroup {
    return this._formBuilder.group({
      mortgagee: [''],
      position: ['Select Position', [Validators.required, this.defaultPosition]],
      action: ['Select Action', [Validators.required, this.defaultAction]],
      loanNumber: ['', [Validators.required]],
      effectiveDate: ['', [Validators.required, this.dateValidator]],
      loanAmount: ['$', [Validators.required, this.defaultLoanAmount]],
      escrow: ['', [Validators.required]],
      addMortgagee: ['', [Validators.required]],
      address1: ['', [Validators.required]],
      address2: [''],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      zip: ['', [Validators.required]]
    },
    {
      validators: [this.replaceAllPositionValidator, this.escrowPositionValidator]
    })
  }
}
