import { Component } from '@angular/core';
import { MortgageeViewModel } from 'src/app/interfaces/loss-payee/mortgagee-view-model';
import { ModalService } from 'src/app/services/modals/modals.service';

@Component({
  selector: 'app-mortgagee-exists-modal',
  templateUrl: './mortgagee-exists-modal.component.html',
  styleUrls: ['./mortgagee-exists-modal.component.css']
})

export class MortgageeExistsModalComponent {
  public existingMortgagee = {} as MortgageeViewModel;
  
  constructor(
    private _modalService: ModalService) { }
    

  transformAddress(address1: string, address2: string): string {
    address1 = address1?.replace('P.o', 'P.O');
    return address2 ? `${address1} ${address2}, ` : `${address1}, `;
  }

  closeModal(): void {
    this._modalService.close('mortgagee-exists');
  }
}
