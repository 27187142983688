import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable, take } from 'rxjs';
import { environment } from '../../environments/environment';
import { ApiResponse } from '../interfaces/api/apiResponse';
import { FeatureFlag } from '../interfaces/feature-flags/feature-flag';
import { FeatureFlags } from '../interfaces/feature-flags/feature-flags';

@Injectable({
  providedIn: 'root'
})

export class FeatureFlagService {

  private _httpClient: HttpClient;
  private _featureFlags: FeatureFlag[];

  public featureEnabled = false;

  constructor(httpClient: HttpClient) { this._httpClient = httpClient; }

  async isFeatureEnabled(featureName: string): Promise<boolean> {

    if (this._featureFlags !== undefined) {
      return this.findTheFlag(this._featureFlags, featureName);
    }

    await this.getFlagsAsync()
      .then(f => {
        this._featureFlags = f;
        this.featureEnabled = this.findTheFlag(f, featureName);
      });

    return this.featureEnabled;
  }

  getAllFlags(): Observable<ApiResponse<FeatureFlags>> {

    return this._httpClient.get<ApiResponse<FeatureFlags>>
      (`${environment.portalApiUrl}v1/features`).pipe(take(1));

  }

  private async getFlagsAsync(): Promise<Array<FeatureFlag>> {

    let response = await lastValueFrom(this.getAllFlags());
    return response.data.featureFlags;
  }

  private findTheFlag(flag: Array<FeatureFlag>, flagName: string): boolean {
    let localDate = new Date().toLocaleDateString();
    let datePipe = new DatePipe('en-US');
    let today = datePipe.transform(localDate, 'yyyy-MM-dd');
    let enabled = false;

    let feature = flag.find(x => x.featureName.toLowerCase() === flagName);

    if (feature !== undefined) {
      if (feature.enabledAt !== null) {
        let formattedDate = datePipe.transform(new Date(feature.enabledAt), 'yyyy-MM-dd');
        enabled = Date.parse(formattedDate) < Date.parse(today);
      }
    }

    return enabled;
  }



}


