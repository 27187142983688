import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateText'
})
export class TruncateTextPipe implements PipeTransform {

  transform(value: string, limit: number = 50, trail: string = '…'): string {
    let result = value || '';

    if (value) {
      const characters = value.split(/\s+/)[0];
      if (characters.length > Math.abs(limit)) {
        if (limit < 0) {
          limit *= -1;
          result =
            trail + characters.slice(characters.length - limit, characters.length);
        } else {
          result = characters.slice(0, limit) + trail;
        }
      }
    }

    return result;
  }
}
