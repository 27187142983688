import { Component } from '@angular/core';

@Component({
  selector: 'app-error-500',
  templateUrl: './error500.component.html',
  styleUrls: ['./error500.component.css']
})

export class Error500Component {


}
