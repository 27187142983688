import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';

import { BriefSearchRequest } from '../../interfaces/search/brief-search-request';
import { BriefSearchResponse } from '../../interfaces/search/brief-search-response';
import { ApiResponse } from '../../interfaces/api/apiResponse';
import { environment } from '../../../environments/environment';
import { BriefPolicySearchDataEvents } from '../../modules/search/policy-search-data.events/policy-search-data.events';
import { BriefDetailsRequest } from '../../interfaces/search/brief-search-details-request';
import { BriefDetailsResponse } from '../../interfaces/search/brief-search-details-response';
import { PurchaseEOIRequest } from '../../interfaces/search/brief-purchase-request';

@Injectable({
  providedIn: 'root'
})

export class SearchService {
  private _httpClient: HttpClient;
  private _propertySearched:  BriefSearchRequest;
  private _searchResults = new BehaviorSubject<BriefSearchResponse>(null);

  public observableSearch = this._searchResults.asObservable();

  constructor(httpClient: HttpClient, dataService: BriefPolicySearchDataEvents) {
    this._httpClient = httpClient;
    this._propertySearched = {} as BriefSearchRequest;
  }

  public searchRespone(searchResponse: BriefSearchResponse): void {
    this._searchResults.next(searchResponse);
  }

  public postBriefSearch(request: BriefSearchRequest): Observable<ApiResponse<BriefSearchResponse>> {
    this._propertySearched = request;
    return this._httpClient.post<ApiResponse<BriefSearchResponse>>(`${environment.portalApiUrl}v1/policies/briefs/`, request)
    .pipe(timeout(30_000));
  }

  public getDetailsBriefs(request: BriefDetailsRequest): Observable<ApiResponse<BriefDetailsResponse>> {
    return this._httpClient.post<ApiResponse<BriefDetailsResponse>>
      (`${environment.portalApiUrl}v1/policies/briefs/details`, request);
  }

  public postPurchaseEOI(briefSearchId: string, request: PurchaseEOIRequest): Observable<HttpResponse<ArrayBuffer>> {
    return this._httpClient.post(`${environment.portalApiUrl}v1/policies/briefResults/${briefSearchId}/evidence`, request,
      {
        observe: 'response',
        responseType: 'arraybuffer'
      });
  }

  public getSavedSearch(): BriefSearchRequest {
    return this._propertySearched;
  }

  public savePropertySearched(savedSearch: BriefSearchRequest): void {
    this._propertySearched = savedSearch;
  }

  public resetPropertSearch(): void {
    this._propertySearched = {} as BriefSearchRequest;
  }
 
}
