<app-custom-modal id="newMortgageeRequest">

  <div [ngClass]="'container'">
    <div [ngClass]="'header'">Request New Mortgagee</div>
    <form novalidate
          [formGroup]="modalForm"
          class="form-group "
          (ngSubmit)="submit">
      <div class="form-group">

        <div [ngClass]="'mortgagee-name'">
          <label for="mortgageeName" id="mortgageeName">Mortgagee Name:</label> <br />
          <input type="text" [ngClass]="'form-control'" formControlName="mortgageeName" required /> <br />
          <span class="help-block"
                *ngIf="(modalForm.get('mortgageeName').touched || modalForm.get('mortgageeName').dirty) && modalForm.get('mortgageeName').errors?.required ">Mortgagee Name is required</span>
        </div>

        <div [ngClass]="'street-address'">
          <div [ngClass]="' address address1'">
            <label for="address1" id="address1">Address Line 1:</label> <br />
            <input type="text" [ngClass]="'form-control'" formControlName="address1" required /> <br />
            <span class="help-block"
                  *ngIf="(modalForm.get('address1').touched || modalForm.get('address1').dirty) && modalForm.get('address1').errors?.required ">Address Line 1 is required</span>
          </div>
          <div [ngClass]="'address2'">
            <label for="address2" id="address2">Address Line 2:</label> <br />
            <input type="text" [ngClass]="'form-control'" formControlName="address2" /> <br />
          </div>
        </div>


        <div [ngClass]="'city-state-zip'">
          <div [ngClass]="'city'">
            <label for="city" id="city">City:</label> <br />
            <input type="text" [ngClass]="'form-control'" formControlName="city" required /> <br />
            <span class="help-block"
                  *ngIf="(modalForm.get('city').touched || modalForm.get('city').dirty) && modalForm.get('city').errors?.required ">City is required</span>
          </div>
          <div [ngClass]="'state'">
            <label for="state">State:</label> <br />
            <select [ngClass]="'form-control local'" formControlName="state">
              <option value="" hidden>
                Select State
              </option>
              <option *ngFor="let state of states" [ngValue]="state.abbreviation">
                {{state.name}}
              </option>
            </select> <br />
            <span [ngClass]="'help-block'"
                  *ngIf="modalForm.get('state').touched && !!modalForm.get('state').errors?.required">
              Select a state
            </span>
          </div>
          <div [ngClass]="'zip'">
            <label for="zip" id="zip">Zip:</label> <br />
            <input type="text" [ngClass]="'form-control'" formControlName="zip" required /> <br />
            <span class="help-block"
                  *ngIf="(modalForm.get('zip').touched || modalForm.get('zip').dirty) && modalForm.get('zip').errors?.required ">Zip is required</span>
          </div>
        </div>

        <div [ngClass]="'button-holders'">
          <div>
            <button id="cancel" type="button" (click)="resetForm(); this.closeModal('newMortgageeRequest');" [ngClass]="'cancel-button'">Cancel</button>
          </div>
          <div>
            <button id="submit" type="submit" (click)="submit()" [ngClass]="'submit-button'">Submit Request</button>
          </div>
        </div>

      </div>
    </form>
  </div>

</app-custom-modal>
<app-mortgagee-exists-modal></app-mortgagee-exists-modal>
<app-add-mortgagee-suggestions-modal></app-add-mortgagee-suggestions-modal>
