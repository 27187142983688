import {Component, Injector} from '@angular/core';
import {ModalService} from '../../../../services/modals/modals.service';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import {Keepalive} from '@ng-idle/keepalive';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-session-timeout',
  templateUrl: './session-timeout.component.html',
  styleUrls: ['./session-timeout.component.scss']
})

export class SessionTimeoutComponent {
  public message: string;
  idleState = 'Not Started';
  timedOut = false;
  lastPing?: Date = undefined;
  private sessionEndCallback: any;
  private _modalService: ModalService;

  constructor(
    private idle: Idle,
    private keepalive: Keepalive,
    private _injector: Injector
  ) {
    const _time_logout = 15;    // seconds before logout
    const _time_idle = parseInt(environment.sessionTimeoutTime) - _time_logout; // seconds before _time_logout timer starts
    const _time_interval = 15;  // seconds between interval
    this._modalService = _injector.get(ModalService);

    idle.setIdle(_time_idle);
    idle.setTimeout(_time_logout);
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'status: not idle.';
      this.reset();
      this._modalService.close('session-timeout');
      // console.log(this.idleState);
    });

    idle.onTimeout.subscribe(() => {
      this.idleState = 'status: timeout';
      this.timedOut = true;
      this.logout();
      this._modalService.close('session-timeout');
      // console.log(this.idleState);
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = `status: logging out in ${_time_logout} seconds`;
      idle.clearInterrupts();
      this._modalService.open('session-timeout');
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = `status: time out in ${countdown} seconds!`;
      this.setCountdown(countdown);
      // console.log(this.idleState);
    });

    // sets the ping interval in seconds
    this.keepalive.interval(_time_interval);

    this.keepalive.onPing.subscribe(() => {
      this.lastPing = new Date();
      // console.log(`last ping: ${this.lastPing}`);
    });
  }

  sessionWatch() {
    this.idle.watch();
  }

  sessionStop() {
    this.idle.stop();
  }

  setCountdown(countdown: number) {
    this.message = `Due to inactivity, your session will expire in ${countdown} second${countdown>1?'s':''}.  Click OK to continue.`;
  }

  showMessage(message: string) {
    this.message = message;
  }

  setSessionEndCallback(callback: any) {
    if (typeof callback === 'function') {
      this.sessionEndCallback = callback;
    }
  }

  logout() {
    if (typeof this.sessionEndCallback === 'function') {
      this.sessionEndCallback();
    } else {
      console.log('logout api unavailable.')
    }
  }

  closeModal() {
    this._modalService.close('session-timeout');
    this.sessionWatch();
  }

  reset() {
    this.idle.watch();
    this.timedOut = false;
  }

  stay() {
    this.reset();
  }
}
