<header [ngClass]="'container'">
  <div class="container-logo">
    <a href="/"><img alt="MERS-HOI-Logo" src="./assets/images/MERS_HOI_Hub-color.svg" /></a>
  </div>
  <div class="container-menu-top">
    <div [ngClass]="{'help-show': this.userIsLoggedIn === true, 'help-hide': this.userIsLoggedIn !== true }">
      <a [routerLink]="['help']" (click)="navigateToHelp()">
        Need Help?
        <img alt="Need Help?" src="./assets/images/ico-question-sm.png" />
      </a>
    </div>
    <div *ngIf="this.userIsLoggedIn === true" [ngClass]="'login'">
      <app-login></app-login>
    </div>
  </div>
</header>
