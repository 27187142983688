<span class="banner-main" *ngIf="bannerVisible">
  <span class="banner-icon-container">
    <i class="fa fa-exclamation-circle fa-3x" aria-hidden="true"></i>
  </span>
  <!--Keeping this in for when we want to enable close functionality
  <span class="banner-icon-close">
    <i class="fa fa-times fa-3x" aria-label="close" (click)="closeBanner()"></i>
  </span>-->
  <div class="banner-main-text">
    This device is not supported!
    <br />
    We have identified this device as a mobile phone or tablet, which is not currently supported.
    <br />
    Please ensure you are using a desktop or laptop device.
  </div>
</span>
