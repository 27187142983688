<app-custom-modal id="searching">
  <div class="modal-dialog center">
    <div class="custom-modal-body">
      <p>Searching for insurance records...</p>
      <div id="progressContainer" #progressContainer [hidden]="isSearching === false">
        <div class="candy" id="progressBar" #progressBar>
        </div>
      </div>
    </div>
  </div>
</app-custom-modal>
