import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Subscription } from 'rxjs';
import { statusType } from '../../../interfaces/users/statusType';

@Component({
  selector: 'app-account-status',
  templateUrl: './account-status.component.html',
  styleUrls: ['./account-status.component.scss']
})
export class AccountStatusComponent implements OnDestroy  {
    public subs: Subscription[] = [];


  //  publically exposed to be used when setting the internal value of status (Pending, Locked, Rejected)
  statusType = statusType;
  private _status: string;

  constructor(
    private route: ActivatedRoute,
    private authService: MsalService) {

    this.subs.push(this.route
      .queryParams
      .subscribe(params => {
        this._status = params['accountStatus'];
      })
    );

    if (this._status === '')
      this._status = statusType.NoStatus;
  }

  ngOnDestroy() {
    if (this.subs && this.subs.length > 0) {

      while (this.subs.length > 0) {
        let sub = this.subs.pop();
        sub.unsubscribe();
        sub = null;
      }
    }
  }

  returnToHome() {
    this.authService.logout();
  }

  get status(): string {
    return this._status;
  }

}
