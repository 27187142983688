import { IEnvironment } from './models/IEnvironment';

export const environment: IEnvironment = {
  isLocal: false,
  production: '#{isProduction}#',
  payment: {
    apiKey: '#{PaymentApiKey}#'
  },
  subscriptionKey: '#{SubscriptionKey}#',
  portalApiUrl:  '#{portalApiUrl}#',
  applicationId: '#{applicationId}#',
  clearHoiUrl: '#{clearHoiUrl}#',
  hideLossPayee: '#{hideLossPayee}#',
  azureB2C: {
    clientID: '#{clientID}#', // required to initialize MSAL module
    authority: '#{authority}#', // In Azure B2C, it is of the form https://<instance>/tfp/<tenantId>/<policyName>/
    passwordResetPolicy: '#{passwordResetPolicy}#',
    redirectUri: '#{redirectUri}#',
    postLogoutRedirectUri: '#{postLogoutRedirectUri}#', // default is redirect uri
    // eslint-disable-next-line max-len
    navigateToLoginRequestUrl: '#{navigateToLoginRequestUrl}#' // Ability to turn off default navigation to start page after login. Default is true. This is used only for redirect flows.
  },
  apiScope: '#{apiScope}#',
  appInsights: {
    instrumentationKey: '#{instrumentationKey}#'
  },
  sessionTimeoutTime: '#{sessionTimeout}#'
};
