
<div *ngIf="briefSearchDetailsLength === 0">
  <div class="section">
    <p>No results found matching your search</p>
    <h3>Search Tips</h3>
    <ul>
      <li>Double check the name and address</li>
      <li>Try a co-borrower on the loan</li>
      <li>Exclude middle initial and suffix</li>
    </ul>
  </div>
</div>


<div *ngIf="canShowSearchResults">

  <h2 class="nex-typography-sub-header nex-color-grey-nex">
    <strong *ngIf="address">
      <span class="nex-typography-quotes text-muted">
        <strong>{{address | address}}</strong>
      </span>
    </strong>
  </h2>

  <form *ngIf="briefSearchDetailsLength > 0 && !fileUploadCompleted">
    <div class="input-wrapper">
      <p><strong>{{ briefSearchDetailsLength }}</strong> record(s) found.  Please upload your borrower's signed consent.</p>
    </div>
    <div class="input-btn border">
      <app-client-consent></app-client-consent>
    </div>
  </form>

  <div *ngFor="let result of briefSearchDetailsResult">
    <form>
      <div [ngClass]="hasPurchasedEvidence ? 'input-wrapper confirm' : 'input-wrapper'">
        <div *ngFor="let details of result.briefDetails; let i = index">
          <div [ngClass]="hasMultiplePolicies ? 'policy-info select' : 'policy-info selected'" (click)="onPolicySelect($event, i)">
            <div class="policy-select">
              <input type="radio" id="{{selectOptionName+i}}" name="policy-type" value="{{i}}"/>
            </div>
            <div class="policy-details">
              <label class="policy-header" for="policy-{{i}}">{{details.firstName}} {{details.lastName}}</label>
              <span class="policy-address">
                {{details.propertyAddress.address1}}, {{details.propertyAddress.city}} {{details.propertyAddress.state}}
              {{details.propertyAddress.zip}}
              </span>
              <div class="input-col-4">
                <div class="input">
                  <label>Policy Type</label>
                  <span class="value">
                    {{ getPolicyType(details.policyTypeCode) | titlecase }}
                  </span>
                </div>
                <div class="input">
                  <label>Effective Date</label>
                  <span class="value">
                    {{details.effectiveDate.replace("T00:00:00","") | date:"shortDate"}}
                  </span>
                </div>
                <div class="input">
                  <label>Premium Amount</label>
                  <span class="value">
                    {{details.premiumAmount | currency:"USD" }}
                  </span>
                </div>
                <div class="input">
                  <label>Expiration Date</label>
                  <span class="value">
                    {{details.expirationDate.replace("T00:00:00","") | date:"shortDate"}}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="input-btn border">
        <p [ngClass]="hasPurchasedEvidence ? 'hide' : 'buy-info'"><span class="lbl">Total Price</span><span class="fee">{{totalFee | currency:"USD"}}</span></p>
        <p [ngClass]="hasPurchasedEvidence ? 'download-info' : 'hide'">Download evidence of insurance <i class="file-pdf"></i></p>
        <button class="primary" [disabled]="buyDisabled" (click)="handleBuy()">
          {{isPurchasingEvidence ? "" : hasPurchasedEvidence ? "DOWNLOAD" : "BUY"}}<span [ngClass]="{'fas fa-spinner fa-spin': isPurchasingEvidence}"></span>
        </button>
      </div>
    </form>
  </div>
</div>

<div *ngIf="briefSearchDetailsLength !==0 && !hasPaymentMethod && !canShowSearchResults">
  <app-no-payment-method></app-no-payment-method>
</div>

