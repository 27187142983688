<app-custom-modal id="clientConsent">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2>Upload Borrower Consent</h2>
      </div>
      <div class="modal-body">
        <div class="client-consent-text">
          <p>Please upload your borrower's signed consent</p>
          <div class="client-consent-modal-upload-button-container">
            <app-uploader [acceptedFileTypes]="'.pdf'" (fileAdded)="processFiles($event)"></app-uploader>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-action">
      <div class="input-btn">
        <button routerLinkActive="router-link-active"
                type="reset"
                class="client-consent-cancel-button"
                role="button"
                aria-label="Close"
                (click)="onCancelClick()"
                data-dismiss="modal">
          Cancel
        </button>

        <button routerLinkActive="router-link-active"
                type="submit"
                class="client-consent-submit-button"
                role="button"
                (click)="onSubmitClick()"
                aria-label="Submit"
                [class.active]="isLoading"
                [disabled]="isLoading">
          <i class="float-right fas fa-spinner fa-spin" *ngIf="isLoading"></i>
          {{!isLoading ? "Submit" : "Submitting" }}
        </button>
      </div>
    </div>
  </div>
</app-custom-modal>
