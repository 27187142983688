import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AddressViewModel } from '../../interfaces/address/addressViewModel';
import { ApiResponse } from '../../interfaces/api/apiResponse';
import { ContactAndConsent } from '../../interfaces/loss-payee-express/contact-and-consent';
import { InsuredDetails } from '../../interfaces/loss-payee-express/insured-details';
import { LossPayeeExpressRequest } from '../../interfaces/loss-payee-express/loss-payee-express-request';
import { LossPayeeExpressResponse } from '../../interfaces/loss-payee-express/loss-payee-express-response';
import { MortgageeDetails } from '../../interfaces/loss-payee-express/mortgagee-details';
import { PolicyInformation } from '../../interfaces/loss-payee-express/policy-information';
import { CreateMortgageeRequest } from '../../interfaces/mortgagee/create-mortgagee-request';


@Injectable({
  providedIn: 'root'
})
export class LossPayeeService {

  protected policy = {} as PolicyInformation;
  protected address = {} as AddressViewModel;
  protected insured = {} as InsuredDetails;
  protected mortgagee = {} as MortgageeDetails;
  protected contact = {} as ContactAndConsent;
  protected addedMortgagee = {} as CreateMortgageeRequest;
  protected mortgageeId = '';
  protected addingMortgagee = false;
  protected fileUpload: File;
  protected uploadedFiles: Set<File>;

  constructor(private _httpClient: HttpClient) { }

  validateForm(form: FormGroup): boolean {
    return form.valid;
  }

  removeUnusedFormFields(form: FormGroup): FormGroup {
    form.removeControl('addMortgagee');
    form.removeControl('address1');
    form.removeControl('address2');
    form.removeControl('city');
    form.removeControl('state');
    form.removeControl('zip');

    return form;
  }

  markFormTouched(formGroup: FormGroup): FormGroup {
    if (!!formGroup) {
      Object.keys(formGroup.controls).forEach(formControlKey => {
        formGroup.controls[formControlKey].markAsTouched();
      });

      return formGroup;
    }
  }

  createLossPayeeRequest(lossPayeeRequest: LossPayeeExpressRequest, blob: File): Observable<ApiResponse<LossPayeeExpressResponse>> {
    const formData: FormData = new FormData();
    
    let request = JSON.stringify(lossPayeeRequest);
    formData.append('request', request);
    formData.append('certificateFile', blob, blob.name);

    const postData = { request: lossPayeeRequest, file: formData };
    
    return this._httpClient.post<ApiResponse<LossPayeeExpressResponse>>(`${environment.portalApiUrl}v1/policies/losspayee`, formData);
  }

  clearLossPayeeRequest() {
    this.addingNewMortgagee = false;

    this.addedMortgagee = null;
    this.policy = null;
    this.address = null;
    this.insured = null;
    this.mortgagee = null;
    this.fileUpload = null;
    this.filesToUpload = null;
  }

  public get policyInformation(): PolicyInformation {
    return this.policy;
  }

  public set policyInformation(policy: PolicyInformation) {
    this.policy = policy;
  }

  public get insuredDetails(): InsuredDetails {
    return this.insured;
  }

  public set insuredDetails(insured: InsuredDetails) {
    this.insured = insured;
  }

  public set mortgageeDetails(mortgagee: MortgageeDetails) {
    this.mortgagee = mortgagee;
  }

  public get mortgageeDetails(): MortgageeDetails {
    return this.mortgagee;
  }

  public set contactAndConsent(contact: ContactAndConsent) {
    this.contact = contact
  }

  public get contactAndConsent(): ContactAndConsent {
    return this.contact;
  }

  public set newMortgagee(newMortgagee: CreateMortgageeRequest) {
    this.addedMortgagee = newMortgagee;
  }

  public get newMortgagee(): CreateMortgageeRequest {
    return this.addedMortgagee;
  }

  public set newMortgageeId(id: string) {
    this.mortgageeId = id;
  }

  public get newMortgageeId(): string {
    return this.mortgageeId;
  }

  public set addingNewMortgagee(value: boolean) {
    this.addingMortgagee = value;
  }

  public get addingNewMortgagee(): boolean {
    return this.addingMortgagee;
  }

  public set singleFileUpload(value: File) {
    this.fileUpload = value;
  }

  public get singleFileUpload(): File {
    return this.fileUpload;
  }

  public set filesToUpload(value: Set<File>) {
    this.uploadedFiles = value;
  }

  public get filesToUpload(): Set<File> {
    return this.uploadedFiles;
  }

}
