<div [ngClass]="'container'" [formGroup]="policyForm">

  <h2 [ngClass]="'title'">Policy Information</h2>
  <label for="propertyAddress" [ngClass]="'property1-label'">Property Address 1</label>
  <input *ngIf="this.isReviewing === false" type="text" id="propertyAddress1" formControlName="address1" required
         [ngClass]="'property1-input'" />
  <input *ngIf="this.isReviewing === true" type="text" id="propertyAddress1" formControlName="address1" autocomplete="no"
         readonly [ngClass]="'property1-input local'" />
  <span class="help-block address1-error"
        *ngIf="(policyForm.get('address1').touched || policyForm.get('address1').dirty)
                    && policyForm.get('address1').errors?.required ">Address is required</span>

  <label for="propertyAddress" [ngClass]="'property2-label'">Property Address 2</label>
  <input *ngIf="this.isReviewing === false" type="text" id="propertyAddress2" [ngClass]="'property2-input'" formControlName="address2" />
  <input *ngIf="this.isReviewing === true" type="text" id="propertyAddress2" [ngClass]="'property2-inlut local'" formControlName="address2" readonly />



  <label for="city" [ngClass]="'city-label'">City</label>
  <input *ngIf="this.isReviewing === false" type="text" id="city" [ngClass]=" 'city-input'" formControlName="city" required />
  <input *ngIf="this.isReviewing === true" type="text" id="city" [ngClass]="'city-input local'" formControlName="city" autocomplete="no" readonly />
  <span class="help-block city-error"
        *ngIf="(policyForm.get('city').touched || policyForm.get('city').dirty)
                    && policyForm.get('city').errors?.required ">City is required</span>

  <label for="state" [ngClass]="'state-label'">State</label>
  <select *ngIf="this.isReviewing === false" [ngClass]="'state-input'" formControlName="state">
    <option value="null"
            hidden>
      Select State
    </option>
    <option *ngFor="let state of states"
            [ngValue]="state.abbreviation">
      {{state.name}}
    </option>
  </select>

  <select *ngIf="this.isReviewing === true" [ngClass]="'state-input local'" formControlName="state" readonly disabled>
    <option *ngFor="let state of states"
            [ngValue]="state.abbreviation">
      {{state.name}}
    </option>
  </select>

  <span class="help-block state-error"
        *ngIf="(policyForm.get('state').touched || policyForm.get('state').dirty)
                    && policyForm.get('state').errors?.required ">State is required</span>

  <label for="zip" [ngClass]="'zip-label'">Zip Code</label>
  <input *ngIf="this.isReviewing === false" type="text" id="zip" [ngClass]="'zip-input'" formControlName="zip" required
         maxlength="5" minlength="5" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" />
  <input *ngIf="this.isReviewing === true" type="text" id="zip" [ngClass]="'zip-input local'" formControlName="zip" autocomplete="no"
         maxlength="5" minlength="5" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" readonly />
  <span class="help-block zip-error"
        *ngIf="(policyForm.get('zip').touched || policyForm.get('zip').dirty)
                    && policyForm.get('zip').errors?.required ">Zip code is required</span>
  <span class="help-block zip-error"
        *ngIf="(policyForm.get('zip').touched || policyForm.get('zip').dirty)
                && policyForm.get('zip').errors?.minlength ">Zip must be 5 digits</span>

  <label for="policyNumber" [ngClass]="'policy-number-label'">Policy Number</label>
  <input *ngIf="this.isReviewing === false" type="text" id="policyNumber" [ngClass]="'policy-number-input'" formControlName="policyNumber" required />
  <input *ngIf="this.isReviewing === true" type="text" id="policyNumber" [ngClass]="'policy-number-input local'" formControlName="policyNumber"
         autocomplete="no" readonly />
  <span class="help-block policy-number-error"
        *ngIf="(policyForm.get('policyNumber').touched || policyForm.get('policyNumber').dirty)
                    && policyForm.get('policyNumber').errors?.required ">Policy number is required</span>

  <label for="policyType" [ngClass]="'policy-type-label'">Policy Type</label>
  <select *ngIf="this.isReviewing === false" id="policyType" [ngClass]="'policy-type-input'" formControlName="policyType" required>
    <option value="null"
            hidden>
      Select Policy Type
    </option>
    <option *ngFor="let policy of policyTypes"
            [ngValue]="policy.name">
      {{policy.name}}
    </option>
  </select>
  <select *ngIf="this.isReviewing === true" id="policyType" [ngClass]="'policy-type-input local'" formControlName="policyType" readonly disabled>
    <option *ngFor="let policy of policyTypes"
            [ngValue]="policy.name">
      {{policy.name}}
    </option>
  </select>
  <span class="help-block policy-type-error"
        *ngIf="(policyForm.get('policyType').touched || policyForm.get('policyType').dirty)
                    && policyForm.get('policyType').errors?.required ">Policy type is required</span>

  <h2 [ngClass]="'insured'">Insured Details</h2>
  <label for="firstName" [ngClass]="'first-name-label'">First Name</label>
  <label for="lastName" [ngClass]="'last-name-label'">Last Name</label>
  <input *ngIf="this.isReviewing === false" type="text" id="firstName" [ngClass]="'first-name-input'" formControlName="firstName" required />
  <input *ngIf="this.isReviewing === true" type="text" id="firstName" [ngClass]="'first-name-input local'" formControlName="firstName"
         autocomplete="no" readonly />
  <span class="help-block first-name-error"
        *ngIf="(policyForm.get('firstName').touched || policyForm.get('firstName').dirty)
                    && policyForm.get('firstName').errors?.required ">First name is required</span>

  <input *ngIf="this.isReviewing === false" type="text" id="lastName" [ngClass]="'last-name-input'" formControlName="lastName" required />
  <input *ngIf="this.isReviewing === true" type="text" id="lastName" [ngClass]="'last-name-input local'" formControlName="lastName"
         autocomplete="no" readonly />
  <span class="help-block last-name-error"
        *ngIf="(policyForm.get('lastName').touched || policyForm.get('lastName').dirty)
                    && policyForm.get('lastName').errors?.required ">Last name is required</span>

  <label for="middleInitial" [ngClass]="'middle-initial-label'">Middle Initial</label>
  <input *ngIf="this.isReviewing === false" type="text" id="middleInitial" [ngClass]="'middle-initial-input'" formControlName="middleInitial" />
  <input *ngIf="this.isReviewing === true" type="text" id="middleInitial" [ngClass]="'middle-initial-input local'" formControlName="middleInitial"
         autocomplete="no" readonly />

  <label for="suffix" [ngClass]="'suffix-label'">Suffix</label>
  <select *ngIf="this.isReviewing === false" [ngClass]="'suffix-input'" formControlName="suffix">
    <option value="null"
            hidden>
    </option>
    <option *ngFor="let suffix of suffixes"
            [ngValue]="suffix.name">
      {{suffix.name}}
    </option>
  </select>
  <select *ngIf="this.isReviewing === true" [ngClass]="'suffix-input local'" formControlName="suffix" readonly disabled>
    <option *ngFor="let suffix of suffixes"
            [ngValue]="suffix.name">
      {{suffix.name}}
    </option>
  </select>
</div>
