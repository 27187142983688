<app-custom-modal id="mortgagee-exists">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="custom-modal-body">
                <div class="column">
                    <div class="mortgagee-exists-title mortgagee-exists-modal-text">
                        Mortgagee Already Exists
                    </div>
                    <div class="column mortgagee-details-text">
                        <div class="mortgagee-name">
                            {{existingMortgagee.name | titlecase}}
                        </div>
                        <div class="mortgagee-address">
                            {{transformAddress(existingMortgagee.address1 | titlecase, existingMortgagee.address2 | titlecase) }} {{existingMortgagee.city | titlecase }}, {{existingMortgagee.state | uppercase}} {{existingMortgagee.zip}}
                        </div>
                    </div>
                    <div>
                        We've confirmed that the mortgagee above already exists in our system.
                    </div>
                    <div class="button-row">
                        <button (click)="closeModal()">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-custom-modal>
