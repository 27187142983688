<div class="content">
  <h1>Update Mortgagee Details</h1>
  <div [ngClass]="'progress-bar-container'">
    <div id="policy" [ngClass]="this.activeScreen === 0 ? 'step-numbers active' : 'step-numbers completed'">&nbsp;</div>
    <div id="mortgagee" [ngClass]="this.activeScreen === 1 ? 'step-numbers active' : this.activeScreen > 1 ? 'step-numbers completed' : 'step-numbers default'">&nbsp;</div>
    <div id="consent" [ngClass]="this.activeScreen === 2 ? 'step-numbers active' : this.activeScreen > 2 ? 'step-numbers completed' : 'step-numbers default'">&nbsp;</div>
    <div id="review" [ngClass]="this.activeScreen === 3 ? 'step-numbers active' : 'step-numbers default'">&nbsp;</div>
  </div>
  <div [ngClass]="'step-numbers-container'">
    <div [ngClass]="this.activeScreen === 0 ? 'step-numbers bold' : 'step-numbers'">1. Policy Details</div>
    <div [ngClass]="this.activeScreen === 1 ? 'step-numbers bold' : 'step-numbers'">2. Mortgagee Details</div>
    <div [ngClass]="this.activeScreen === 2 ? 'step-numbers bold' : 'step-numbers'">3. Contact and Consent</div>
    <div [ngClass]="this.activeScreen === 3 ? 'step-numbers bold' : 'step-numbers'">4. Review and Submit</div>
  </div>

  <div [ngClass]="'component-container'">
    <div [ngClass]="'pages'" [ngSwitch]="activeScreen">
      <app-policy-information *ngSwitchCase="'0'" [policyInformation]="this.policyDetails" [reviewing]="this.isReviewing"></app-policy-information>
      <app-mortgagee-details *ngSwitchCase="'1'"
                             [mortgageeDetails]="this.mortgageeDetails"
                             [newMortgagee]="this.newMortgagee"
                             [addingNewMortgagee]="this.addingNewMortgagee"
                             [reviewing]="this.isReviewing">
      </app-mortgagee-details>
      <app-contact-and-consent *ngSwitchCase="'2'" [consent]="this.contactDetails" [reviewing]="this.isReviewing"></app-contact-and-consent>
      <app-review-submit *ngSwitchCase="'3'"
                         [policyInformation]="this.policyDetails"
                         [mortgageeDetailsInput]="this.mortgageeDetails"
                         [addingNewMortgagee]="this.addingNewMortgagee"
                         (changeScreen)="changeScreen($event)"></app-review-submit>
    </div>
    <div [ngClass]="'summary'">
      <app-order-summary [activeScreen] = "this.activeScreen"></app-order-summary>
    </div>
  </div>

  <div [ngClass]="'buttons-container'">
    <button [ngClass]="'cancel-button'" (click)="cancelOrBack()">{{activeScreen === 0 ? "Cancel" : "Previous"}}</button>
    <button [ngClass]="'primary'" (click)="next()" *ngIf="this.activeScreen < 3" [disabled]="!this.isPolicyFormValid()">Next</button>
  </div>
  <app-add-mortgagee-suggestions-modal></app-add-mortgagee-suggestions-modal>
</div>
