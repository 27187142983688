<div class="section confirm">
  <h2>Request Submitted Successfully</h2>
  <p>
    Click <a [routerLink]="['/my-account']" [queryParams]="{setOption: 2}"> here</a>  to view the status of your request or click on the button below to return to the transaction history page.
  </p>
  <div class="input-btn">
    <button (click)="returnToTransactionHistory()"
            type="submit"
            role="button">
      Return To Transaction History
    </button>
  </div>
</div>
