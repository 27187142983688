import {Injectable} from '@angular/core';
import { Suffix } from '../../interfaces/search/suffix';

@Injectable({
  providedIn: 'root',
})
export class SuffixService {
  public getSuffixes(): Array<Suffix> {
    return [
      {
        name: 'SR',
      },
      {
        name: 'JR',
      },
      {
        name: 'II'
      },
      {
        name: 'III'
      },
      {
        name: 'IV'
      },
    ];
  }
}
