<!--  NOTE - for some reason [ngClass] breaks the css so we use the traditional class attribute. -->
<section class="accordion">
  <span class="arrow"></span>
  Policy Details <span [ngClass]="'change'" (click)="navigateToScreen(0)">Change</span>
</section>
<div class="panel">
  <app-policy-information [policyInformation]="this.policyDetails" [reviewing]="this.isReviewing"></app-policy-information>
</div>

<section class="accordion">
  <span class="arrow"></span>
  Mortgagee Details <span [ngClass]="'change'" (click)="navigateToScreen(1)">Change</span>
</section>
<div class="panel">
  <app-mortgagee-details
                         [mortgageeDetails]="this.mortgageeDetails"
                         [reviewing]="this.isReviewing"
                         [addingNewMortgagee]="this.addingNewMortgagee"></app-mortgagee-details>
</div>

<section class="accordion">
  <span class="arrow"></span>
  Contact and Consent <span [ngClass]="'change'" (click)="navigateToScreen(2)">Change</span>
</section>
<div class="panel">
  <app-contact-and-consent [reviewing]="this.isReviewing"></app-contact-and-consent>
</div>
