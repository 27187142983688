<div class="account-header">Mortgagee Update History </div>
<div class="row">
    <button [class.green-button]="currentPage > 1"  class="" (click)="getSet('First')">First</button>
    <button [class.green-button]="currentPage > 1" class="" (click)="getSet('Previous')">Previous</button>
    <div class="page-info">{{currentPage}} / {{totalPages}}</div>
    <button [class.green-button]="currentPage  !== totalPages" class="" (click)="getSet('Next')">Next</button>
    <button [class.green-button]="currentPage !== totalPages" class="" (click)="getSet('Last')">Last</button>
</div>
<div class="grid">
    <div class="header-cell">Loan Number</div>
    <div class="header-cell">Mortgagee</div>
    <div class="header-cell">Position</div>
    <div class="header-cell">Action</div>
    <div class="header-cell">Status</div>
    <div class="header-cell">Request Date</div>
    <div class="header-cell">Reference ID</div>
    <div class="header-cell">Updated Dec Page</div>

    <ng-container *ngFor="let lossPayeeRequest of getLossPayeeRequestsResponse?.items">
        <div class="row-wrapper">
            <span class="cell">
                <div class="loan-number">
                    {{lossPayeeRequest.loanNumber}}
                </div>
            </span>
            <span class="cell">
                <div class="mortgagee-name" [class.long-mortgagee-text-wrap]="lossPayeeRequest.mortgageeName.length > 50">{{lossPayeeRequest.mortgageeName | titlecase }}</div>
                <div class="mortgagee-address-cell" [class.long-mortgagee-text-wrap]="lossPayeeRequest.address1.length > 50">
                    {{transformAddress(lossPayeeRequest.address1 | titlecase, lossPayeeRequest.address2 | titlecase)}}
                    {{lossPayeeRequest.city | titlecase}}
                    {{lossPayeeRequest.state }},
                    {{lossPayeeRequest.zip | titlecase}}
                </div>
            </span>
            <span class="cell">{{lossPayeeRequest.position}}</span>
            <span class="cell">{{transformOperation(lossPayeeRequest.operation)}}</span>
            <span *ngIf="lossPayeeRequest.status !== 'Error' && lossPayeeRequest.status !== 'Pending'" class="cell">{{lossPayeeRequest.status}}</span>
            <span *ngIf="lossPayeeRequest.status === 'Pending'" class="cell"><button (click)="openModal(lossPayeeRequest, 'delivered-to-insurer')" class = "contact-insurer-button" id="contactBtn">Delivered to Insurer</button></span>
            <span *ngIf="lossPayeeRequest.status === 'Error'" class="cell"><button (click)="openModal(lossPayeeRequest, 'contact-insurance')" class="contact-insurer-button" id="contactBtn">Contact Insurer</button></span>
            <span class="cell">{{lossPayeeRequest.createdDate | date: 'shortDate' }}</span>
            <span class="cell">{{lossPayeeRequest.referenceId}}</span>
            <span *ngIf="lossPayeeRequest.status === 'Completed'" class="cell"><button (click)="downloadEoi(lossPayeeRequest.lossPayeeRequestId)"
                class="download-button" [id]="'download-button' + lossPayeeRequest.lossPayeeRequestId">Download</button></span>
            <span *ngIf="lossPayeeRequest.status !== 'Completed'" class="cell"></span>
        </div>
    </ng-container>
</div>
<div class="row">
    <button [class.green-button]="currentPage > 1" class="navigation-page-button" (click)="getSet('First')">First</button>
    <button [class.green-button]="currentPage > 1" class="navigation-page-button" (click)="getSet('Previous')">Previous</button>
    <div class="page-info">{{currentPage}} / {{totalPages}}</div>
    <button [class.green-button]="currentPage  !== totalPages" class="navigation-page-button green-button" (click)="getSet('Next')">Next</button>
    <button [class.green-button]="currentPage !== totalPages" class="navigation-page-button green-button" (click)="getSet('Last')">Last</button>
</div>
<app-contact-insurer-modal></app-contact-insurer-modal>
<app-delivered-to-insurer-modal></app-delivered-to-insurer-modal>
