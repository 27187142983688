<div [ngClass]="'container'">
  <h2>Frequently Asked Questions (FAQ)</h2>

  <p>
    Below you will find our Frequently Asked Questions topics broken into categories. For a more detailed and encompassing guide to MERS HOI Hub, please check
    out our <a (click)="setParentOption()">Training Materials</a> to download our full User Guide.
  </p>

  <div class="section">
    <h3>General</h3>
    <div class="sec-content">
      <ul class="list-content">
        <li>
          <h4>What is MERS HOI Hub?</h4>
          <p>
            MERS HOI Hub is a secure platform that allows mortgage lenders to obtain evidence of insurance for their borrowers from numerous insurance providers without having to manage multiple
            B2B/lender portal website log-ins and contacting insurance providers and their agents directly. MERS HOI Hub generates evidence of insurance from our insurance providers
            in real time, via direct API integrations it maintains.
          </p>
        </li>
        <li>
          <h4>Who can use MERS HOI Hub?</h4>
          <p>
            Only verified mortgage lenders can use the MERS HOI Hub platform to obtain insurance information about their borrowers going through a mortgage transaction.
            The platform verifies a vast majority of users using the Nationwide Multistate Licensing System & Registry (NMLS) data automatically.
          </p>
        </li>
        <li>
          <h4>Why should I use MERS HOI Hub?</h4>
          <p>
            Instead of juggling log-ins for a multitude of insurance providers and trading email, faxes and phone calls to agents and call centers, MERS HOI Hub provides a
            one-stop solution to the top insurance providers in the industry with the exact data that mortgage lenders need to verify their borrowers insurance immediately
            and close loans quickly.
          </p>
        </li>
        <li>
          <h4>What does MERS HOI Hub provide?</h4>
          <p>Upon purchase, MERS HOI Hub provides evidence of insurance as a downloadable PDF that is generated based on data received in real time from the insurer.</p>
        </li>
        <li>
          <h4>What insurers are on MERS HOI Hub?</h4>
          <p>The list of participating insurance providers is available on our public <a href="https://www.mersinc.org/products-services/hoi-hub" [ngClass]="'underline-link'" target="_blank">website</a>. We are actively engaging with other insurance providers to increase insurer participation, so check back often!</p>
        </li>
        <li>
          <h4>I am an insurer. How do I join MERS HOI Hub?</h4>
          <p>Please send us a message at <a href="mailto:MERSHOI.SUPPORT@ice.com" [ngClass]="'underline-link'">MERSHOI.SUPPORT@ice.com</a> so we can chat!</p>
        </li>
        <li>
          <h4>I am a lender/loan origination system (LOS). Can I integrate with MERS HOI Hub so I/my lenders don't need to use the portal?</h4>
          <p>
            Yes! We have already integrated with MERS HOI Hub in several loan origination systems. Integrating with MERS HOI Hub allows lenders to pull in raw data directly into
            their LOS and eliminates rekeying from PDFs. Please send us a message at <a href="mailto:MERSHOI.SUPPORT@ice.com" [ngClass]="'underline-link'">MERSHOI.SUPPORT@ice.com</a> so we can chat!
          </p>
        </li>
      </ul>
    </div>
  </div>

  <div class="section">
    <h3>Search</h3>
    <div class="sec-content">
      <ul class="list-content">
        <li>
          <h4>Why didn't my search return any results?</h4>
          <div>
            <ul>
              <li>Double check the address</li>
              <li>Try a co-borrower on the loan</li>
              <li>Exclude middle initial and suffix</li>
              <li>The current insurer is not part of MERS HOI Hub (yet!)</li>
              <li>The current insurer’s system may be down temporarily (this is very rare)</li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <div class="section">
    <h3>Account</h3>
    <div class="sec-content">
      <ul class="list-content">
        <li>
          <h4>How do I create an account?</h4>
          <p>Navigate to the MERS HOI Hub portal by clicking <a (click)="createAccountUrl()">here</a>. Follow the prompts and fill in the required information to create your account.</p>
        </li>
        <li>
          <h4>Why does it say my account is "Pending Review"?</h4>
          <p>
            You have likely registered with an email domain that is not a recognized list of verified mortgage lenders. The MERS HOI Hub team will manually verify your registration
            before giving you access.
          </p>
        </li>
        <li>
          <h4>Why am I getting a message "unable to activate my account"?</h4>
          <p>
            This is happening because an issue occurred with the registration of your account.
            Please contact <a href="mailto:MERSHOI.SUPPORT@ice.com" [ngClass]="'underline-link'">MERSHOI.SUPPORT@ice.com</a> for assistance.
          </p>
        </li>
        <li>
          <h4>Why am I getting a message (on the page) that my account is locked?</h4>
          <p>
            Your account could be locked if the MERS HOI Hub support team detected unusual activity coming from it.
            Please contact <a href="mailto:MERSHOI.SUPPORT@ice.com" [ngClass]="'underline-link'">MERSHOI.SUPPORT@ice.com</a> for assistance.
          </p>
        </li>
        <li>
          <h4>What do I do if I forgot my password?</h4>
          <p>Easy! Simply click on the “Forgot your Password?” link on the main sign-in page.</p>
        </li>
      </ul>
    </div>
  </div>

  <div class="section">
    <h3>Payment</h3>
    <div class="sec-content">
      <ul class="list-content">
        <li>
          <h4>How much does it cost to use MERS HOI Hub?</h4>
          <p>$18 to generate an evidence of insurance PDF, but it is free to view search results, which include Effective and Expiration dates along with the Premium Amount.</p>
        </li>
        <li>
          <h4>How can I get a discounted rate?</h4>
          <p>
            We are happy to offer discounted rates based on volume to our integrated partners. Please send us a message at
            <a href="mailto:MERSHOI.SUPPORT@ice.com" [ngClass]="'underline-link'">MERSHOI.SUPPORT@ice.com</a> so we can chat!
          </p>
        </li>
        <li>
          <h4>I purchased and received evidence of insurance for one of my borrowers. What if I don't want it and would like a refund?</h4>
          <p>Please send us a note at <a href="mailto:MERSHOI.SUPPORT@ice.com" [ngClass]="'underline-link'">MERSHOI.SUPPORT@ice.com</a> and we can review.</p>
        </li>
      </ul>
    </div>
  </div>

  <div class="section">
    <h3>Miscellaneous</h3>
    <div class="sec-content">
      <ul class="list-content">
        <li>
          <h4>What policy types are currently supported?</h4>
          <div>
            <ul>
              <li>Homeowners policies (HO-3)</li>
              <li>Some insurers will return Condo and landlord policies, however.</li>
            </ul>
          </div>
        </li>
        <li>
          <h4>I think there's suspicious activity in my transaction history. What should I do?</h4>
          <p>Please contact us at <a href="mailto:MERSHOI.SUPPORT@ice.com" [ngClass]="'underline-link'">MERSHOI.SUPPORT@ice.com</a></p>
        </li>
        <li>
          <h4>Why do I need to upload borrower consent?</h4>
          <p>
            We take our insurers’ and lenders’ borrower data very seriously. Insurers participating on our platform can be sure MERS HOI Hub always has a signed borrower consent
            before disclosing any borrower information and we allow audits to support that.
          </p>
        </li>
        <li>
          <h4>What file types are supported to upload borrower consent?</h4>
          <p>The MERS HOI Hub product supports the Adobe® PDF file format. </p>
        </li>
        <li>
          <h4>What browsers are supported?</h4>
          <p>The MERS HOI Hub portal should function properly using a supported version of a modern, standards-compliant browser like Google Chrome&trade;</p>
        </li>
        <li>
          <h4>
            The information on the homeowners policy provided doesn't provide me with all the information I require.
            What do I do or who do I contact?
          </h4>
          <p>
            MERS HOI Hub provides Homeowners Information from insurance providers that is consistent or at times more expansive then what the insurance providers typically provide on
            their Homeowners Insurance Policy information.  At times this information may still be too limited to fully answer all questions that you as a Lender may have.
            In those cases you should contact the insurance provider at the number provided with the information provided by MERS HOI Hub.  This will connect you directly with the
            insurance provider for further clarification.
          </p>
          <a (click)="scrollToTop()">Back to top</a>
        </li>
      </ul>
    </div>
  </div>
</div>
