import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-loss-payee-confirm',
  templateUrl: './loss-payee-confirm.component.html',
  styleUrls: ['./loss-payee-confirm.component.scss']
})
export class LossPayeeConfirmComponent {

  constructor(private _router: Router) { }

  navigateTo() {
    this._router.navigate(['/home']);
  }

}
