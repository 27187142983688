<div class="content">
  <h1>Insurance Search</h1>
  <form novalidate
        [formGroup]="briefPolicySearchForm"
        class="form-group row"
        (ngSubmit)="submit">
    <div class="input-wrapper">
      <div class="input-col-4">
        <div class="input first-name">
          <app-input [labelName]="'First Name'"
                     [isRequired]="true"
                     [group]="briefPolicySearchForm"
                     [controlName]="'firstName'"
                     [maxlength]="50">
          </app-input>
        </div>

        <div class="input middle-initial">
          <app-input class="input-small"
                     [labelName]="'Middle Initial'"
                     [isRequired]="false"
                     [group]="briefPolicySearchForm"
                     [controlName]="'middleInitial'"
                     [maxlength]="1">
          </app-input>
        </div>

        <div class="input last-name">
          <app-input [controlName]="'lastName'"
                     [isRequired]="true"
                     [group]="briefPolicySearchForm"
                     [labelName]="'Last Name'"
                     [maxlength]="50">
          </app-input>
        </div>

        <div class="input">
          <label for="suffix">Suffix:</label>
          <select class="form-control"
                  formControlName="suffix">
            <option value="null"
                    hidden>
            </option>
            <option *ngFor="let suffix of suffixes"
                    [ngValue]="suffix.name">
              {{suffix.name}}
            </option>
          </select>
        </div>

        <div class="input policy-type">
          <label for="policyType">Policy Type:</label>
          <select class="form-control"
                  formControlName="policyType">
            <option value="null"
                    hidden>
              Select Policy Type
            </option>
            <option *ngFor="let policy of policyTypes"
                    [ngValue]="policy.name">
              {{policy.name}}
            </option>
          </select>
          <span class="help-block"
                *ngIf="briefPolicySearchForm.get('policyType').touched && !!briefPolicySearchForm.get('policyType').errors?.required">
            Policy type is required
          </span>
        </div>
        <div class="input street-address-one">
          <app-input [controlName]="'streetAddressOne'"
                     [group]="getAddressFormGroup()"
                     [isRequired]="true"
                     [labelName]="'Street Address 1'"
                     [maxlength]="100">
          </app-input>
        </div>

        <div class="input street-address-two">
          <app-input [controlName]="'streetAddressTwo'"
                     [group]="getAddressFormGroup()"
                     [isRequired]="false"
                     [labelName]="'Street Address 2'"
                     [maxlength]="100">
          </app-input>
        </div>

        <div class="input city">
          <app-input [controlName]="'city'"
                     [isRequired]="true"
                     [group]="getAddressFormGroup()"
                     [labelName]="'City'"
                     [maxlength]="100">
          </app-input>
        </div>

        <div class="input state"
             [formGroup]="getAddressFormGroup()"
             [ngClass]="(getAddressFormGroup().get('state').touched) && getAddressFormGroup().get('state').invalid ? 'has-error' : ''">
          <label for="exampleFormControlSelect1">State:</label>
          <select class="form-control"
                  formControlName="state">
            <option value="null"
                    hidden>
              Select State
            </option>
            <option *ngFor="let state of states"
                    [ngValue]="state.abbreviation">
              {{state.name}}
            </option>
          </select>
          <span class="help-block"
                *ngIf="getAddressFormGroup().get('state').touched && !!getAddressFormGroup().get('state').errors?.required">
            Select
            a state
          </span>
        </div>

        <div class="input zip input-small">
          <app-zipcode [controlName]="'zip'"
                       [isRequired]="true"
                       [group]="getAddressFormGroup()"
                       [labelName]="'Zip'"
                       [maxLength]="5"
                       [minLength]="5"
                       [numbersOnly]="true">
          </app-zipcode>
        </div>

      </div>
    </div>
    <div class="input-btn border">
      <button type="button" (click)="onReset()">Reset</button>
      <button type="submit" (click)="submit()" [disabled]="isSearching||briefPolicySearchForm.invalid">
        <span [ngClass]="{'fas fa-spinner fa-spin' : isSearching}"></span>
        {{!isSearching?buttonName:''}}
      </button>
    </div>
  </form>
  <app-progress-bar (startSearching)="search()"></app-progress-bar>

</div>

