import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-zipcode',
  templateUrl: './zip-code.component.html',
})
export class ZipCodeComponent implements OnInit {
  @Input() group: FormGroup;
  @Input() controlName: string;
  @Input() labelName: string;
  @Input() type = 'text';
  @Input() isRequired = false;
  @Input() canMarkAsOptional = false;
  @Input() minLength?: number;
  @Input() maxLength?: number;
  @Input() placeholder?: string;
  @Input() numbersOnly?: boolean;

  // eslint-disable-no-output-native
  @Output() input: EventEmitter<any> = new EventEmitter();

  public submittedButNotValid = false;

  public validationMessages: Object;

  ngOnInit() {
    this.validationMessages = {
      answer: [
        { type: 'required', message: `${this.labelName} is required` },
        { type: 'minlength', message: `Zip must be 5 digits` }
      ]
    };
  }

  public hasFieldValidationError(
    controlName: string,
    errorCode: string
  ): boolean {
    return (
      (this.group.get(this.controlName).touched || this.submittedButNotValid) &&
      this.group.get(this.controlName).hasError(errorCode) &&
      this.isRequired
    );
  }

  public get hasErrorClassName() {
    return this.group.get(this.controlName).touched &&
      this.group.get(this.controlName).invalid &&
      this.isRequired
      ? 'has-error'
      : '';
  }

  public resetTextForm(): void {
    this.group.markAsPristine();
    this.group.reset();
  }
}
