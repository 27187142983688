import { Component } from '@angular/core';
import { RouteTrackerService } from '../../../services/route-tracker.service';

@Component({
  selector: 'app-error-400',
  templateUrl: './error400.component.html',
  styleUrls: ['./error400.component.scss']
})

export class Error400Component  {
  enableUI: string;

  constructor(private _urlService: RouteTrackerService) {
    this.enableUI = this._urlService.hasFilteredUrl() ? 'hide' : '';
  }
}
