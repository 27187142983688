<app-custom-modal id="add-mortgagee-suggestions">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="custom-modal-body">
                <div class="column">
                    <div class="mortgagee-suggestions-title mortgagee-suggestions-modal-text">
                        {{modalTitle}}
                    </div>
                    <div class="mortgagee-suggestions-modal-text mortgagee-suggestions-sub-title">
                        You are adding the following mortgagee:
                    </div>
                    <div class="row"
                         (click)="selectAddMortgagee()"
                         [ngClass]="{'row-clicked': isMortgageeNew }">
                        <div class="column">
                            <div class="mortgagee-name">
                                {{addedMortgagee.name | titlecase}}
                            </div>
                            <div class="mortgagee-address">
                                {{ transformAddress(addedMortgagee.address1 | titlecase, addedMortgagee.address2 | titlecase) }} {{addedMortgagee.city | titlecase }}, {{addedMortgagee.state | uppercase}} {{addedMortgagee.zip}}
                            </div>
                        </div>
                        <div class="custom-radio-button">
                            <input type="radio"
                                   name="mortgagee-radio"
                                   [(ngModel)]="isMortgageeNew"
                                   [value]="isMortgageeNew"/>
                        </div>
                    </div>
                    <div *ngIf="mortgagees.length > 0" class="mortgagee-suggestions-modal-text mortgagee-suggestions-sub-title">
                        Did you mean one of these?
                    </div>
                    <div class="mortgagee-suggestions-container">
                        <div *ngFor="let mortgagee of mortgagees"
                             (click)="selectMortgageeSuggestion(mortgagee)"
                             class="row"
                             [ngClass]="{'row-clicked': mortgagee.mortgageeId === selectedMortgagee?.mortgageeId }">
                            <div class="column">
                                <div class="mortgagee-name">
                                    {{mortgagee.name | titlecase}}
                                </div>
                                <div class="mortgagee-address">
                                    {{ transformAddress(mortgagee.address1 | titlecase, mortgagee.address2 | titlecase) }} {{mortgagee.city | titlecase }}, {{mortgagee.state | uppercase}} {{mortgagee.zip}}
                                </div>
                            </div>
                            <div class="custom-radio-button">
                                <input
                                       type="radio"
                                       name="mortgagee-radio"
                                       [(ngModel)]="selectedMortgagee"
                                       [value]="mortgagee"/>
                            </div>
                        </div>
                    </div>
                    <div class="button-row">
                        <button (click)="closeModal(mortgageeSuggestionModalName)">Cancel</button>
                        <button (click)="confirmMortgagee()" class="primary">
                          {{isNewMortgageeConfirmed ? "" : "Confirm"}}<span [ngClass]="{'fas fa-spinner fa-spin': isNewMortgageeConfirmed}"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-custom-modal>
