import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { InsuredDetails } from '../../../../interfaces/loss-payee-express/insured-details';
import { PolicyInformation } from '../../../../interfaces/loss-payee-express/policy-information';
import { PolicyType } from '../../../../interfaces/search/policy-type';
import { State } from '../../../../interfaces/search/state';
import { Suffix } from '../../../../interfaces/search/suffix';
import { AddressViewModel } from '../../../../interfaces/transactions/address-view-model';
import { LossPayeeService } from '../../../../services/loss-payee/loss-payee.service';
import { PolicyTypeService } from '../../../../services/policyType/policy-type.service';
import { StatesService } from '../../../../services/states/states.service';
import { SuffixService } from '../../../../services/suffixes/suffixes.service';

@Component({
  selector: 'app-policy-information',
  templateUrl: './policy-information.component.html',
  styleUrls: ['./policy-information.component.css']
})
export class PolicyInformationComponent implements OnInit, AfterViewInit {

  @Input() policyInformation: PolicyInformation;
  @Input() reviewing: boolean;

  policyTypes: Array<PolicyType>;
  policyForm: FormGroup;
  p: PolicyInformation;
  states: Array<State>;
  suffixes: Array<Suffix>;
  isReviewing = false;

  constructor(
    private _formBuilder: FormBuilder,
    private _policyService: PolicyTypeService,
    private _lossPayeeService: LossPayeeService,
    private _suffixService: SuffixService,
    private _statesService: StatesService) { }

  ngOnInit(): void {
    this.policyForm = this.buildForm();
    this.states = this._statesService.getStates();
    this.suffixes = this._suffixService.getSuffixes();
    this.policyTypes = this._policyService.getPolicyTypes();
  }

  //  we use ngAfterViewInit because the user can navigate back here. So ngOnInit has already fired and won't fire again.
  ngAfterViewInit(): void {
    this.isReviewing = this.reviewing;
    let p = this._lossPayeeService.policyInformation;    

    if (p === this.policyInformation) {
      this.mapModelToForm(p);
    }

    if (this.isReviewing === true) {
      this.policyForm = this.noValidationForm();
      this.mapModelToForm(p);
    }
  }

  mapFormToModel(): void {
    let policy = {} as PolicyInformation;
    let address = {} as AddressViewModel;
    let insured = {} as InsuredDetails;

    policy.propertyAddress = address;
    policy.insuredDetails = insured;


    policy.propertyAddress.address1 = this.policyForm.get('address1').value;
    policy.propertyAddress.address2 = this.policyForm.get('address2').value;
    policy.propertyAddress.city = this.policyForm.get('city').value;
    policy.propertyAddress.state = this.policyForm.get('state').value;
    policy.propertyAddress.zip = this.policyForm.get('zip').value;

    policy.insuredDetails.firstName = this.policyForm.get('firstName').value;
    policy.insuredDetails.lastName = this.policyForm.get('lastName').value;
    policy.insuredDetails.middleInitial = this.policyForm.get('middleInitial').value;
    policy.insuredDetails.suffix = this.policyForm.get('suffix').value;

    policy.policyType = this.policyForm.get('policyType').value;
    policy.policyNumber = this.policyForm.get('policyNumber').value;

    this._lossPayeeService.policyInformation = policy;
  }

  mapModelToForm(pi: PolicyInformation): void {
    this.policyForm.get('address1').setValue(pi.propertyAddress.address1);
    this.policyForm.get('address2').setValue(pi.propertyAddress.address2);
    this.policyForm.get('city').setValue(pi.propertyAddress.city);
    this.policyForm.get('state').setValue(pi.propertyAddress.state);
    this.policyForm.get('zip').setValue(pi.propertyAddress.zip);

    this.policyForm.get('firstName').setValue(pi.insuredDetails.firstName);
    this.policyForm.get('lastName').setValue(pi.insuredDetails.lastName);
    this.policyForm.get('middleInitial').setValue(pi.insuredDetails.middleInitial);
    this.policyForm.get('suffix').setValue(pi.insuredDetails.suffix);

    this.policyForm.get('policyType').setValue(pi.policyType);
    this.policyForm.get('policyNumber').setValue(pi.policyNumber);
  }

  resetForm() {
    this.policyForm.reset();
    this._lossPayeeService.policyInformation = null;
    this.policyForm = this.buildForm();
    this.policyForm.updateValueAndValidity();
  }

  private buildForm(): FormGroup {

    return this._formBuilder.group({
      address1: ['', [Validators.required]],
      address2: [''],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      zip: ['', [Validators.required]],
      policyNumber: ['', [Validators.required]],
      policyType: ['', [Validators.required, this.defaultAction]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      middleInitial: [''],
      suffix: ['']
    })

  }

  private defaultAction(fc: FormControl) {
    let isInvalid = '' === fc.value;
    return isInvalid ? { defaultValue: 'Cannot use defualt value' } : null;
  }

  private noValidationForm(): FormGroup {

    return this._formBuilder.group({
      address1: [''],
      address2: [''],
      city: [''],
      state: [''],
      zip: [''],
      policyNumber: [''],
      policyType: [''],
      firstName: [''],
      lastName: [''],
      middleInitial: [''],
      suffix: ['']
    })

  }

}
