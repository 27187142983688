<app-custom-modal id="delivered-to-insurer">
    <div class="modal-dialog"  role="document">
        <div class="modal-content">
            <div class="custom-modal-body">
                <div class="delivered-to-insurer-modal-text delivered-to-insurer-title">
                    Delivered to Insurer
                </div>
                <div class="delivered-to-insurer-text">
                    The insurer is currently working on processing your mortgagee update request.
                    <br/><br/>
                    A response will be sent to the email address "{{clientEmail}}" that was provided as part of the request.
                    <br/><br/>

                    <span *ngIf="insurer !== null && insurer.lossPayeePhone !== '' " > If you do not receive a response within 3 business days, you can contact {{insurer.name}} at: <a class="loss-payee-support-phone" [href]="'tel:' + insurer.lossPayeePhone">{{insurer.lossPayeePhone | phoneFormat}}</a></span>
                    <span *ngIf="insurer === null || insurer.lossPayeePhone === '' " > If you do not receive a response within 3 business days, you can contact us at MERSHOI.SUPPORT@ice.com</span>


                    <br/><br/><br/>
                    <span *ngIf="insurer !== null && insurer.lossPayeePhone !== '' " > Please share the following detials when you contact them</span>
                    <span *ngIf="insurer === null || insurer.lossPayeePhone === ''" > Please share the following details when you reach out</span>
                    <br/>
                    - Reference number: {{referenceId}}<br/>
                    - Policy number<br/>
                    - Mortgagee update details<br/>
                </div>
                   <br/>
                  <button (click)="closeModal()">Close</button>
            </div>
        </div>
    </div>
</app-custom-modal>
