<app-custom-modal id="session-timeout">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h2>Session Expire Warning</h2>
      </div>
      <div class="modal-body">
        <p>{{message}}</p>
      </div>
      <div class="modal-action">
        <div class="input-btn">
          <button class="primary" (click)="closeModal()">OK</button>
        </div>
      </div>
    </div>
  </div>
</app-custom-modal>
