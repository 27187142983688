<div class="content">
  <h1>Update Mortgagee Details</h1>
  <form novalidate
        [formGroup]="mortgageeForm"
        class="form-group"
        (ngSubmit)="submit">
    <div class="input-wrapper">
      <div class="section">
        <h2>Policy Information</h2>
        <div [ngClass]="'input-col-2'">
          <div class="input">
            <label>Property Address:</label>
            <input type="text" id="propertyAddress" formControlName="fullAddress" />
          </div>

          <div class="input">
            <label>Policy Number:</label>
            <input type="text" id="policyNumber" maxlength="50" formControlName="policyNumber" required [ngClass]="'form-control'" /><br />
            <span [ngClass]="'help-block help'"
                  *ngIf="(mortgageeForm.get('policyNumber').touched || mortgageeForm.get('policyNumber').dirty) && mortgageeForm.get('policyNumber').errors?.required">Policy Number is required</span>
          </div>
        </div>
      </div>

      <div class="section">
        <h2>Mortgagee Details</h2>
        <div class="input-col-3">
          <div class="input">
            <label>Mortgagee:</label>
            <input type="text" id="mortgageeName" formControlName="mortgageeName" required (input)="this.search()" autocomplete="off"/><br />
            <ul *ngIf="this.noMortgageesFound === false" ng-scrollbars>
              <li id="singleMortgagee" *ngFor="let mortgagee of this.mortgagees.data.mortgagees; let i=index" (click)="this.selectItem(i)">
                <span [ngClass]="'mortgagee-name'">{{ mortgagee.name }}</span> <br /> <span [ngClass]="'mortgagee-address1'">{{ mortgagee.address1 }} <span *ngIf="this.mortgagee.address2.length > 0" [ngClass]="'mortgagee-address1'">{{ mortgagee.address2 }}</span> {{ mortgagee.city }} {{ mortgagee.state }} {{ mortgagee.zip }}</span>
              </li>
            </ul>
            <span [ngClass]="'help-block help'"
                  *ngIf="(mortgageeForm.get('mortgageeName').touched || mortgageeForm.get('mortgageeName').dirty) && mortgageeForm.get('mortgageeName').errors?.required">Mortgagee is required</span>
          </div>

          <!--begin internal form-->
          <div [ngClass]="'add-mortgagee'" *ngIf="this.addingMortgagee === true">
            <div class="form-group">
              <div [ngClass]="'orange-banner'">
                <p>Please note that any mortgagee added will require processing time.</p>
              </div>
              <div class="input-col-3 content">
                <div class="input">
                  <label for="addMortgagee" id="addMortgagee" [ngClass]="'mortgagee'">Mortgagee Name:</label> <br />
                  <input type="text" [ngClass]="'form-control mortgagee'" formControlName="addMortgagee" required maxlength="100"/> <br />
                  <span class="help-block inner-form"
                        *ngIf="(mortgageeForm.get('addMortgagee').touched
                    || mortgageeForm.get('addMortgagee').dirty)
                    && mortgageeForm.get('addMortgagee').errors?.required ">Mortgagee Name is required</span>
                </div>
                <div class="input">
                  <label for="address1" id="address1" [ngClass]="'mortgagee'">Address Line 1:</label>
                  <input type="text" [ngClass]="'form-control mortgagee'" formControlName="address1" required maxlength="100"/><br />
                  <span class="help-block inner-form"
                        *ngIf="(mortgageeForm.get('address1').touched
                    || mortgageeForm.get('address1').dirty)
                    && mortgageeForm.get('address1').errors?.required ">Address Line 1 is required</span>
                </div>
                <div class="input">
                  <label for="address2" id="address2" [ngClass]="'mortgagee'">Address Line 2:</label>
                  <input type="text" [ngClass]="'mortgagee form-control'" formControlName="address2" maxlength="100"/>
                </div>
                <div class="input">
                  <label for="city" id="city" [ngClass]="'mortgagee'">City:</label> <br />
                  <input type="text" [ngClass]="'form-control mortgagee city'" formControlName="city" required maxlength="100"/> <br />
                  <span class="help-block inner-form"
                        *ngIf="(mortgageeForm.get('city').touched || mortgageeForm.get('city').dirty) && mortgageeForm.get('city').errors?.required ">City is required</span>
                </div>
                <div class="input">
                  <label for="state" [ngClass]="'mortgagee state'">State:</label> <br />
                  <select [ngClass]="'form-control local mortgagee half-size state'" formControlName="state">
                    <option value="" disabled selected hidden>
                      Select State
                    </option>
                    <option *ngFor="let state of states" [ngValue]="state.abbreviation">
                      {{state.name}}
                    </option>
                  </select>
                  <span [ngClass]="'help-block inner-form help state'"
                        *ngIf="mortgageeForm.get('state').touched && !!mortgageeForm.get('state').errors?.required">
                  Select a state
                </span>
                </div>
                <div [ngClass]="'zip input'">
                  <label for="zip" id="zip" [ngClass]="'mortgagee'">Zip:</label> <br />
                  <input type="text" [ngClass]="'form-control mortgagee half-size'" formControlName="zip" required maxlength="5" minlength="5" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" /> <br />
                  <span class="help-block inner-form"
                        *ngIf="(mortgageeForm.get('zip').touched
                    || mortgageeForm.get('zip').dirty)
                    && mortgageeForm.get('zip').errors?.required">Zip is required</span>
                  <span class="help-block inner-form"
                        *ngIf="(mortgageeForm.get('zip').touched
                    || mortgageeForm.get('zip').dirty)
                    && mortgageeForm.get('zip').errors?.minlength ">Zip must be 5 digits</span>

                </div>
              </div>
            </div>
          </div>
          <!--end internal form-->

          <div class="input">
            <label>Position:</label>
            <select id="position" [ngClass]="'local'" formControlName="position" required>
              <option hidden>Select Position</option>
              <option value="1">1</option>
              <option value="2">2</option>
            </select><br />
            <span [ngClass]="'help-block half'"
                  *ngIf="(mortgageeForm.get('position').touched || mortgageeForm.get('position').dirty )
                && mortgageeForm.get('position').invalid">Position is required</span>
          </div>
          <div class="input">
            <label>Action:</label>
            <select id="action" [ngClass]="'local'" formControlName="action" required>
              <option hidden>Select Action</option>
              <option value="replace">Replace</option>
              <option value="replaceAll">Replace All</option>
              <option value="add">Add</option>
            </select><br />
            <span [ngClass]="'help-block half'"
                  *ngIf="(mortgageeForm.get('action').touched || mortgageeForm.get('action').dirty)
                && mortgageeForm.get('action').invalid">Action is required</span>
            <span [ngClass]="'help-block half'"
                  *ngIf="(this.invalidAction === true)">Action can only be Replace All if the <br /> position is 1</span>
          </div>

          <div class="input">
            <label>Loan Number:</label>
            <input type="text" id="loanNumber" maxlength="50" formControlName="loanNumber" required /><br />
            <span [ngClass]="'help-block help'"
                  *ngIf="(mortgageeForm.get('loanNumber').touched || mortgageeForm.get('loanNumber').dirty) && mortgageeForm.get('loanNumber').errors?.required">Loan Number is required</span>
          </div>
          <div class="input">
            <label>Effective Date:</label>
            <input type="date" id="effectiveDate" formControlName="effectiveDate" required [ngClass]="'local'" /><br />
            <span [ngClass]="'help-block help'"
                  *ngIf="(mortgageeForm.get('effectiveDate').touched || mortgageeForm.get('effectiveDate').dirty) && mortgageeForm.get('effectiveDate').errors?.required">Effective Date is required</span>
            <span [ngClass]="'help-block help'"
                  *ngIf="(mortgageeForm.get('effectiveDate').invalid && (mortgageeForm.get('effectiveDate').dirty && mortgageeForm.get('effectiveDate').touched))">Effective Date cannot be in the past</span>
          </div>

          <div class="input">
            <label>Loan Amount:</label>
            <input type="text" id="loanAmount" maxlength="50" prefix="$" mask="separator.0" thousandSeparator="," separatorLimit="1000000000" formControlName="loanAmount" /><br />
            <span [ngClass]="'help-block help'"
                  *ngIf="(mortgageeForm.get('loanAmount').touched || mortgageeForm.get('loanAmount').dirty)
                && mortgageeForm.get('loanAmount').invalid">Loan Amount is required</span>
          </div>
          <div class="input">
            <label>Escrowed:</label>
            <div class="select-group">
              <div [ngClass]="'input radio'">
                <input type="radio" id="yes" value="yes" name="escrow" formControlName="escrow" required [ngClass]="'local'" />
                <label for="yes" [ngClass]="'local'">Yes</label>
              </div>
              <div [ngClass]="'input radio'">
                <input type="radio" id="no" value="no" name="escrow" formControlName="escrow" required [ngClass]="'local'" />
                <label for="no" [ngClass]="'local'">No</label>
              </div>
              <div [ngClass]="'escrow-error-position'">
              <span [ngClass]="'help-block help'"
                    *ngIf="(mortgageeForm.get('escrow').touched || mortgageeForm.get('escrow').dirty) && mortgageeForm.get('escrow').errors?.required">Escrow Status is required</span>
                <span [ngClass]="'help-block help'"
                      *ngIf="(this.invalidEscrow === true)">Escrowed status can only be yes if the mortgagee position is 1</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <h2>Contact Details</h2>
        <div class="input-col-2">
          <div>
            <label>Email Address:</label>
            <input type="text" id="emailAddress" formControlName="email" required/><br />
            <span [ngClass]="'help-block help'"
                  *ngIf="(mortgageeForm.get('email').touched || mortgageeForm.get('email').dirty)
              && mortgageeForm.get('email').invalid">Please enter a valid email address</span><br />
          </div>
        </div>
      </div>
    </div>
    <div class="input-btn border">
      <button id="reset" (click)="this.resetForm()">Reset</button>
      <button id="history" (click)="this.backToTransactions()">Back To Transaction History</button>
      <button id="mortgagee" class="primary" (click)="submit()" [disabled]="!this.isFormValid()">
        {{isUpdatingMortgagee ? "" : "Update Mortgagee"}}<span [ngClass]="{'fas fa-spinner fa-spin': isUpdatingMortgagee}"></span>
      </button>
    </div>
  </form>
</div>
<app-add-mortgagee-suggestions-modal></app-add-mortgagee-suggestions-modal>
