import { Component, OnInit, ViewChild } from '@angular/core';
import { saveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { GetLossPayeeRequestsResponse } from 'src/app/interfaces/loss-payee/get-loss-payee-update-requests-response';
import { ModalService } from 'src/app/services/modals/modals.service';
import { MortgageeService } from 'src/app/services/mortgagee.service';
import { ContactInsurerModalComponent } from 'src/app/shared/components/modal-dialogs/contact-insurer-modal/contact-insurer-modal.component';
import { DeliveredToInsurerModalComponent } from 'src/app/shared/components/modal-dialogs/delivered-to-insurer-modal/delivered-to-insurer-modal.component';
import { ErrorMessage } from 'src/app/shared/models/error-message.enum';
import { LossPayeeUpdateRequest } from 'src/app/interfaces/loss-payee/loss-payee-update-request';

@Component({
  selector: 'app-mortgagee-update-history',
  templateUrl: './mortgagee-update-history.component.html',
  styleUrls: ['./mortgagee-update-history.component.scss']
})
export class MortgageeUpdateHistoryComponent implements OnInit {

  public static readonly rowsPerPage = 10;

  @ViewChild(ContactInsurerModalComponent) _contactInsurerModel: ContactInsurerModalComponent;
  @ViewChild(DeliveredToInsurerModalComponent) _deliveredToInsurerModel: DeliveredToInsurerModalComponent;

  public getLossPayeeRequestsResponse: GetLossPayeeRequestsResponse;
  public totalPages: number;
  public currentPage = 1;
  public isDownloadingEvidence = false;


  constructor(
    private _mortgageeService: MortgageeService,
    private _toastr: ToastrService,
    private _modalService: ModalService) { }

  ngOnInit() {
    this.getMortgageeUpdateRequests(this.currentPage, MortgageeUpdateHistoryComponent.rowsPerPage);
  }

  getMortgageeUpdateRequests(page: number, pageSize: number): void {
    this._mortgageeService.getMortgageeUpdateRequests(page, pageSize)
      .subscribe(response => {
        this.getLossPayeeRequestsResponse = response.data;
        this.totalPages = response.data.totalCount > 0 ? Math.ceil(response.data.totalCount / MortgageeUpdateHistoryComponent.rowsPerPage) : 1;
      },
        error => {
          this.totalPages = 1;
          this._toastr.error(ErrorMessage.GenericError);
        });
  }

  toggleButtonText(lossPayeeRequestId: string, isDownloadingEoi: boolean): void {
    let id = 'download-button' + lossPayeeRequestId;
    let buttonElement = document.getElementById(id);

    if (isDownloadingEoi) {
      let spinner = document.createElement('i');
      spinner.setAttribute('style', 'font-size: 24px;')
      spinner.className = 'fas fa-spinner fa-spin';
      buttonElement.appendChild(spinner);
    } else {
      buttonElement.innerHTML = 'Download';
    }

  }

  downloadEoi(lossPayeeRequestId: string): void {

    this.toggleButtonText(lossPayeeRequestId, true);

    this._mortgageeService.getUpdatedEoiByLossPayeeRequestId(lossPayeeRequestId)
      .subscribe(data => {

        if (data !== null) {

          var mimeType = data.headers.get('content-type').slice(-3);

          if (mimeType === 'pdf') {
            const blob = new Blob([data.body], { type: 'application/pdf' });
            saveAs(blob, 'EvidenceofInsurance.pdf');

          } else if (mimeType === 'zip') {
            const blob = new Blob([data.body], { type: 'application/zip' });
            saveAs(blob, 'EvidenceofInsurance.zip');

          }
          this.toggleButtonText(lossPayeeRequestId, false);
        }
      },
        error => {
          this.toggleButtonText(lossPayeeRequestId, false);
          this._toastr.error(ErrorMessage.GenericError);
        });
  }

  getSet(action: string): void {

    if (this.cannotGetNextSet(action)) {
      return;
    }

    switch (action) {
      case 'First': {
        this.currentPage = 1;
        break;
      }
      case 'Last': {
        this.currentPage = this.totalPages;
        break;
      }
      case 'Next': {
        this.currentPage++;
        break;
      }
      case 'Previous': {
        this.currentPage--;
        break;
      }
    }
    this.getMortgageeUpdateRequests(this.currentPage, MortgageeUpdateHistoryComponent.rowsPerPage);
  }

  transformOperation(operation: string): string {
    return operation === 'ReplaceAll' ? 'Replace All' : operation;
  }

  transformAddress(address1: string, address2: string): string {
    address1 = address1.replace('P.o', 'P.O');
    return address2 ? `${address1} ${address2}, ` : `${address1}, `;
  }

  cannotGetNextSet(action: string): boolean {
    return (action === 'Next' && this.currentPage + 1 > this.totalPages) || (this.currentPage === 1 && action === 'Previous');
  }

  openModal(lossPayeeRequest: LossPayeeUpdateRequest, modalId: string): void {
    this._mortgageeService.getInsurersForMortageeUpdateRequest()
      .subscribe(response => {
        var insurer =  response.data.insurers.filter(i => i.insurerId === lossPayeeRequest.insurerId)[0];
        insurer = insurer === undefined ? null : insurer;

        switch(modalId){
          case 'contact-insurance':
            this._contactInsurerModel.insurer = insurer;
            break;
          case 'delivered-to-insurer':
            this._deliveredToInsurerModel.insurer = insurer;
            this._deliveredToInsurerModel.clientEmail = lossPayeeRequest.contact.email;
            this._deliveredToInsurerModel.referenceId = lossPayeeRequest.referenceId;
            break;
        }

        this._modalService.open(modalId);
      },
      error => {
        this._toastr.error(ErrorMessage.GenericError);
        });
  }
}

