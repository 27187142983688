<div [ngClass]="'content'">
  <h1>MERS HOI Hub Services</h1>

  <div [ngClass]="'section insurance-search'">
    <button [ngClass]="'primary'" id="insurance" (click)="navigate('search')">Insurance Search</button>
    <label for="insurance"> Obtain proof of insurance using a name and a property address</label>
  </div>

  <div [ngClass]="'section mortgagee-update'" (click)="navigate('mortgagee')">
    <button [ngClass]="'primary'" id="mortgagee">Mortgagee Update</button>
    <label for="mortgagee"> Modify mortgagee details associated with a policy</label>
  </div>
</div>
