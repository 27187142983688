import { Injectable } from '@angular/core';
import { UserProfile } from '../../interfaces/users/user-profile';


@Injectable({
  providedIn: 'root',
})

export class AccountStatusService{
  
  public currentStatus = '';  

  setAccountStatus(profile: UserProfile): string {
    if (profile !== null) {
      if (profile['isRejected']) {
        this.currentStatus = 'Rejected';
      } else if (profile['isLocked']) {
        this.currentStatus = 'Locked';
      } else if (profile['isPending']) {
        this.currentStatus = 'Pending';
      } else {
        this.currentStatus = 'NoStatus';
      }
    } 
    return this.currentStatus;
  }
}
