import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  constructor(private router: Router) { }

  navigate(event: string): void {
    if (event === 'search') {
      this.router.navigate(['/search']);
    } else if (event === 'mortgagee') {
      this.router.navigate(['/loss-payee-express']);
    }
  }
}
