<div class="content">
  <h1>500 Error</h1>
  <div>
    <h2>Something went wrong</h2>
    <p>Our Apologies, but due to an error on our side, we are not able to display the page. Please try again.</p>

    <h3>For technical support contact <a href="mailto:MERSHOI.SUPPORT@ice.com">MERS HOI Hub Support</a>: </h3>
    <div class="subtitle-error-500-contact">
      <span class="fa fa-phone-alt nex-fa-span error-fa-green" aria-hidden="true"></span><span class="error-pad-left">(888) 680-6377</span> <br />
      <span class="fa fa-envelope nex-fa-span error-fa-green" aria-hidden="true"></span><span class="error-pad-left">
        <a href="mailto:MERSHOI.SUPPORT@ice.com">MERSHOI.SUPPORT@ice.com</a></span> <br />
      <span class="far fa-clock nex-fa-span error-fa-green" aria-hidden="true"></span><span class="error-pad-left">Monday-Friday</span> <span class="error-pad-left-double"> 8:00 AM - 6:00 PM ET</span>
    </div>
  </div>
</div>


