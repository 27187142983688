import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-loss-payee-success',
  templateUrl: './loss-payee-success.component.html',
  styleUrls: ['./loss-payee-success.component.scss']
})
export class LossPayeeSuccessComponent  {

  constructor(private _router: Router) { }

  returnToTransactionHistory() {
    this._router.navigate(['/my-account'], { queryParams: { setOption: 1 } });
  }

}
