import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AccountInfo } from '@azure/msal-browser';

import { UsersService } from '../../services/users/users.service';
import { UserProfile } from '../../interfaces/users/user-profile';
import { statusType } from 'src/app/interfaces/users/statusType';
import { RouteTrackerService } from '../../services/route-tracker.service';
import { MsalService } from '@azure/msal-angular';
import { lastValueFrom } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class RegistrationGuard implements CanActivate {
  public userProfile: UserProfile;
  private _user: AccountInfo;
  private _accountStatus = '';

  constructor(

    private _userService: UsersService,
    private _router: Router,
    private _msalService: MsalService,
    private _urlService: RouteTrackerService
  ) {
    this._user = _userService.getMSALUser();
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {

    if (!this._user) {
      Promise.resolve(false);
    }

    if (this._urlService.getPreviousUrl().indexOf('/loss-payee') === 0) {
      Promise.resolve(true);
    }

    return this.allowedToEnter();

  }

  private allowedToEnter(): Promise<boolean> {

    this._user = this._msalService.instance.getActiveAccount();

    //  stop the Interaction_in_progress ERROR! since this is essentially the entry point
    if (!this._user) {
      return Promise.resolve(false);
    }

    return new Promise((resolve) => {

      lastValueFrom(this._userService.fetchUserProfile()).then(
        (userProfile) => {
          this.userProfile = userProfile;
          this._accountStatus = this._userService.accountStatus;

          //  they have a B2C account, but have not completed page 2
          if (this._user.homeAccountId.length > 0 && this.userProfile === null) {
            Promise.resolve(false);
            this._router.navigate(['/account-details']);
          } else if (this.userProfile !== null) {  //  Has B2C account, is not rejected, pending, or locked, but they have not accepted Terms and Conditions
              if (this._userService.termsAndConditionsRequired !== null) {
                resolve(false);
                this._router.navigate(['/terms'], { queryParams: { termsId: this._userService.termsAndConditionsRequired.termsAndConditionsId } });
              } else {
                resolve(true);
            }
          }

          if (this._accountStatus === 'Pending' || this._accountStatus === 'Rejected' || this._accountStatus === 'Locked') {
            resolve(false);
            this._router.navigate(['/account-status'], { queryParams: { accountStatus: statusType[this._accountStatus] } });
          }
        }
      )
    });
  }
}
