import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

import { SearchComponent } from './modules/search/search.component';
import { RegistrationGuard } from './shared/guards/registration.guard';
import { AccountDetailsComponent } from './modules/account/account-details/account-details.component';
import { AccountStatusComponent } from './modules/account/account-status/account-status.component';
import { ErrorComponent } from './modules/error/error.component';
import { MyAccountComponent } from './modules/account/my-account/my-account.component';
import { TermsComponent } from './modules/terms/terms.component';
import { Error500Component } from './modules/error/500/error500.component';
import { Error400Component } from './modules/error/404/error400.component';
import { HelpComponent } from './modules/help/help.component';
import { LossPayeeComponent } from './modules/loss-payee/loss-payee.component';
import { LossPayeeRequestComponent } from './modules/loss-payee/loss-payee-request/loss-payee-request.component';
import { LossPayeeSuccessComponent } from './modules/loss-payee/loss-payee-success/loss-payee-success.component';
import { MyAccountGuard } from './shared/guards/my-account.guard';
import { TermsAndConditionsGuard } from './shared/guards/terms.guard';
import { PaymentResolver } from './shared/resolvers/payment.resolver';
import { HomeComponent } from './modules/home/home.component';
import { LossPayeeExpressComponent } from './modules/loss-payee/loss-payee-express/loss-payee-express.component';
import { LossPayeeConfirmComponent } from './modules/loss-payee/loss-payee-express/loss-payee-confirm/loss-payee-confirm.component';
import { LossPayeeExpressGuard } from './shared/guards/loss-payee-express.guard';
import { CompleteSetupGuard } from './shared/guards/complete-setup.guard';
import { LossPayeeGuard } from './shared/guards/loss-payee.guard';


export const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full', data: { title: 'Home' } },
  { path: 'state', redirectTo: 'home', data: { title: 'Home' } },
  { path: 'home', pathMatch: 'full', component: HomeComponent, canActivate: [MsalGuard, RegistrationGuard, LossPayeeExpressGuard], data: { title: 'Home' } },
  { path: 'search', component: SearchComponent, canActivate: [RegistrationGuard],  data: { title: 'Search' }, resolve: { payment: PaymentResolver } },
  { path: 'account-details', pathMatch: 'full', component: AccountDetailsComponent, canActivate: [MsalGuard], data: { title: 'Account-Details' } },
  { path: 'my-account', component: MyAccountComponent, canActivate: [MsalGuard, MyAccountGuard], data: { title: 'My Account' } },
  { path: 'error', pathMatch: 'full', component: ErrorComponent, data: { title: 'Error' } },
  { path: '500', pathMatch: 'full', component: Error500Component, data: { title: 'Error500' } },
  { path: '400', pathMatch: 'full', component: Error400Component, data: { title: 'Error400' } },
  { path: 'account-status', pathMatch: 'full', component: AccountStatusComponent, data: { title: 'Account-Status' } },
  { path: 'terms', pathMatch: 'full', component: TermsComponent, canActivate: [TermsAndConditionsGuard], data: { title: 'Terms & Conditions' } },
  { path: 'help', pathMatch: 'full', component: HelpComponent, data: { title: 'Help' } },
  // eslint-disable-next-line max-len
  { path: 'loss-payee', pathMatch: 'full', canActivate: [LossPayeeGuard, CompleteSetupGuard], component: LossPayeeComponent, data: { title: 'Loss Payee', featureFlag: 'losspayee' } },
   // eslint-disable-next-line max-len
  { path: 'loss-payee-express', pathMatch: 'full', canActivate: [LossPayeeExpressGuard, RegistrationGuard], component: LossPayeeExpressComponent, data: { title: 'Loss Payee Express' } },
  // eslint-disable-next-line max-len
  { path: 'update-mortgagee', pathMatch: 'full', canActivate: [LossPayeeGuard], component: LossPayeeRequestComponent, data: { title: 'Update Mortgagee', featureFlag: 'losspayee' } },
  // eslint-disable-next-line max-len
  { path: 'loss-payee-success', pathMatch: 'full', canActivate: [LossPayeeGuard], component: LossPayeeSuccessComponent, data: { title: 'Loss Payee Success', featureFlag: 'losspayee'}},
  // eslint-disable-next-line max-len
  { path: 'loss-payee-confirm', pathMatch: 'full', canActivate: [LossPayeeExpressGuard], component: LossPayeeConfirmComponent, data: { title: 'Loss Payee Express Success', featureFlag: 'losspayee' } },

  { path: '**', component: Error400Component }// needs to be last route

];

const routerOptions: ExtraOptions = {
  useHash: true
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
