import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { RouteTrackerService } from '../../services/route-tracker.service';



@Injectable({
  providedIn: 'root'
})

export class CompleteSetupGuard implements CanActivate {

  constructor(private _urlService: RouteTrackerService) { }

  canActivate(): boolean {
        return this._urlService.getPreviousUrl().indexOf('/terms') === 0;
    }

}
