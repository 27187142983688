import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContactAndConsent } from '../../../interfaces/loss-payee-express/contact-and-consent';
import { MortgageeDetails } from '../../../interfaces/loss-payee-express/mortgagee-details';
import { PolicyInformation } from '../../../interfaces/loss-payee-express/policy-information';
import { CreateMortgageeRequest } from '../../../interfaces/mortgagee/create-mortgagee-request';
import { LossPayeeService } from '../../../services/loss-payee/loss-payee.service';
import { MortgageeService } from '../../../services/mortgagee.service';
import { ModalService } from 'src/app/services/modals/modals.service';
import { ContactAndConsentComponent } from './contact-and-consent/contact-and-consent.component';
import { MortgageeDetailsComponent } from './mortgagee-details/mortgagee-details.component';
import { PolicyInformationComponent } from './policy-information/policy-information.component';
import { AddMortgageeSuggestionsModalComponent } from 'src/app/shared/components/modal-dialogs/add-mortgaee-suggestion-modal/add-mortgagee-suggestions-modal.component';
import { MortgageeViewModel } from '../../../interfaces/loss-payee/mortgagee-view-model';
import { take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { ErrorMessage } from '../../../shared/models/error-message.enum';

enum Screens {
  policyDetails,
  mortgageeDetails,
  contactAndConsent,
  reviewAndSubmit
}

@Component({
  selector: 'app-loss-payee-express',
  templateUrl: './loss-payee-express.component.html',
  styleUrls: ['./loss-payee-express.component.scss']
})
export class LossPayeeExpressComponent implements AfterViewInit {
  @ViewChild(AddMortgageeSuggestionsModalComponent) _mortgageeSuggestionsModal: AddMortgageeSuggestionsModalComponent;
  @ViewChild(PolicyInformationComponent) childPolicy: PolicyInformationComponent;
  @ViewChild(MortgageeDetailsComponent) childMortgagee: MortgageeDetailsComponent;
  @ViewChild(ContactAndConsentComponent) childContact: ContactAndConsentComponent;


  private _loss: LossPayeeService;
  private _mortgageeService: MortgageeService;
  private _modalService: ModalService;
  private _toastr: ToastrService;
  private _isReady = false;

  screenArray: Array<number> = [Screens.policyDetails, Screens.mortgageeDetails, Screens.contactAndConsent, Screens.reviewAndSubmit];
  activeScreen = Screens.policyDetails;
  policyDetails = {} as PolicyInformation;
  mortgageeDetails = {} as MortgageeDetails;
  contactDetails = {} as ContactAndConsent;
  newMortgagee = {} as CreateMortgageeRequest;
  isReviewing = false;
  addingNewMortgagee = false;

  constructor(
    lossPayeeService: LossPayeeService,
    mortgageeService: MortgageeService,
    modalService: ModalService,
    toasterService: ToastrService,
    private _router: Router) {

    this._loss = lossPayeeService;
    this._mortgageeService = mortgageeService;
    this._modalService = modalService;
    this._toastr = toasterService;
  }

  ngAfterViewInit(): void {
    this._isReady = true;
  }

  next() {
    this.isReviewing = this.activeScreen === Screens.reviewAndSubmit;
    if (this.activeScreen < Screens.reviewAndSubmit) {
      this.navigateScreens(this.activeScreen);
    }
  }

  cancelOrBack() {
    if (this.activeScreen > 0) {
      this.activeScreen -= 1;
      this.getContentForScreen(this.activeScreen);
    } else if (this.activeScreen === 0) {
      this.navigateTo();
    }

    if (this.activeScreen === 3) {
      this.isReviewing = true;
    }
  }

  navigateTo(): void {
    this._router.navigate(['/home']);
  }

  changeScreen(screen: number) {
    this.isReviewing = false;
    this.activeScreen = screen;
    this.getContentForScreen(this.activeScreen);
    console.log('Screen changed to ' + screen);
  }

  isPolicyFormValid(): boolean {
    switch (this.activeScreen) {
      case Screens.policyDetails:
        return this.childPolicy ? this._loss.validateForm(this.childPolicy.policyForm) : false;

      case Screens.mortgageeDetails:
        if (!this.childMortgagee) return false;
        if (!this.childMortgagee.creatingNewMortgagee) {
          this._loss.removeUnusedFormFields(this.childMortgagee.mortgageeForm);
          this.addingNewMortgagee = false;
        } else {
          this.addingNewMortgagee = true;
        }
        return this._loss.validateForm(this.childMortgagee.mortgageeForm);

      case Screens.contactAndConsent:
        return this.childContact ? this._loss.validateForm(this.childContact.consentForm) : false;

      default:
        return false;
    }
  }

  private navigateScreens(screen: number) {

    switch (this.activeScreen) {

      case Screens.policyDetails:
        this.processPolicyForm();
        break;

      case Screens.mortgageeDetails:
        this.processMortgageeForm();
        break;

      case Screens.contactAndConsent:
        this.processContactAndConsent();
        break;

      default:
        break;

    }

  }

  private processContactAndConsent() {
    if (this._loss.validateForm(this.childContact.consentForm)) {
      this.saveScreenDetails(this.activeScreen);
      this.activeScreen += 1;
    } else {
      this.childContact.consentForm = this._loss.markFormTouched(this.childContact.consentForm);
    }
  }

  private processPolicyForm() {
    if (this._loss.validateForm(this.childPolicy.policyForm)) {
      this.saveScreenDetails(this.activeScreen);
      this.activeScreen += 1;
    } else {
      this.childPolicy.policyForm = this._loss.markFormTouched(this.childPolicy.policyForm);
    }
  }

  private processMortgageeForm(): void {
    if (!this.childMortgagee.creatingNewMortgagee) {
      this._loss.removeUnusedFormFields(this.childMortgagee.mortgageeForm);
      this.addingNewMortgagee = false;
    } else {
      this.addingNewMortgagee = true;
    }

    if (this._loss.validateForm(this.childMortgagee.mortgageeForm)) {
      if (this.addingNewMortgagee) {
        this.submit();
      } else {
        this.saveScreenDetails(this.activeScreen);
        this.activeScreen += 1;
      }

    } else {
      this.childMortgagee.mortgageeForm = this._loss.markFormTouched(this.childMortgagee.mortgageeForm);
    }
  }

  private getContentForScreen(activeScreen: number) {

    if (this._isReady) {
      switch (activeScreen) {
        case Screens.policyDetails:
          this.policyDetails = this._loss.policyInformation;
          break;
        case Screens.mortgageeDetails:
          this.mortgageeDetails = this._loss.mortgageeDetails;
          if (this._loss.addingNewMortgagee === true) {
            this.addingNewMortgagee = true;
            this.newMortgagee = this._loss.newMortgagee;
          }
          break;
        case Screens.contactAndConsent:
          this.contactDetails = this._loss.contactAndConsent;
          break;

        default:
          break;
      }

    }

  }

  private saveScreenDetails(screen: number) {

    switch (screen) {
      case Screens.policyDetails:
        this.childPolicy.mapFormToModel();
        break;
      case Screens.mortgageeDetails:
        this.childMortgagee.mapFormToModel();
        break;
      case Screens.contactAndConsent:
        this.childContact.mapFormToModel();
        break;

      default:
        break;

    }

  }


  private submit(): void {

    if (!this.childMortgagee.mortgageeForm.valid) {
      this._loss.markFormTouched(this.childMortgagee.mortgageeForm);
      return;
    }

    let addedMortgagee = this.mapMortgageeFormToModel();
    this._mortgageeService.getMortgageesByMortgageeRequest(addedMortgagee).pipe(take(1))
      .subscribe(response => {
        let exactMatch = this._mortgageeService.findExactMortgageeMatch(response.data.mortgagees, addedMortgagee);
        if (exactMatch !== null && exactMatch !== undefined) {
          this.childMortgagee.clearInternalForm();
          this.addingNewMortgagee = false;
          let mortgagee = this.childMortgagee.mapMortgageeResponseToString(exactMatch);
          this.childMortgagee.mortgageeForm.get('mortgagee').setValue(mortgagee);
          this.childMortgagee.setMortgageeId(exactMatch.mortgageeId.toString());
          this.saveScreenDetails(this.activeScreen);
          this.activeScreen += 1;
        } else {
          // open modal
          this.setUpSuggestionModal(addedMortgagee, response.data.mortgagees);
          this._modalService.open('add-mortgagee-suggestions');
        }
      },
        error => {
          this._toastr.error(ErrorMessage.GenericError);
        });

  }

  private setUpSuggestionModal(addedMortgagee: CreateMortgageeRequest, mortgagees: MortgageeViewModel[]) {
    this._mortgageeSuggestionsModal.addedMortgagee = addedMortgagee;
    this._mortgageeSuggestionsModal.mortgagees = mortgagees;
    this._mortgageeSuggestionsModal.modalTitle = 'Update Mortgagee';
    this._mortgageeSuggestionsModal.isFromLossPayeeExpressPath = true;
    this._mortgageeSuggestionsModal.lossPayeeRequestComponent = this;
  }

  private mapMortgageeFormToModel(): CreateMortgageeRequest {
    /*  inner Mortgagee form  */
    let mortgagee = {} as CreateMortgageeRequest;
    mortgagee.name = this.childMortgagee.mortgageeForm.get('addMortgagee').value;
    mortgagee.action = 'Add';
    mortgagee.address1 = this.childMortgagee.mortgageeForm.get('address1').value;
    mortgagee.address2 = this.childMortgagee.mortgageeForm.get('address2').value;
    mortgagee.city = this.childMortgagee.mortgageeForm.get('city').value;
    mortgagee.state = this.childMortgagee.mortgageeForm.get('state').value;
    mortgagee.zip = this.childMortgagee.mortgageeForm.get('zip').value;

    return mortgagee;
  }
}
