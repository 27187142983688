import { Pipe, PipeTransform } from '@angular/core';
import { Person } from '../../interfaces/search/person';

@Pipe({
  name: 'person'
})
export class PersonPipe implements PipeTransform {

  public transform(value: Person): string {
    const personString =
      value.middleInitial !==null ? `${value.firstName} ${value.lastName} ${value.middleInitial}`
           : value.suffix !==null ? `${value.firstName} ${value.lastName} ${value.suffix}`
           : (value.middleInitial !== null && value.suffix !== null) ? `${value.firstName} ${value.lastName} ${value.middleInitial} ${value.suffix}`
           : `${value.firstName} ${value.lastName}`;

    return personString;

  }

}
