import { AddressViewModel } from 'src/app/interfaces/address/addressViewModel';

export enum policyTypeCode {
  ERRONEOUS = -1,
  HOME = 0,
  CONDO = 1,
  MASTER = 2,
  FLOOD = 3,
  HURRICANE = 4,
  WIND = 5,
  EARTHQUAKE = 6,
  MINE = 7,
  BRUSHFIRE = 8,
  LANDLORD = 9,
  MANUFACTURED = 10,
  RENTERS = 11
}

export interface BriefSearchRequest {
  policyTypeCode: policyTypeCode;
  firstName: string;
  lastName: string;
  middleInitial: string;
  suffix: string;
  propertyAddress: AddressViewModel;
}
