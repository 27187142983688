import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { FeatureFlagService } from '../../services/feature-flag.service';
import { RouteTrackerService } from '../../services/route-tracker.service';

@Injectable({
  providedIn: 'root'
})

export class LossPayeeGuard implements CanActivate {

  private readonly _featureName = 'losspayee'

  constructor(
    private _featureFlagService: FeatureFlagService,
    private _router: Router,
    private _urlService: RouteTrackerService
  ) { }


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean>  {

    return this._featureFlagService.isFeatureEnabled(this._featureName)
      .then(f => {
        if (f === false && this._urlService.hasFilteredUrl()) {
          this._router.navigateByUrl('search');
          return Promise.resolve(true);
        }
        else if (f === false) {
          this._router.navigateByUrl('Error400')
          return Promise.resolve(false);
        }

        return Promise.resolve(true);
      });

  }

}
