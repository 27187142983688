import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { BriefSearchResponse } from '../../../interfaces/search/brief-search-response';
import { BriefSearchRequest } from '../../../interfaces/search/brief-search-request';
import { BriefDetailsResponse } from '../../../interfaces/search/brief-search-details-response';


@Injectable({
  providedIn: 'root'
})

export class BriefPolicySearchDataEvents {

  private fileUploadCompleted__ = new BehaviorSubject<boolean>(null);
  public fileUploadCompleted_ = this.fileUploadCompleted__.asObservable();

  private briefpolicySearch = new BehaviorSubject<BriefSearchResponse>(null);
  public briefPolicySearch = this.briefpolicySearch.asObservable();

  private briefDetailsSearchResults__ = new BehaviorSubject<BriefDetailsResponse>(null);
  public briefDetailsSearchResults_ = this.briefDetailsSearchResults__.asObservable();

  private insuranceSearch__ = new BehaviorSubject<BriefSearchRequest>(null);
  public propertySearched_ = this.insuranceSearch__.asObservable();


  public updateBriefPolicySearch(lightBirefSearch: BriefSearchResponse): void {
    this.briefpolicySearch.next(lightBirefSearch);
  }

  public updateAddressSearched(propertySearched: BriefSearchRequest): void {
    this.insuranceSearch__.next(propertySearched);
  }

  public updateFileUploadCompleted(fileUploadCompleted: boolean): void {
    this.fileUploadCompleted__.next(fileUploadCompleted);
  }

  public updateBriefDetailsSearchResults(briefDetailsSearchResults: BriefDetailsResponse): void {
    this.briefDetailsSearchResults__.next(briefDetailsSearchResults);
  }

  public resetSearch(): void {
    this.fileUploadCompleted__.next(false);
  }
}
