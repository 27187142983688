import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PaymentMethod } from '../../../interfaces/payment/get-payment-method-request';
import { PaymentMethodViewModel } from '../../../interfaces/payment/get-payment-method-view-model-request';

@Injectable({
  providedIn: 'root'
})
export class PaymentDataEvents {

  private addPaymentMethodIFrame__ = new BehaviorSubject<boolean>(false);
  public addPaymentMethodIFrame_ = this.addPaymentMethodIFrame__.asObservable();

  private paymentHasBeenAdded__ = new BehaviorSubject<boolean>(false);
  public paymentHasBeenAdded_ = this.paymentHasBeenAdded__.asObservable();

  private paymentHasBeenDeleted__ = new BehaviorSubject<boolean>(false);
  public paymentHasBeenDeleted_ = this.paymentHasBeenDeleted__.asObservable();

  private paymentMethodDeleteObject__ = new BehaviorSubject<PaymentMethodViewModel>(null);
  public paymentMethodDeleteObject_ = this.paymentMethodDeleteObject__.asObservable();

  private paymentHasBeenCancelled__ = new BehaviorSubject<boolean>(false);
  public paymentHasBeenCancelled_ = this.paymentHasBeenCancelled__.asObservable();

  private paymentOpenAfterRedirect__ = new BehaviorSubject<boolean>(false);
  public paymentOpenAfterRedirect_ = this.paymentOpenAfterRedirect__.asObservable();

  private paymentMethod__ = new BehaviorSubject<PaymentMethod>(null);
  public paymentMethod_ = this.paymentMethod__.asObservable();


  public paymentMethodAdded(paymentHasBeenAdded: boolean): void {
    this.paymentHasBeenAdded__.next(paymentHasBeenAdded);
  }

  public paymentMethodDeleted(paymentHasBeenDeleted: boolean): void {
    this.paymentHasBeenDeleted__.next(paymentHasBeenDeleted);
  }

  public paymentMethodDeleteObject(paymentMethodDeleteObject: PaymentMethodViewModel): void {
    this.paymentMethodDeleteObject__.next(paymentMethodDeleteObject);
  }

  public paymentMethodCancelled(paymentHasBeenCancelled: boolean): void {
    this.paymentHasBeenCancelled__.next(paymentHasBeenCancelled);
  }

  public paymentOpenAfterRedirect(paymentModalShouldOpen: boolean): void {
    this.paymentOpenAfterRedirect__.next(paymentModalShouldOpen);
  }

  public updatePaymentMethodIFrame(addPaymentMethodIFrame: boolean): void {
    this.addPaymentMethodIFrame__.next(addPaymentMethodIFrame);
  }

  public updatePaymentMethod(paymentMethod: PaymentMethod): void {
    this.paymentMethod__.next(paymentMethod);
  }

}
