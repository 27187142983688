import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { ModalService } from '../../../services/modals/modals.service';
import { PaymentDataEvents } from '../payment-data.events/payment-data.events';


@Component({
  selector: 'app-no-payment-method',
  templateUrl: './no-payment-method.component.html',
  styleUrls: ['./no-payment-method.component.scss']
})
export class NoPaymentMethodComponent {

  constructor(
    private _modalService: ModalService,
    private _paymentDataEvents: PaymentDataEvents,
    private _router: Router) {
  }

  onAddPaymentMethodClick() {
    this._router.navigate(['/my-account'], { queryParams: { openAfterRoute: 'true' } });

  }

  closeModal(id: string) {
    this._modalService.close(id);
  }
}
