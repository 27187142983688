import { Injectable, Inject } from '@angular/core';
import { FileSizeUnit } from '../file-size-unit';

@Injectable()
export class FileValidator {
  private readonly _acceptedFileTypes: string;
  public readonly maxFileSize: number;
  public readonly maxFileSizeUnit: FileSizeUnit;

  constructor(@Inject(String) acceptedFileTypes: string, @Inject(Number) maxFileSize: number = 50, @Inject(String) maxFileSizeUnit: FileSizeUnit = FileSizeUnit.MB) {
    this._acceptedFileTypes = acceptedFileTypes;
    this.maxFileSize = maxFileSize;
    this.maxFileSizeUnit = maxFileSizeUnit;
  }

  public isValidFileType(file: File): boolean {
    const fileExtension = file.name.match(/\.([^\.]+)$/)[1].toLocaleLowerCase();

    return this._acceptedFileTypes.indexOf(fileExtension) > -1;
  }

  public isValidFileSize(file: File): boolean {
    const fileSizeUnit = this.getFileSizeUnitIndex(file.size);

    if (fileSizeUnit > this.maxFileSizeUnit) {
      return false;
    } else if (fileSizeUnit === this.maxFileSizeUnit) {
      return !this.isFileSizeGreaterThanMaxFileSize(file.size, fileSizeUnit);
    } else {
      return true;
    }
  }

  public transformFileSize(fileSize: number): string {
    if (fileSize === 0) {
      return '0 Byte';
    }
    const fileSizeUnit = this.getFileSizeUnitIndex(fileSize);
    return this.getFileSize(fileSize, fileSizeUnit) + ' ' + FileSizeUnit[fileSizeUnit];
  }

  private getFileSizeUnitIndex(fileSize: number): number {
    return Math.floor(Math.log(fileSize) / Math.log(1024));
  }

  private isFileSizeGreaterThanMaxFileSize(fileSize: number, fileSizeUnit: number): boolean {
    return +this.getFileSize(fileSize, fileSizeUnit) > this.maxFileSize;
  }

  private getFileSize(fileSize: number, fileSizeUnit: number): string {
    return (fileSize / Math.pow(1024, fileSizeUnit)).toFixed(2);
  }
}
