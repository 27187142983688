<app-custom-modal id="deletePaymentMethod" class="modal nex-modal">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2>Delete Payment Method</h2>
      </div>
      <div class="modal-body">
        <p>Are you sure you want to delete the payment method
          <span class="delete-payment-highlighted">{{containsPaymentToDelete === true ? paymentToDelete.nickname : ''}}</span>?
        </p>
      </div>
    </div>
    <div class="modal-action">
      <div class="input-btn">
        <button routerLinkActive="router-link-active"
                type="reset"
                role="button"
                aria-label="Cancel"
                (click)="closeModal('deletePaymentMethod')">
          Cancel
        </button>
        <button routerLinkActive="router-link-active"
                type="submit"
                role="button"
                aria-label="Delete Payment Method"
                (click)="onDeletePaymentMethodClick()">
          Delete Payment Method
        </button>
      </div>
    </div>
  </div>
</app-custom-modal>
