import { OnInit, Component, Output, EventEmitter } from '@angular/core';
import { ModalService } from '../../services/modals/modals.service';
import { UserProfile } from '../../shared/models/user-profile';
import { UsersService } from '../../services/users/users.service';
import { BriefPolicySearchDataEvents } from '../search/policy-search-data.events/policy-search-data.events';
import { PaymentMethod } from '../../interfaces/payment/get-payment-method-request';
import { BriefSearchResponse } from '../../interfaces/search/brief-search-response';

@Component({
  selector: 'app-client-consent',
  templateUrl: './client-consent.component.html',
  styleUrls: ['./client-consent.component.scss']
})

export class ClientConsentComponent implements OnInit {
  @Output() clientConsentUploaded = new EventEmitter<boolean>();

  public paymentMethod: PaymentMethod;
  public userProfile: UserProfile;
  public hasPaymentMethod = false;
  public searchResults: BriefSearchResponse;

  constructor(
    private _dataEvents: BriefPolicySearchDataEvents,
    private _userService: UsersService,
    private _modalService: ModalService) { }

  ngOnInit(): void {
    this._dataEvents.briefPolicySearch.subscribe(searchResults => {
      this.searchResults = searchResults;
    });
  }

  onClientConsentOpenClick(): void {
    if (this._userService.userHasPaymentMethod() === true ) {
      this.removeModal('paymentMethod');
      this._modalService.open('clientConsent');
    } else {
      this.removeModal('clientConsent');
      this._modalService.open('noPaymentMethod');
    }
  }

  removeModal(id: string): void {
    let modal = document.getElementById(id);
    if (modal !== null) {
      modal.remove();
    }
  }

  onAddPaymentMethodClick(): void {
    this._modalService.open('paymentMethod');
  }

  closeModal(id: string): void {
    this._modalService.close(id);
  }

}
