import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Form } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { PaymentMethodSuccessResponse } from '../../../../interfaces/payment/payment-method-success-response';
import { UsersService } from '../../../../services/users/users.service';
import { ErrorMessage } from '../../../../shared/models/error-message.enum';
import { PaymentMethodService } from '../../../../services/payment/payment.method.service';
import { PaymentDataEvents } from '../../../../modules/payment/payment-data.events/payment-data.events';
import { environment } from '../../../../../environments/environment';
import { ModalService } from '../../../../services/modals/modals.service';
import { PaymentMethodComponent } from '../../../../modules/payment/payment-method/payment-method.component';


declare let HostedForm: Function;

@Component({
  selector: 'app-payment-method-modal',
  templateUrl: './payment-method-modal.component.html',
  styleUrls: ['./payment-method-modal-component.css']
})
export class PaymentMethodModalComponent implements OnInit, OnDestroy {
  @ViewChild(PaymentMethodComponent) _paymentMethodComponent: PaymentMethodComponent;

  private _subs: Subscription[] = [];

  public paymentMethodForm: FormGroup;
  public hostedForm = {};
  public readonly paymentFormId = 'payment-form';
  public isHostFormConfigured = false;

  public _isHostedFormConfigured = false;

  constructor(
    private _formBuilder: FormBuilder,
    private _toastrService: ToastrService,
    private _userService: UsersService,
    private _paymentMethodService: PaymentMethodService,
    private _paymentDataEvents: PaymentDataEvents,
    private _modalService: ModalService  ) { }

  ngOnInit(): void {
    this.paymentMethodForm = this.buildPaymentMethodFormGroup();
  }

  ngOnDestroy(): void {
    this.destroyHostedPaymentMethodForm();

    if (this._subs && this._subs.length > 0) {
      while (this._subs.length > 0) {
        let sub = this._subs.pop();
        sub.unsubscribe();
        sub = null;
      }
    }
  }

  public configurePaymentMethod(): void {
    this._paymentMethodComponent.destroyHostedPaymentMethodForm();
    this._paymentMethodComponent.mountHostedPaymentMethodForm();
  }

  public closeModal(id: string): void {
    this._modalService.close(id);
  }

  public get isHostedPaymentMethodFormConfigured(): boolean {
    return this.isHostFormConfigured;
  }

  public destroyHostedPaymentMethodForm(): void {
    this.paymentMethodForm.get('cardNickname').setValue(null);
    this._paymentMethodComponent.destroyHostedPaymentMethodForm();
  }

  public mountHostedPaymentMethodForm(): void {
    try {
      this.hostedForm['frame'] = this.configureHostedPaymentForm();
      this.hostedForm['frame'].mount();
      this.syncPaymentMethodIFrame(true);

    } catch (error) {
      this.syncPaymentMethodIFrame(false);
      this.destroyHostedPaymentMethodForm();
      this._toastrService.error(ErrorMessage.GenericError);
    }
  }

  public syncPaymentMethodIFrame(isHosted: boolean) {
    this._paymentDataEvents.updatePaymentMethodIFrame(isHosted);
  }

  private buildPaymentMethodFormGroup(): FormGroup {
    return this._formBuilder.group({
      cardNickname: new FormControl(null)
    });
  }

  private configureHostedPaymentForm(): Function {
    const paymentService = this._paymentMethodService;
    const paymentMethodTransformer = this._paymentMethodService.transform;
    const toastFactoryService = this._toastrService;
    const paymentMethodForm = this.paymentMethodForm;

    let userId: string;

    this._userService
      .userProfile_()
      .subscribe(userProfile => (userId = userProfile.userId));

    const config = {
      apiKey: environment.payment.apiKey,
      showLabels: true,
      id: `#${this.paymentFormId}`,
      autoSubmit: false,
      showMessages: true,
      buttonGroupAlignment: 'right',
      submitButtonText: 'Add Payment Option',
      showCancelButton: true,
      onCancel() {
        paymentService.cancelPayment();
      },
      onSuccess(paymentMethodResponse: PaymentMethodSuccessResponse): void {
        let cardName = paymentMethodForm.get('cardNickname').value;
        const transformedPaymentMethod = paymentMethodTransformer(paymentMethodResponse, cardName);
        transformedPaymentMethod.userId = userId;
        paymentService.addPaymentMethod(transformedPaymentMethod).subscribe();
      },
      onError(response: { message: string, errors: any }): void {
        const preMessage = 'We are currently unable to process your request. Please email us at MERSHOI.SUPPORT@ice.com or call (888) 680-6377';

        switch (response.message) {
          case 'Unable to determine cardtype':
            toastFactoryService.error(`${preMessage} Please enter a valid card number and try again.`);
            break;

          case 'Invalid expdate':
            toastFactoryService.error(`${preMessage} Please check your card number and try again.`);
            break;

          case 'LUHN/MOD10 check on account number failed':
            toastFactoryService.error(preMessage);
            break;

          case 'INVALID ZIPCODE. NOT VALID FOR US OR CANADA.':
            toastFactoryService.error(`${preMessage} Please enter a valid zip code and try again.`);
            break;

          default:
            toastFactoryService.error(preMessage);
            break;
        }

      },
      styles: this.hostedPaymentMethodFormStyles
    };

    return HostedForm(config);
  }

  private get hostedPaymentMethodFormStyles(): object {
    const primaryColor = '#5c7909';
    const primaryVariantColor = '#516b08';
    const errorColor = '#B00020';

    return {
      base: {
        fontSize: 16,
        lineHeight: 1.5,
        padding: '0 5px',
        class: 'boris123'
      },
      error: {
        display: 'block',
        background: '#E3342F',
        fontSize: 14,
        color: 'white',
        padding: '7px 19px',
        textAlign: 'center',
        borderRadius: '4px',
        marginBottom: 3
      },
      success: {
        display: 'block',
        background: '#38C172',
        fontSize: 14,
        color: 'white',
        padding: '7px 19px',
        textAlign: 'center',
        borderRadius: '4px',
        marginBottom: 3
      },
      cancel: {
        display: 'block',
        background: 'white',
        color: '#5d8119',
        fontsize: 14,
        padding: '10px 20px 10px 20px',
        border: '1px solid #5d8119',
        cursor: 'pointer',
        font: 'weight: bold',
        borderRadius: '4px',
        marginRight: '10px'
      },
      formGroup: {
        display: 'block',
        padding: '3px 0',
        borderRadius: '4px',
        marginBottom: 3
      },
      label: {
        display: 'block',
        color: '#6a8c0a',
        margin: '0',
        fontWeight: 700,
        fontSize: 18
      },
      helpText: {
        fontSize: 13,
        fontStyle: 'italic',
        color: '#aaa'
      },
      helpError: {
        fontSize: 16,
        color: errorColor,
        fontWeight: 400,
        lineHeight: 1.4
      },
      input: {
        display: 'block',
        width: '100% !important',
        height: 'calc(2.25rem + 2px)',
        padding: '0.375rem 0.75rem',
        lineHeight: '1.5',
        color: '#495057',
        backgroundClip: 'padding-box',
        borderRadius: '0.25rem',
        transition:
          'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
        fontSize: '1rem',
        fontWeight: '400',
        border: 'solid 1px #555759',
        boxShadow: 'none',
        margin: '10px 0 7px',
        fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',

        ':focus': {
          border: 'solid 1px #8ebc0d',
          boxShadow:
            'inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(142, 188, 13, 0.6)',
          outline: 'none'
        },
        ':active': {
          border: 'solid 1px #8ebc0d',
          boxShadow:
            'inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(142, 188, 13, 0.6)',
          outline: 'none'
        }
      },
      button: {
        backgroundColor: primaryColor,
        color: 'white',
        borderRadius: '4px',
        padding: '10px 30px',
        margin: '35px 0',
        width: '100%',
        height: '50%',
        fontSize: 18,
        ':hover': {
          backgroundColor: primaryVariantColor
        },
        ':focus': {
          backgroundColor: primaryVariantColor,
          outline: 'none'
        },
        ':active': {
          backgroundColor: primaryVariantColor
        }
      }
    };
  }

  private destroyHostedForm() {
    let element = document.getElementById('payment-form');
    while (element.firstChild) {
      element.removeChild(element.firstChild);
    }
  }

}
