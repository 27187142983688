import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router, ActivatedRoute, CanActivate } from '@angular/router';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { ApiResponse } from '../../interfaces/api/apiResponse';
import { environment } from '../../../environments/environment';
import { GetMortgageesResponse } from '../../interfaces/loss-payee/get-mortgagees-response';
import { MortgageesSearchModel } from './mortgagees-search-model';
import { MortgageeRequestComponent } from './mortgagee-request/mortgagee-request.component';
import { BriefPolicySearchDataEvents } from '../search/policy-search-data.events/policy-search-data.events';
import { UserProfile } from '../../interfaces/users/user-profile';
import { UsersService } from '../../services/users/users.service';
import { MortgageeService } from '../../services/mortgagee.service';
import { ModalService } from '../../services/modals/modals.service';


@Component({
  selector: 'app-loss-payee',
  templateUrl: './loss-payee.component.html',
  styleUrls: ['./loss-payee.component.scss']
})

export class LossPayeeComponent implements OnInit {

  #_httpClient: HttpClient;
  #_mortgageeRequest: MortgageeRequestComponent;
  #_userProfile: UserProfile;
  private _userService: UsersService;

  subs: Subscription[] = [];
  isSearching = false;
  itemSelected = false;
  showAllRecords = false;
  mortgagees: ApiResponse<GetMortgageesResponse>;
  noMortgageesFound = false;
  searchValue = '';
  totalRecordsInCurrentSearch = 0;
  fullListBuilt = false;
  inError = false;
  requestSent = false;
  searchString: string;
  mortgageeAdded = false;

  // The eight variables below are for use in the MortgageeRequest component
  businessName = '';
  businessAddress1 = '';
  businessAddress2 = '';
  businessCity = '';
  businessState = '';
  businessZip = '';
  records = 0;
  showAll = false;

  constructor(
    httpClient: HttpClient,
    private _router: Router,
    private route: ActivatedRoute,
    private _mortgageeService: MortgageeService,
    userService: UsersService,
    private _toastr: ToastrService,
    private _modalService: ModalService
  ) {
    this.#_httpClient = httpClient;
    this._userService = userService;
    this.subscribeToMortgageeService();
  }


  ngOnInit(): void {
    this.#_userProfile = {} as UserProfile;

    this._userService.fetchUserProfile().subscribe(x => {
    });
  }


  search() {
    this.requestSent = false;
    this.itemSelected = false;
    this.inError = false;

    if (this.showAllRecords) {
      this.clearResultsList();
      this.showAllRecords = false;
    }

    this.searchValue = (<HTMLInputElement>document.getElementById('searchBox')).value;
    const MINIMUM_CHARACTERS_FOR_SEARCH = 2;

    let options = {
      params: new HttpParams()
        .set('page', '1')
        .set('pageSize', '10')
        .set('name', this.searchValue)
    }

    if (this.searchValue.length > MINIMUM_CHARACTERS_FOR_SEARCH) {
      this.#_httpClient.get<ApiResponse<GetMortgageesResponse>>(`${environment.portalApiUrl}v1/mortgagees`, options).pipe(
        distinctUntilChanged(),
      ).subscribe(result => {
        this.mortgagees = result;
        this.totalRecordsInCurrentSearch = result.data.totalCount;
        if (result.data.totalCount === 0) {
          this.noMortgageesFound = true;
          this.businessName = this.searchValue;
          this.records = 0;
          this.isSearching = false;
        } else {
          this.noMortgageesFound = false;
          this.businessName = this.searchValue;
        }
        this.isSearching = true;
      })
    } else {
      this.isSearching = false;
    }

  }

  getAllMortgagees() {

    let searchTerm = (<HTMLInputElement>document.getElementById('searchBox')).value;

    if (searchTerm.length === 0) {
      this.inError = true;
      return;
    }

    if (this.showAllRecords === true) {
      return;
    }

    if (this.totalRecordsInCurrentSearch === 0) {
      return;
    }

    this.showAllRecords = true;
    this.itemSelected = false;
    this.noMortgageesFound = false;


    let getMortgageeRequest = {} as MortgageesSearchModel;
    getMortgageeRequest.page = 1;
    getMortgageeRequest.pageSize = this.totalRecordsInCurrentSearch;
    getMortgageeRequest.name = searchTerm;

    this.findMortgagees(getMortgageeRequest)
      .pipe(take(1))
      .subscribe(results => {
        this.mortgagees = results;
        this.buildResultsList(results);
      },
        error => {
          this._toastr.error('We are currently unable to process your request. Please email us at MERSHOI.SUPPORT@ice.com or call (888) 680-6377');
        });

  }

  findMortgagees(searchRequest: MortgageesSearchModel) {

    const options = {
      params: new HttpParams()
        .set('page', searchRequest.page.toString())
        .set('pageSize', searchRequest.pageSize.toString())
        .set('name', searchRequest.name)
    }

    return this.#_httpClient
      .get<ApiResponse<GetMortgageesResponse>>
      (`${environment.portalApiUrl}v1/mortgagees`, options);

  }

  selectItem(i: number) {
    this.itemSelected = true;
    this.businessName = this.mortgagees.data.mortgagees[i].name;
    this.businessAddress1 = this.mortgagees.data.mortgagees[i].address1;
    this.businessAddress2 = this.mortgagees.data.mortgagees[i].address2;
    this.businessCity = this.mortgagees.data.mortgagees[i].city;
    this.businessState = this.mortgagees.data.mortgagees[i].state;
    this.businessZip = this.mortgagees.data.mortgagees[i].zip.toString();
    this.records = 1;
  }

  finish() {
    this._router.navigate(['/home']);
  }

  private buildResultsList(listToBuild: ApiResponse<GetMortgageesResponse>) {

    let fullList = document.getElementById('fullList');
    let ul = document.createElement('ul');
    ul.setAttribute('style', 'list-style-type: none; width: 37.38vw; margin-left: -8px;');

    let header = document.createElement('li');
    header.setAttribute('style', 'background: #FFFFFF 0% 0% no-repeat padding-box; margin-top: 40px; margin-bottom: 40px; text-align: center;')
    let span = document.createElement('span');
    span.setAttribute('style', 'color: #658D1B; font-size: 28px; font-weight: 700;')
    span.innerHTML = 'We found several mortgagees'
    header.appendChild(span);
    ul.appendChild(header);

    for (var i = 0; i < listToBuild.data.mortgagees.length; i++) {
      let li = document.createElement('li');
      // eslint-disable-next-line max-len
      li.setAttribute('style', 'background: #FFFFFF 0% 0% no-repeat padding-box; box-shadow: 0px 0px 10px #00000029; border: 1px solid #CCCCCC; border-radius: 5px; padding: 10px; border-radius: 5px; margin-top: 20px; text-align: center;')
      let firstSpan = document.createElement('span');

      firstSpan.setAttribute('style', 'color: #555555; font-size: 18px; font-weight: 700;');
      firstSpan.innerHTML = listToBuild.data.mortgagees[i].name;
      li.appendChild(firstSpan);

      let br = document.createElement('br');
      li.appendChild(br);

      let secondSpan = document.createElement('span');

      secondSpan.setAttribute('style', 'color: #757575; font - size: 16px;');
      // eslint-disable-next-line max-len
      secondSpan.innerHTML = listToBuild.data.mortgagees[i].address1 + ' ' + listToBuild.data.mortgagees[i].address2 + ' ' + listToBuild.data.mortgagees[i].city + ' ' + listToBuild.data.mortgagees[i].state + ' ' + listToBuild.data.mortgagees[i].zip.toString();
      li.appendChild(secondSpan);
      ul.appendChild(li);
    }

    fullList.appendChild(ul);
    this.fullListBuilt = true;
    this.showAll = true;

  }

  private clearResultsList() {
    let fullList = document.getElementById('fullList');

    while (fullList.firstChild) {
      fullList.removeChild(fullList.firstChild);
    }
  }

  private subscribeToMortgageeService() {
    this._mortgageeService.requestSubject.subscribe(isRequestSent => {
      this.requestSent = isRequestSent;

      if (this.requestSent === true) {
        this.mortgageeAdded = true;
      }

      this.clearSearch();
      if (this.showAllRecords) {
        this.clearResultsList();
      }
    })
  }

  public clearSearch() {
    this.searchString = '';
    this.searchValue = '';
    this.isSearching = false;
    this.showAllRecords = false;
    this.showAll = false;
    this.inError = false;
    this.itemSelected = false;
    this.noMortgageesFound = false;
  }

  openRequestSentModal() {
    this._modalService.open('requestSent');
  }
}
