<header [ngClass]="'container'" *ngIf="this.isUserLoggedIn !== false">
  <nav>
    <div [ngClass]="'dropdown'" (mouseleave)="panelExpand()">
      <div class="dropdown-toggle-btn">
        <div type="button" class="dropdown-toggle" data-toggle="dropdown"
                aria-haspopup="false"
                aria-expanded="false"
                (click)="panelExpanded = !panelExpanded" >
          <span [ngClass]="'user-name'">{{this.userName}}</span>
          </div>
      </div>
      <div [ngClass]="'dropdown-menu'" *ngIf="panelExpanded">
        <div [ngClass]="'dropdown-item'" (click)="navigate('account')">
          <a>My Account</a>
        </div>
        <div [ngClass]="'dropdown-item'" (click)="navigate('search')">
          <a>Insurance Search</a>
        </div>
        <div [ngClass]="'dropdown-item'" (click)="navigate('mortgagee')" *ngIf="this.showLossPayee === true">
          <a>Mortgagee Update</a>
        </div>
        <div [ngClass]="'dropdown-item'" (click)="logout()">
          <a>Logout</a>
        </div>
      </div>
    </div>
  </nav>
</header>
