import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { FeatureFlagService } from '../services/feature-flag.service';

@Directive({
  selector: '[appFeatureFlag]'
})
export class FeatureFlagDirective implements OnInit {

  private _flagName = '';
  private _hideFeature = true;

  @Input() set appFeatureFlag(val: string) {
    if (val) {      
      this._flagName = val;
      this.updateView();
    }
  }

  constructor(
    private _template: TemplateRef<ViewContainerRef>,
    private _viewContainer: ViewContainerRef,
    private _featureFlag: FeatureFlagService
    ) { }

  ngOnInit() {
    this.updateView();
  }

  updateView() {
    if (this.checkValidity()) {
      this._viewContainer.createEmbeddedView(this._template);
      if (this._hideFeature) {
        this._viewContainer.createEmbeddedView(this._template);
        this._hideFeature = false;
      }
    } else {
      this._viewContainer.clear();
      this._hideFeature = true;
    }
  }

  checkValidity() {
    return (
      this._flagName &&
      this._featureFlag.isFeatureEnabled(this._flagName)
    );
  }

}
