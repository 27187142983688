import { Component } from '@angular/core';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent {
  bannerVisible = false;
  constructor() {
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      this.bannerVisible = true;
    }
  }

  //Keeping this in for when we want to enable close functionality
  //closeBanner() {
  //  this.bannerVisible = false;
  //}
}
