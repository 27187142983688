import { Component, OnInit, EventEmitter, Output, AfterViewInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';
import { FeatureFlagService } from '../../services/feature-flag.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, AfterViewInit {

  @Output() isLoggedIn: EventEmitter<boolean> = new EventEmitter();

  private readonly _featureName = 'losspayeeexpress';

  public showLossPayee = false;
  public userName = '';
  public panelExpanded = false;
  public isUserLoggedIn = false;

  constructor(
    private authService: MsalService,
    private router: Router,
    private _featureFlagService: FeatureFlagService
  ) { }

  ngOnInit() {
    this.initUserName();
    this.isLoggedIn.emit(true);
  }

  ngAfterViewInit(): void {

    this._featureFlagService.isFeatureEnabled(this._featureName)
      .then(f => this.showLossPayee = f);
    
  }

  initUserName(): void {
       
    const userToken = this.authService.instance.getActiveAccount();
    if (userToken !== null) {
      const first = userToken.idTokenClaims['given_name'];
      const last = userToken.idTokenClaims['family_name'];
      this.userName = `${first} ${last}`;
      this.isUserLoggedIn = true;
    } else {
      this.userName = 'User Name';
    }
  }

  logout(): void {
    this.isLoggedIn.emit(false);
    this.authService.logout();
  }

  panelExpand(): void {
    this.panelExpanded = false;
  }

  navigate(event: string): void {
    if (event === 'account') {
      this.router.navigate(['/my-account'], { queryParams: { setOption: 0 } });
    } else if (event === 'search') {
      this.router.navigate(['/search']);
    } else if (event === 'mortgagee') {
      this.router.navigate(['loss-payee-express']);
    }
    this.panelExpanded = false;
  }
}
