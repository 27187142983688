import { Component } from '@angular/core';
import { Insurer } from 'src/app/interfaces/loss-payee/loss-payee-insurer';
import { ModalService } from 'src/app/services/modals/modals.service';

@Component({
  selector: 'app-delivered-to-insurer-modal',
  templateUrl: './delivered-to-insurer-modal.component.html',
  styleUrls: ['./delivered-to-insurer-modal.component.css']
})
export class DeliveredToInsurerModalComponent {
  public insurer = {} as Insurer;
  public clientEmail: string;
  public referenceId: string;

  constructor(private _modalService: ModalService) { }

  closeModal() {
    this._modalService.close('delivered-to-insurer');
  }  
}
