import {Component, ElementRef, Input, OnInit} from '@angular/core';
import { saveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';

import { AddressViewModel } from '../../../interfaces/address/addressViewModel';
import { SearchService } from '../../../services/search/search-service';
import { BriefPolicySearchDataEvents } from '../policy-search-data.events/policy-search-data.events';
import { BriefDetailsResponse } from '../../../interfaces/search/brief-search-details-response';
import { PurchaseEOIRequest } from '../../../interfaces/search/brief-purchase-request';
import { BriefSearchResponse } from '../../../interfaces/search/brief-search-response';
import { ErrorMessage } from '../../../shared/models/error-message.enum';
import { policyTypeCode } from '../../../interfaces/search/brief-search-request';
import { Header } from '../../../shared/enum/headers.enum';

@Component({
  selector: 'app-policy-search-results',
  templateUrl: './policy-search-results.component.html',
  styleUrls: ['./policy-search-results.component.scss']
})

export class PolicySearchResultsComponent implements OnInit {
  // eslint-disable-no-input-rename
  @Input('userHasPaymentMethod') hasPaymentMethod: boolean;

  public briefSearchDetailsLength: number;
  public fileUploadCompleted = false;
  public hasMultiplePolicies: boolean = null;
  public address: AddressViewModel;
  public briefSearchDetails: BriefDetailsResponse;
  public purchaseEOIRequest: PurchaseEOIRequest = { EOIPrice: 0, EvidenceKeys: [] };
  public briefSearchResponse: BriefSearchResponse;
  public briefSearchDetailsResult: BriefDetailsResponse[] = [];
  public canShowSearchResults = false;
  public showDefaultPaymentMethodFrame = true;
  public isPurchasingEvidence = false;
  public hasPurchasedEvidence = false;
  public selectOptionName: string = 'policy';
  public selectedPolicyId: number = -1;
  public selectedPolicyDom: EventTarget | null;
  public buyDisabled : boolean = true;
  public totalFee : string = '0.00';
  headerSearch : Header = Header.SearchResults;
  headerOrderConfirm : Header = Header.OrderConfirm;

  constructor(
    private _searchService: SearchService,
    private _dataEvents: BriefPolicySearchDataEvents,
    private _toastService: ToastrService
  ) { }

  ngOnInit(): void {
    this.syncSearchResults();
    this.syncSearchDetailResults();
    this.syncFileUpload();
  }

  syncFileUpload(): void {
    this._dataEvents.fileUploadCompleted_.subscribe(
      fileUploadCompleted => {
        this.fileUploadCompleted = fileUploadCompleted;
      }
    );
  }

  /**
   * @desc  event subscribed to listen when file upload is completed
   */
  syncSearchDetailResults(): void {
    this._dataEvents.briefDetailsSearchResults_.subscribe(
      briefSearchDetails => {
        this.briefSearchDetails = briefSearchDetails;
        if (this.briefSearchDetails !== null) {
          this.briefSearchDetailsResult = [];
          this.briefSearchDetailsResult.push(this.briefSearchDetails);
          this.reset();
          if (this.briefSearchDetails.briefDetails.length === 1) {
            this.onPolicySelect(null,0);
            this.hasMultiplePolicies = false;
          } else {
            this.hasMultiplePolicies = true;
          }
        }
      }
    );
  }

  /**
   * @desc  event subscribed to listen when search is completed
   */
  syncSearchResults(): void {
    this._dataEvents.briefPolicySearch.subscribe(searchResult => {
      if (searchResult) {
        this.briefSearchDetailsResult = [];
        this.briefSearchResponse = searchResult;
        this.briefSearchDetailsLength = searchResult.briefSearchResults.length;
        this.canShowSearchResults = this.briefSearchDetailsLength > 0;
        this.hasPurchasedEvidence = false;
      } else {
        this.briefSearchDetailsLength = null;
        this.canShowSearchResults = false;
      }
      this.updateResultHeader(false, !!searchResult);
    });
  }

  mapDetailsToPurchase(briefSearchDetails: BriefDetailsResponse): PurchaseEOIRequest {
    this.purchaseEOIRequest = { EOIPrice: 0, EvidenceKeys: [] };

    if (briefSearchDetails['eoiPrice'] !== null) {
      this.purchaseEOIRequest.EOIPrice = briefSearchDetails['eoiPrice'];
    }

    if (this.selectedPolicyId >= 0) {
      this.purchaseEOIRequest.EvidenceKeys.push(briefSearchDetails.briefDetails[this.selectedPolicyId]['evidenceKey']);
    }

    return this.purchaseEOIRequest;
  }

  /**
   * @desc  function when buy button is clicked
   */
  handleBuy(): void {
    if (this.selectedPolicyId === -1) return; // policy not selected
    this.isPurchasingEvidence = true;
    this.buyDisabled = true;
    this._searchService.postPurchaseEOI(this.briefSearchDetails.briefSearchId, this.mapDetailsToPurchase(this.briefSearchDetails))
      .subscribe(
        data => {
          if (data !== null) {

            var mimeType = data.headers.get('content-type').slice(-3);

            if (mimeType === 'pdf') {
              const blob = new Blob([data.body], { type: 'application/pdf' });
              saveAs(blob, 'EvidenceofInsurance.pdf');

            } else if (mimeType === 'zip') {
              const blob = new Blob([data.body], { type: 'application/zip' });
              saveAs(blob, 'EvidenceofInsurance.zip');

            }
            this.isPurchasingEvidence = false;
            this.hasPurchasedEvidence = true;
            this.buyDisabled = false;
            this.updateResultHeader(true, true);
          }
        },
        e => {
          this.isPurchasingEvidence = false;
          this.buyDisabled = false;
          this._toastService.error(ErrorMessage.GenericError);
        }
      )
  }

  /**
   * @param isResultHeader boolean to indicate whether to show search header or order confirm header
   * @param hasResult boolean to indicate whether to show the header or hide it
   */
  updateResultHeader(isResultHeader : boolean, hasResult : boolean): void {
    try {
      const header = document.querySelector('#search-header');
      header.innerHTML = isResultHeader ? this.headerOrderConfirm : this.headerSearch;
      if (hasResult)
        header.classList.add('show');
      else header.classList.remove('show');
      if (isResultHeader)
        document.querySelector('#search-criteria').classList.add('hide');
    } catch (e) {
      console.log('unable to update result header');
    }
  }

  getPolicyType(policyCode: number): string {
    if (policyCode !== undefined && policyCode !== null) {
      return policyTypeCode[policyCode];
    }
    return 'Unknown';
  }

  onPolicySelect(event: MouseEvent | null, id: number) {
    if (event) {
      const policySelected = event.currentTarget as HTMLElement;
      const radioBtn = policySelected.querySelectorAll('input[type="radio"]');
      radioBtn[0]['checked'] = true;
      policySelected['classList'].add('selected');
      if (this.selectedPolicyDom) {
        this.selectedPolicyDom['classList'].remove('selected');
      }
      this.selectedPolicyDom = event.currentTarget;
    }
    this.buyDisabled = false;
    this.selectedPolicyId = id;
    this.totalFee = this.briefSearchDetails['eoiPrice'].toFixed(2) + '';
  }

  reset(): void {
    this.buyDisabled = true;
    this.totalFee = '0.00';
    this.selectedPolicyId = -1;
  }
}


