import { Component } from '@angular/core';
import { ModalService } from '../../../../services/modals/modals.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-no-payment-method-modal',
  templateUrl: './no-payment-method-modal.component.html',
  styleUrls: ['./no-payment-method-modal-component.scss']
})
export class NoPaymentMethodModalComponent {
  constructor(
    private modalService: ModalService,
    private router: Router) {
  }

  public onAddPaymentMethodClick(): void {
    this.router.navigate(['/my-account'], { queryParams: { openAfterRoute: 'true' } });
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

}
