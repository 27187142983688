import { Component, ViewChild } from '@angular/core';
import { ModalService } from '../../../../services/modals/modals.service';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.css']
})
export class ProgressBarComponent {
  public isSearching = false;
  public progressWidth = 0;

  constructor(private modalService: ModalService) { }


  closeModal(id: string) {
    this.modalService.close(id);
  }

  showProgressBar() {
    let elem = document.getElementById('progressBar');
    elem.style.width = this.progressWidth + '%';

    let id = setInterval(() => {
      if (this.isSearching === false || this.progressWidth === 100) {
        clearInterval(id);
        this.progressWidth = 0;
      } else {
        this.progressWidth = this.progressWidth >= 100 ? 0 : this.progressWidth;
        this.progressWidth++;
        elem.style.width = this.progressWidth + '%';
      }
    }, 290);

  }

}
