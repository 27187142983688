import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpEvent, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiResponse } from '../../interfaces/api/apiResponse';
import { ClientConsentRequest } from '../../interfaces/client-consent/client-consent-request';

@Injectable({
  providedIn: 'root',
})

export class ClientConsentService {
  private _httpClient: HttpClient;

  constructor(httpClient: HttpClient) { this._httpClient = httpClient; }

  public uploadCertificateOfAuthorization(briefSearchId: string, files: Set<File>): Observable<HttpEvent<{}>> {

    const formData: FormData = new FormData();

    files.forEach(file => {
      formData.append('certificateFile', file, file.name);
    });

    return this._httpClient
      .put<ApiResponse<ClientConsentRequest>>(
        `${environment.portalApiUrl}v1/policies/briefs/${briefSearchId}/certificate`, formData, { observe: 'response' }
      );
  }

}
