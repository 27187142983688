import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserProfile } from '../../interfaces/users/user-profile';
import { GetUserProfileResponse } from '../../interfaces/users/get-user-profile-response';
import { ApiResponse } from '../../interfaces/api/apiResponse';
import { CreateUserRequest } from '../../interfaces/users/create-user-request';
import { environment } from '../../../environments/environment';
import { AccountStatusService } from '../accountStatus/account-status.service';
import { RequiredTermsAndConditions } from '../../interfaces/termsAndConditions/requiredTermsAndConditions';


@Injectable({
  providedIn: 'root',
})

export class UsersService {
  protected className: 'UsersService';

  private _httpClient: HttpClient;
  private _user: AccountInfo;
  private readonly _msalService: MsalService;
  private _userObserver = new Subject<boolean>();

  public accountStatus = '';
  public userSubscriber = this._userObserver.asObservable();
  public toastr: ToastrService;
  public userProfile: UserProfile;
  public termsAndConditionsRequired?: RequiredTermsAndConditions;
  public userId = '';
  public userPaymentMethodId = '';
  public authorizationKey = '';

  constructor(
    msalService: MsalService,
    toastr: ToastrService,
    httpClient: HttpClient,
    private _accountStatusService: AccountStatusService) {
    this._msalService = msalService;
    this.toastr = toastr;
    this._httpClient = httpClient;
  }

  public getMSALUser(): AccountInfo {
    this._user = this._msalService.instance.getActiveAccount();
    return this._user;
  }

  public userProfile_(email: string = null): Observable<UserProfile> {
    this.userLoggedIn();
    return this.fetchUserProfile();
  }

  public registerUser(user: CreateUserRequest): Observable<HttpResponse<ApiResponse<GetUserProfileResponse>>> {
    return this._httpClient
      .post<ApiResponse<GetUserProfileResponse>>(
        `${environment.portalApiUrl}v1/users`, user, { observe: 'response' }
      );

  }

  public userLoggedIn(): void {
    this.userLoggedInEmitter(true);
  }

  public fetchUserProfile(): Observable<UserProfile> {
    return this._httpClient
      .get<ApiResponse<GetUserProfileResponse>>(
        `${environment.portalApiUrl}v1/users/profile`, { observe: 'response' })
      .pipe(
        map(userProfileResponse => {
          if (userProfileResponse.status >= 300) {
            throwError(new Error('User is invalid'));
            this.toastr.error('User is invalid');
          }

          if (userProfileResponse.body.data !== null && userProfileResponse.body.data !== undefined) {
            this.setUserAccountInformation(userProfileResponse.body.data.profile);
            this.userLoggedInEmitter(true);
            return userProfileResponse.body.data.profile;
          } else {
            return null;
          }

        })
      );
  }

  public userHasPaymentMethod(): boolean {
    return  typeof this.userPaymentMethodId !='undefined' && this.userPaymentMethodId  ? true : false;
  }

  private userLoggedInEmitter(data: boolean): void {
    this._userObserver.next(data);
  }

  private setUserAccountInformation(userProfile: UserProfile) {
    this.userId = userProfile.userId;
    this.authorizationKey = userProfile.authorizationKey;
    this.userPaymentMethodId = userProfile.paymentMethodId;
    this.accountStatus = this._accountStatusService.setAccountStatus(userProfile);
    this.termsAndConditionsRequired = userProfile.termsAndConditionsRequired;
  }

}
