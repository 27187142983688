<div [ngClass]="'container'" [formGroup]="consentForm">
  <h2 [ngClass]="'title'">Contact Details</h2>
  <label for="emailAddress" [ngClass]="'email-label'">Email Address</label>
  <input *ngIf="this.isReviewing === false" type="email" id="emailAddress" placeholder="Youremail@domain.com" [ngClass]="'email-input'" formControlName="email" />
  <input *ngIf="this.isReviewing === true" type="email" id="emailAddress" [ngClass]="'email-input local'" readonly autocomplete="no" formControlName="email" />
  <span *ngIf="(consentForm.get('email').touched || consentForm.get('email').dirty) && consentForm.get('email').invalid"
        class="help-block" [ngClass]="'email-error'">Please enter a valid email address</span>

  <h2 [ngClass]="'client-consent-label'"> Client Consent</h2>
  <div *ngIf="this.isReviewing === true" [ngClass]="'cloud'">
    Uploaded Borrower Consent <br />
    {{this.fileToBeUploaded}}
  </div>
  <div *ngIf="this.isReviewing === false" id="dropArea" [ngClass]="'solid-border client-consent-input'">
    <i [ngClass]="'fas fa-cloud-upload-alt fa-2x spacer cloud'"></i>

    <p [ngClass]="'upload-label'">Please upload your borrower's signed consent</p>
    <input type="hidden" formControlName="file" minlength="1"/>
    <span *ngIf="(consentForm.get('file').touched || consentForm.get('file').dirty) && (consentForm.get('file').errors?.['minlength'] || consentForm.get('file').invalid)"
          class="help-block" [ngClass]="'upload-label'">Please select a file</span>
    <div>
    </div>
    <div [ngClass]="'local'">
      <app-uploader [uploadedFile] ="this.lossPayeeService.filesToUpload" (fileAdded)="processFiles($event)"></app-uploader>
    </div>
  </div>
</div>
