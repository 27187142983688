import { Component } from '@angular/core';

@Component({
  selector: 'app-training-materials',
  templateUrl: './training-materials.component.html',
  styleUrls: ['./training-materials.component.scss']
})
export class TrainingMaterialsComponent  {

  constructor() { }

}
