import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { UsersService } from '../../../services/users/users.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy  {
  private subs: Subscription[] = [];
  public userIsLoggedIn: boolean;

  constructor(
    private _router: Router,
    private _userService: UsersService) { }

  ngOnInit() {
    this.subscribeToUserService();
  }

  ngOnDestroy() {
    if (this.subs && this.subs.length > 0) {
      while (this.subs.length > 0) {
        let sub = this.subs.pop();
        sub.unsubscribe();
        sub = null;
      }
    }
  }

  navigateToHelp(): void {
    this._router.navigate(['/help']);
  }

  public subscribeToUserService(): void {
    this.subs.push(this._userService.userSubscriber.subscribe(() => {
      this.userIsLoggedIn = true;
    }));
  }

}
