import { Component, OnInit, OnDestroy, ElementRef, Input } from '@angular/core';
import { ModalService } from '../../../services/modals/modals.service';

@Component({
  selector: 'app-custom-modal',
  templateUrl: './modals.component.html',
  styleUrls: ['./modals.component.css']
})
export class ModalsComponent implements OnInit, OnDestroy {
  @Input() id: string;
  private _element: ElementRef;

  constructor(private _modalService: ModalService, private _el: ElementRef) {
    this._element = _el;
  }

  ngOnInit(): void {
    let modal = this;

    // ensure id attribute exists
    if (!this.id) {
      console.error('modal must have an id');
      return;
    }

    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    document.body.appendChild(this._element.nativeElement);

    // close modal on background click
    this._element.nativeElement.addEventListener('click', (e: { target: { className: string; }; }) => {
      if (e.target.className === 'modal__overlay modal__overlay--toggle') {
        modal.close();
      }
    });

    this._element.nativeElement.addEventListener('click', (e: { target: { className: string; }; }) => {
      if (e.target.className === 'model-close-btn' || e.target.className === 'close-image') {
        modal.close();
      }
    });

    // add self (this modal instance) to the modal service so it's accessible from controllers
    this._modalService.add(this);
  }

  // remove self from modal service when directive is destroyed
  ngOnDestroy(): void {
    this._modalService.remove(this.id);
    this._element.nativeElement.remove();
  }

  // open modal
  open(): void {
    let modal = document.getElementById(this.id);
    let modalSelector = modal.getElementsByClassName('custom-modal');
    let modalSelectorChild = modalSelector[0].classList;
    if (modalSelectorChild !== null) {
      modalSelectorChild.remove('hidden');
    }
  }

  // close modal
  close(): void {
    let modal = document.getElementById(this.id);
    let modalSelector = modal.getElementsByClassName('custom-modal');
    let modalSelectorChild = modalSelector[0].classList;
    if (modalSelectorChild !== null) {
      modalSelectorChild.add('hidden');
    }
  }
}
