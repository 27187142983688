<app-custom-modal id="requestSent">
  <div [ngClass]="'container'">
    <div [ngClass]="'header'">
      <div [ngClass]="'circle fas fa-check fa-3x'"></div>
    </div>

    <div [ngClass]="'main-body'">
      <p [ngClass]="'title'">Success</p>
      <p [ngClass]="'reviewed'">Your mortgagee has been added</p>
      <button id="close" type="button" (click)="this.closeModal('requestSent')" [ngClass]="'close'">Close</button>
    </div>
  </div>
</app-custom-modal>

