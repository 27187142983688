import { HttpClient } from '@angular/common/http';
import { Input, Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { LossPayeeUpdateRequest } from 'src/app/interfaces/loss-payee/loss-payee-update-request';
import { MortgageeViewModel } from 'src/app/interfaces/loss-payee/mortgagee-view-model';
import { CreateMortgageeRequest } from 'src/app/interfaces/mortgagee/create-mortgagee-request';
import { LossPayeeExpressComponent } from 'src/app/modules/loss-payee/loss-payee-express/loss-payee-express.component';
import { ModalService } from 'src/app/services/modals/modals.service';
import { MortgageeService } from 'src/app/services/mortgagee.service';
import { ErrorMessage } from 'src/app/shared/models/error-message.enum';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-mortgagee-suggestions-modal',
  templateUrl: './add-mortgagee-suggestions-modal.component.html',
  styleUrls: ['./add-mortgagee-suggestions-modal.component.scss']
})

export class AddMortgageeSuggestionsModalComponent {
  @Input() name: string;
  @Output() Success: EventEmitter<boolean> = new EventEmitter<boolean>();
  public addedMortgagee = {} as CreateMortgageeRequest;
  public mortgagees = [] as Array<MortgageeViewModel>;
  public lossPayeeRequest = {} as LossPayeeUpdateRequest;
  public selectedMortgagee = {} as MortgageeViewModel;
  public lossPayeeRequestComponent = {} as LossPayeeExpressComponent;
  public userId: string;
  public eoiPurchaseId: string;
  public modalTitle: string;
  public isFromLossPayee: boolean;
  public isFromLossPayeeExpressPath : boolean;
  public isMortgageeNew = true;
  mortgageeSuggestionModalName = 'add-mortgagee-suggestions';
  requestSentModalName = 'requestSent'
  newRequestModalName = 'newMortgageeRequest';
  isNewMortgageeConfirmed = false;

  constructor(
    private _modalService: ModalService,
    private _mortgageeservice: MortgageeService,
    private _httpClient: HttpClient,
    private _router: Router,
    private _toastr: ToastrService) { }

  closeModal(id: string): void {
    this.isNewMortgageeConfirmed = false;
    this.isMortgageeNew = true;
    this.selectedMortgagee = null;
    this._modalService.close(id);
  }

  openModal(id: string) {
    this._modalService.open(id);
  }

  transformAddress(address1: string, address2: string): string {
    address1 = address1?.replace('P.o', 'P.O');
    return address2 ? `${address1} ${address2}, ` : `${address1}, `;
  }

  confirmMortgagee(): void {
    this.isNewMortgageeConfirmed = true;
    if (this.isMortgageeNew) {
      if(this.isFromLossPayeeExpressPath){
        this.lossPayeeRequestComponent.childMortgagee.mapFormToModel();
        this.lossPayeeRequestComponent.activeScreen += 1;
        this.closeModal(this.mortgageeSuggestionModalName);
      }
      else{
        this._mortgageeservice.createMortgagee(this.userId, this.addedMortgagee)
        .pipe(take(1))
        .subscribe(response => {
          if (this.isFromLossPayee) {
            this.postToLossPayeeEndpoint(response.data.mortgageeRequest.mortgageeId)
              .pipe(take(1))
              .subscribe(() => {
                this.closeModal(this.mortgageeSuggestionModalName);
                this._router.navigate(['/loss-payee-success']);
              },
                error => {
                  this.closeModal(this.mortgageeSuggestionModalName);
                  this._toastr.error(ErrorMessage.GenericError);
                });
          } else {
            this.closeModal(this.mortgageeSuggestionModalName);
            this._mortgageeservice.isRequestSent(true);
            this.Success.emit(true);
            this.openModal(this.requestSentModalName);
          }
        },
          error => {
            this.isNewMortgageeConfirmed = false;
            this._toastr.error(ErrorMessage.GenericError);
          });
      }
    } else {
      if(this.isFromLossPayeeExpressPath){
        this.replaceNewMortgageeWithExistingMortgagee(this.selectedMortgagee);
        this.lossPayeeRequestComponent.activeScreen += 1;
        this.closeModal(this.mortgageeSuggestionModalName);
      }
      else if (this.isFromLossPayee) {
        this.postToLossPayeeEndpoint(this.selectedMortgagee.mortgageeId)
          .pipe(take(1))
          .subscribe(() => {
            this._router.navigate(['/loss-payee-success']);
          },
            error => {
              this.closeModal(this.mortgageeSuggestionModalName);
              this._toastr.error(ErrorMessage.GenericError);
            });
      } else {
        this._modalService.close(this.mortgageeSuggestionModalName);
        this.Success.emit(true);
      }
    }
  }

  postToLossPayeeEndpoint(mortgageeId: number): Observable<LossPayeeUpdateRequest> {
    this.lossPayeeRequest.mortgageeId = mortgageeId.toString();
    return this._httpClient
      .post<LossPayeeUpdateRequest>
      (`${environment.portalApiUrl}v1/policies/evidence/${this.eoiPurchaseId}/losspayee`, this.lossPayeeRequest);
  }

  selectMortgageeSuggestion(mortgagee: MortgageeViewModel): void {
    this.isMortgageeNew = false;
    this.selectedMortgagee = mortgagee;
  }

  selectAddMortgagee(): void {
    this.isMortgageeNew = true;
    this.selectedMortgagee = {} as MortgageeViewModel;
  }

  private replaceNewMortgageeWithExistingMortgagee(s: MortgageeViewModel){
    this.lossPayeeRequestComponent.childMortgagee.clearInternalForm();
    this.lossPayeeRequestComponent.addingNewMortgagee = false;
    let mortgagee = this.lossPayeeRequestComponent.childMortgagee.mapMortgageeResponseToString(s);
    this.lossPayeeRequestComponent.childMortgagee.mortgageeForm.get('mortgagee').setValue(mortgagee);
    this.lossPayeeRequestComponent.childMortgagee.setMortgageeId(s.mortgageeId.toString());
    this.lossPayeeRequestComponent.childMortgagee.mapFormToModel();
  }
}
