<div [ngClass]="'section confirm'">
  <div class="grid-container">
    <div>
      <h2>Request Submitted Successfully</h2>
      <p>
        Click <a [routerLink]="['/my-account']" [queryParams]="{setOption: 2}">here</a>  to view the status of your request or click on the button below to return to the home page.
      </p>

      <div class="input-btn">
        <button (click)="navigateTo()" type="submit" role="button">
          Return To Home
        </button>
      </div>
    </div>
  </div>
</div>
