<div>
  <input type="file"
         id="fileUploader"
         name="fileUploader"
         [accept]="canRestrictFileTypes ? acceptedFileTypes : null"
         [multiple]="canAllowMultipleFiles ? true : null"
         #file
         style="display: none"
         (change)="onFilesAdded()" />
  <section>
    <div class="center">
      <div class="row">
        <p class="text-center"
           *ngFor="let validation of validations">
          {{ validation }}
        </p>
      </div>
      <button id="uploadButton"
              class="primary"
              (click)="addFiles()">
        Choose a file to upload
      </button>
    </div>
    <div class="file-uploader-area"
         *ngFor="let file of files">
      <div class="file-uploader-icon">
        <i class="fa fa-file fa-3"></i>
      </div>
      <div class="file-uploader-file-name">{{ file.name | truncateText }}</div>
      <div class="text-left file-uploader-file-size">
        {{ fileValidator.transformFileSize(file.size) }}
      </div>
      <div class="file-uploader-remove"
           (click)="removeFile(file)">
        Remove
      </div>
    </div>
  </section>
</div>
