import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})

export class FooterComponent  {

  getCopyRightText(): string {
    const _copyright = {
      simple: 'MERS HOI Hub. All rights reserved.',
      full: 'MERS HOI Hub. All rights reserved. MERS and the ICE Mortgage Technology and MERS logos\n' +
        '    are trademarks or registered trademarks of the entities of ICE Mortgage Technology. Other company and product names\n' +
        '    may be trademarks of their respective owners.'
    };

    const path = window.location.hash.replace(/[^a-zA-Z]/g,'');
    return path === 'search' ? _copyright.full : _copyright.simple;
  }

  getCurrentYear(): number {
    var dateYear = new Date();
    let y = dateYear.getFullYear();

    return y !== null ? y : 2021;
  }

}
