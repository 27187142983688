import { Component } from '@angular/core';

import { ModalService } from '../../../../../services/modals/modals.service';

@Component({
  selector: 'app-request-sent-modal',
  templateUrl: './request-sent-modal.component.html',
  styleUrls: ['./request-sent-modal.component.css']
})
export class RequestSentModalComponent {

  constructor(
    private _modalService: ModalService) { }


  closeModal(id: string) {
    this._modalService.close(id);    
  }

} 
