import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { distinctUntilChanged, take } from 'rxjs/operators';
import { ModalService } from 'src/app/services/modals/modals.service';
import { AddMortgageeSuggestionsModalComponent } from 'src/app/shared/components/modal-dialogs/add-mortgaee-suggestion-modal/add-mortgagee-suggestions-modal.component';
import { environment } from '../../../../environments/environment';
import { ApiResponse } from '../../../interfaces/api/apiResponse';
import { ContactViewModel } from '../../../interfaces/contact-view-model';
import { GetMortgageesResponse } from '../../../interfaces/loss-payee/get-mortgagees-response';
import { LossPayeeUpdateRequest } from '../../../interfaces/loss-payee/loss-payee-update-request';
import { MortgageeViewModel } from '../../../interfaces/loss-payee/mortgagee-view-model';
import { CreateMortgageeRequest } from '../../../interfaces/mortgagee/create-mortgagee-request';
import { State } from '../../../interfaces/search/state';
import { AddressViewModel } from '../../../interfaces/transactions/address-view-model';
import { UserProfile } from '../../../interfaces/users/user-profile';
import { MortgageeService } from '../../../services/mortgagee.service';
import { StatesService } from '../../../services/states/states.service';
import { UsersService } from '../../../services/users/users.service';
import { ErrorMessage } from '../../../shared/models/error-message.enum';
import { AddressPipe } from '../../../shared/pipes/address.pipe';

@Component({
  selector: 'app-loss-payee-request',
  templateUrl: './loss-payee-request.component.html',
  styleUrls: ['./loss-payee-request.component.scss']
})
export class LossPayeeRequestComponent implements OnInit {
  @ViewChild(AddMortgageeSuggestionsModalComponent) _mortgageeSuggestionsModal: AddMortgageeSuggestionsModalComponent;
  private _httpClient: HttpClient;
  private _toastr: ToastrService;
  private _userProfile: UserProfile;
  private _userService: UsersService;
  private _mortgageeId = '';
  private _mortgageeService: MortgageeService;
  private _router: Router;
  private _statesService: StatesService;
  private _modalService: ModalService;
  private _addressPipe: AddressPipe;

  hasError = false;
  badDate = false;
  addingMortgagee = false;
  eoiPurchaseId = '';
  mortgageeForm: FormGroup;
  pipe = new DatePipe('en-US');
  fullAddress = '';
  newMortgage = '';
  policyNumber = '';
  invalidAction = false;
  invalidEscrow = false;
  noMortgageesFound = true;
  lossPayeeUpdateRequest: LossPayeeUpdateRequest;
  mortgagees: ApiResponse<GetMortgageesResponse>;
  searchValue = '';
  states: Array<State>;
  isUpdatingMortgagee = false;

  constructor(
    private _injector: Injector,
    private _formBuilder: FormBuilder) {

    //  inject instance
    this._httpClient = _injector.get(HttpClient);
    this._toastr = _injector.get(ToastrService);
    this._userService = _injector.get(UsersService);
    this._router = _injector.get(Router);
    this._mortgageeService = _injector.get(MortgageeService);
    this._statesService = _injector.get(StatesService);
    this._modalService = _injector.get(ModalService);
    this._addressPipe = _injector.get(AddressPipe);
  }

  ngOnInit() {
    this.lossPayeeUpdateRequest = {} as LossPayeeUpdateRequest;
    this.setMinCalendarDate();
    this.states = this._statesService.getStates();
    this.mortgageeForm = this.buildForm();
    this.getMortgagePropertyInformation();
    this.listenForEvents();
    this._userProfile = {} as UserProfile;
    this._userService.fetchUserProfile().subscribe(profile => {
      this._userProfile = profile;
    });
  }

  isFormValid(): boolean {
    if (!this.addingMortgagee === true) {
      this.removeUnusedFormFields();
    }
    return this.mortgageeForm.valid;
  }

  submit(): void {
    /*first check if the are adding a new mortgagee. If not remove the validation from those fields*/
    if (!this.addingMortgagee === true) {
      this.removeUnusedFormFields();
    }

    if (!this.mortgageeForm.valid) {
      this.markAllControlsAsTouched(this.mortgageeForm);
      return;
    }

    this.isUpdatingMortgagee = true;

    if (this.addingMortgagee) {
      let addedMortgagee = this.mapMortgageeFormToModel();
      this._mortgageeService.getMortgageesByMortgageeRequest(addedMortgagee).pipe(take(1))
        .subscribe(response => {
          let exactMatch = this._mortgageeService.findExactMortgageeMatch(response.data.mortgagees, addedMortgagee);
          if (exactMatch !== null && exactMatch !== undefined) {
            this.postToLossPayeeEndpoint(exactMatch.mortgageeId.toString()).pipe(take(1))
              .subscribe(() => {
                this.isUpdatingMortgagee = false;
                this._router.navigate(['/loss-payee-success']);
              },
                error => {
                  this.mortgageeErrorHandler();
                })
          } else {
            // open modal
            this.isUpdatingMortgagee = false;
            this.setUpSuggestionModal(addedMortgagee, response.data.mortgagees);
            this._modalService.open('add-mortgagee-suggestions');
          }
        },
          error => {
            this.mortgageeErrorHandler();
          });
    }
    else {
      //  we are using 'then' of a promise to simplify error handling.  If 'then' is not successful the error catch fires
      //  we can't do this with 'subscribe' to an Observable.
      this.postToLossPayeeEndpoint().toPromise().then(() => {
        this._router.navigate(['/loss-payee-success']);
      }).catch((error) => this.mortgageeErrorHandler())
    }
  }

  setUpSuggestionModal(addedMortgagee: CreateMortgageeRequest, mortgagees: MortgageeViewModel[]) {
    this._mortgageeSuggestionsModal.addedMortgagee = addedMortgagee;
    this._mortgageeSuggestionsModal.mortgagees = mortgagees;
    this._mortgageeSuggestionsModal.userId = this._userProfile.userId;
    this._mortgageeSuggestionsModal.eoiPurchaseId = this.eoiPurchaseId;
    this._mortgageeSuggestionsModal.modalTitle = 'Update Mortgagee';
    this._mortgageeSuggestionsModal.isFromLossPayee = true;
    this._mortgageeSuggestionsModal.lossPayeeRequest = this.mapFormToModel('');
  }

  resetForm(): void {
    this.mortgageeForm.reset({ mortgageeName: '' });
    this.mortgageeForm.reset({ email: '' });
    this.mortgageeForm.get('action').patchValue('Select Action');
    this.mortgageeForm.get('position').setValue('Select Position');
    this.mortgageeForm.get('loanAmount').setValue('$');

    this.mortgageeForm.get('fullAddress').setValue(this.fullAddress);
    this.mortgageeForm.get('policyNumber').setValue(this.policyNumber);
    if (this.mortgagees !== undefined) {
      this.mortgagees.data.mortgagees = null;
    }
    this.addingMortgagee = false;
  }

  backToTransactions(): void {
    this._router.navigate(['/my-account'], { queryParams: { setOption: 1 } });
  }

  //  the question mark indicates optional parameter
  postToLossPayeeEndpoint(id?: string): Observable<LossPayeeUpdateRequest> {
    this.lossPayeeUpdateRequest = this.mapFormToModel(id);
    return this._httpClient
      .post<LossPayeeUpdateRequest>
      (`${environment.portalApiUrl}v1/policies/evidence/${this.eoiPurchaseId}/losspayee`, this.lossPayeeUpdateRequest);
  }

  selectItem(i: number) {
    let s = this.mapMortgageeResponseToString(this.mortgagees.data.mortgagees[i]);
    this.mortgageeForm.get('mortgageeName').setValue(s);

    //  there won't be a mortgageeId if they select 'Add New Mortgagee'
    if (this.mortgagees.data.mortgagees[i].name === 'Add New Mortgagee') {
      this.addingMortgagee = true;
    } else {
      this._mortgageeId = this.mortgagees.data.mortgagees[i].mortgageeId.toString();
    }

    this.noMortgageesFound = true;
    this.mortgagees.data.mortgagees = null;
  }

  search(): void {
    this.searchValue = (<HTMLInputElement>document.getElementById('mortgageeName')).value;
    const MINIMUM_CHARACTERS_FOR_SEARCH = 2;

    if (this.searchValue.length > MINIMUM_CHARACTERS_FOR_SEARCH) {
      this._mortgageeService.getMortgageesByName(1, 10, this.searchValue).pipe(
        distinctUntilChanged(),
      ).subscribe(result => {
        this.mortgagees = result;
        let addNewMortgagee = {} as MortgageeViewModel;
        addNewMortgagee.name = 'Add New Mortgagee';
        addNewMortgagee.address1 = '';
        addNewMortgagee.address2 = '';
        addNewMortgagee.city = '';
        addNewMortgagee.state = '';
        addNewMortgagee.zip = null;
        this.mortgagees.data.mortgagees.unshift(addNewMortgagee);
        this.noMortgageesFound = false;
      })
    }
  }

  prependAddNewMortgagee(): void {
    let header = document.createElement('li');
    header.setAttribute('style', 'background: #FFFFFF 0% 0% no-repeat padding-box; margin-top: 40px; margin-bottom: 40px; text-align: center;')
    let span = document.createElement('span');
    span.setAttribute('style', 'color: #658D1B; font-size: 28px; font-weight: 700;')
    span.innerHTML = 'Add New Mortgagee'
    header.appendChild(span);
  }

  private mortgageeErrorHandler(): void {
    this.isUpdatingMortgagee = false;
    this.hasError = true;
    this._toastr.error(ErrorMessage.GenericError);
  }

  private buildForm(): FormGroup {
    return this._formBuilder.group({
      fullAddress: [{ value: '', disabled: true }],
      policyNumber: ['', [Validators.required]],
      mortgageeName: ['', [Validators.required]],
      position: ['Select Position', [Validators.required, this.defaultPosition]],
      action: ['Select Action', [Validators.required, this.defaultAction]],
      loanNumber: ['', [Validators.required]],
      effectiveDate: ['', [Validators.required, this.dateValidator]],
      loanAmount: ['$', [Validators.required, this.defaultLoanAmount]],
      escrow: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')]],
      addMortgagee: ['', [Validators.required]],
      address1: ['', [Validators.required]],
      address2: [''],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      zip: ['', [Validators.required]]
    })
  }

  private defaultPosition(fc: FormControl): { defaultValue: 'Cannot use defualt value' } {
    const isInvalid = 'Select Position' === fc.value;
    return isInvalid ? { defaultValue: 'Cannot use defualt value' } : null;
  }

  private defaultAction(fc: FormControl) {
    let isInvalid = 'Select Action' === fc.value;
    return isInvalid ? { defaultValue: 'Cannot use defualt value' } : null;
  }

  private defaultLoanAmount(fc: FormControl) {
    let isInvalid = '$' === fc.value;
    return isInvalid ? { defaultValue: 'Cannot use defualt value' } : null;
  }

  private removeUnusedFormFields(): void {
    this.mortgageeForm.clearValidators();
    this.mortgageeForm.updateValueAndValidity();
    this.mortgageeForm.removeControl('addMortgagee');
    this.mortgageeForm.removeControl('address1');
    this.mortgageeForm.removeControl('address2');
    this.mortgageeForm.removeControl('city');
    this.mortgageeForm.removeControl('state');
    this.mortgageeForm.removeControl('zip');
  }

  private setMinCalendarDate(): void {
    let today = Date.now();
    let minDate = this.pipe.transform(today, 'yyyy-MM-dd');
    let datePicker = document.getElementById('effectiveDate');
    datePicker.setAttribute('min', minDate);
  }

  private getMortgagePropertyInformation(): void {
    this._mortgageeService.policyProperty.subscribe(results => {
      if (results !== null) {
        this.fullAddress = this.mapResultsToString(results.propertyAddress);
        this.eoiPurchaseId = results.eoiPurchaseId;
        this.policyNumber = results.policyNumber;
        this.mortgageeForm.get('fullAddress').setValue(this.fullAddress);
        if (this.policyNumber !== '' && this.policyNumber !== null) {
          this.mortgageeForm.get('policyNumber').setValue(this.policyNumber);
          this.mortgageeForm.get('policyNumber').disable();
        }
      }
    })
  }

  private markAllControlsAsTouched(formGroup: FormGroup): void {
    if (!!formGroup) {
      Object.keys(formGroup.controls).forEach(formControlKey => {
        formGroup.controls[formControlKey].markAsTouched();
      });
    }
  }

  private mapResultsToString(results: AddressViewModel): string {
    return this._addressPipe.transform(results);
  }

  private mapMortgageeResponseToString(response: MortgageeViewModel): string {
    let address2 = response.address2 === null ? '' : response.address2;

    if (response.name === 'Add New Mortgagee') {
      return response.name;
    }
    return response.name + ' ' + response.address1 + ' ' + address2 + ' ' + response.city + ', ' + response.state + ' ' + response.zip;
  }

  private listenForAction(): void {
    this.mortgageeForm.get('action').valueChanges.subscribe(value => {
      if (value === 'replaceAll' && this.mortgageeForm.get('position').value === '2') {
        this.invalidAction = true;
      } else {
        this.invalidAction = false;
      }
    })
  }

  private listenForEscrow(): void {
    this.mortgageeForm.get('escrow').valueChanges.subscribe(value => {
      if (value === 'yes' && this.mortgageeForm.get('position').value === '2') {
        this.invalidEscrow = true;
      } else {
        this.invalidEscrow = false;
      }
    })
  }

  private listenForPosition(): void {
    this.mortgageeForm.get('position').valueChanges.subscribe(value => {
      if (value === '2' && this.mortgageeForm.get('action').value === 'replaceAll') {
        this.invalidAction = true;
      } else {
        this.invalidAction = false;
      }

      if (value === '2' && this.mortgageeForm.get('escrow').value === 'yes') {
        this.invalidEscrow = true;
      } else {
        this.invalidEscrow = false;
      }
    })
  }

  private listenForEvents(): void {
    this.listenForAction();
    this.listenForEscrow();
    this.listenForPosition();
  }

  private mapFormToModel(id?: string): LossPayeeUpdateRequest {
    let lossPayee = {} as LossPayeeUpdateRequest;
    let contact = {} as ContactViewModel;
    lossPayee.contact = contact;
    let mortgageeId = id === undefined ? this._mortgageeId : id;

    lossPayee.operation = this.mortgageeForm.get('action').value;
    lossPayee.position = parseInt(this.mortgageeForm.get('position').value);
    lossPayee.loanAmount = parseFloat(this.mortgageeForm.get('loanAmount').value);
    lossPayee.loanNumber = this.mortgageeForm.get('loanNumber').value;
    lossPayee.policyNumber = this.mortgageeForm.get('policyNumber').value;
    lossPayee.mortgageeId = mortgageeId;
    lossPayee.effectiveDate = this.mortgageeForm.get('effectiveDate').value;
    lossPayee.isEscrowed = Boolean(this.mortgageeForm.get('escrow').value);
    lossPayee.contact.email = this.mortgageeForm.get('email').value;
    return lossPayee;
  }

  private mapMortgageeFormToModel(): CreateMortgageeRequest {
    /*  inner Mortgagee form  */
    let mortgagee = {} as CreateMortgageeRequest;
    mortgagee.name = this.mortgageeForm.get('addMortgagee').value;
    mortgagee.action = 'Add';
    mortgagee.address1 = this.mortgageeForm.get('address1').value;
    mortgagee.address2 = this.mortgageeForm.get('address2').value;
    mortgagee.city = this.mortgageeForm.get('city').value;
    mortgagee.state = this.mortgageeForm.get('state').value;
    mortgagee.zip = this.mortgageeForm.get('zip').value;

    return mortgagee;
  }

  private dateValidator(control: FormControl): { [s: string]: boolean } | null {
    let today = new Date().toLocaleDateString();
    let datePipe = new DatePipe('en-US');
    let minDate = datePipe.transform(today, 'yyyy-MM-dd');
    if (control.value < minDate) {
      return { 'badDate': true };
    }
  }
}

