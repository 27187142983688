<div [ngClass]="'container'" [formGroup]="mortgageeForm">
  <h2 [ngClass]="'title'">Mortgagee Information</h2>

  <label for="mortgagee" [ngClass]="'mortgagee-label'">Mortgagee Name:</label>

  <input *ngIf="this.isReviewing === false" type="text" formControlName="mortgagee" maxlength="100" id="mortgagee" (input)="this.search()" autocomplete="off"
         [ngClass]="'mortgagee-input'" />
  <input *ngIf="this.isReviewing === true" type="text" formControlName="mortgagee" id="mortgagee" autocomplete="no" readonly
         [ngClass]="'mortgagee-input local'" />

  <ul *ngIf="this.noMortgageesFound === false && this.isReviewing === false" ng-scrollbars [ngClass]="this.mortgagees.data.totalCount > 0 ? 'scrollbar mortgagee-error small' : 'scrollbar mortgagee-error small no-results'">
    <li id="singleMortgagee" *ngFor="let mortgagee of this.mortgagees.data.mortgagees; let i=index" (click)="this.selectItem(i)">
      <span>{{ mortgagee.name }}</span> <br /> <span >{{ mortgagee.address1 }} <span *ngIf="this.mortgagee.address2.length > 0">{{ mortgagee.address2 }}</span> {{ mortgagee.city }} {{ mortgagee.state }} {{ mortgagee.zip }}</span>
    </li>
  </ul>
  <span class="help-block mortgagee-error"
        *ngIf="(mortgageeForm.get('mortgagee').touched
                    || mortgageeForm.get('mortgagee').dirty)
                    && mortgageeForm.get('mortgagee').errors?.required ">Mortgagee Name is required</span>

  <!--begin internal form-->
  <div *ngIf="this.isReviewing === false && this.creatingNewMortgagee === true" [ngClass]="'add-mortgagee internal-form'">
    <div>
      <div [ngClass]="'orange-banner'">
        <p>Please note that any mortgagee added will require processing time.</p>
      </div>

      <div>
        <label for="addMortgagee" id="addMortgagee">Mortgagee Name:</label> <br />
        <input type="text" [ngClass]="'form-control mortgagee'" formControlName="addMortgagee" required maxlength="100" />
        <span class="help-block inner-form"
              *ngIf="(mortgageeForm.get('addMortgagee').touched
                    || mortgageeForm.get('addMortgagee').dirty)
                    && mortgageeForm.get('addMortgagee').errors?.required ">Mortgagee Name is required</span>
      </div>

      <label for="address1" id="address1">Address Line 1:</label>
      <input type="text" formControlName="address1" required maxlength="100" /><br />
      <span class="help-block"
            *ngIf="(mortgageeForm.get('address1').touched
                || mortgageeForm.get('address1').dirty)
                && mortgageeForm.get('address1').errors?.required ">Address Line 1 is required</span>
    </div>
    <div>
      <label for="address2" id="address2">Address Line 2:</label>
      <input type="text" formControlName="address2" maxlength="100" />
    </div>

    <div>
      <label for="city" id="city">City:</label> <br />
      <input type="text" formControlName="city" required maxlength="100" />
      <span class="help-block"
            *ngIf="(mortgageeForm.get('city').touched || mortgageeForm.get('city').dirty) && mortgageeForm.get('city').errors?.required ">City is required</span>
    </div>
    <div>
      <label for="state">State:</label>
      <select formControlName="state">
        <option value="" disabled selected hidden>
          Select State
        </option>
        <option *ngFor="let state of states" [ngValue]="state.abbreviation">
          {{state.name}}
        </option>
      </select> <br />
      <span [ngClass]="'help-block help spacer-30px'"
            *ngIf="mortgageeForm.get('state').touched && !!mortgageeForm.get('state').errors?.required">
        Select a state
      </span>
    </div>

    <div [ngClass]="'zip'">
      <label for="zip" id="zip">Zip:</label>
      <input *ngIf="this.isReviewing === false" type="text" [ngClass]="'form-control'" formControlName="zip" required maxlength="5" minlength="5"
             oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" /> <br /><br />
      <span class="help-block"
            *ngIf="(mortgageeForm.get('zip').touched
                || mortgageeForm.get('zip').dirty)
                && mortgageeForm.get('zip').errors?.required">Zip is required</span>
      <span class="help-block"
            *ngIf="(mortgageeForm.get('zip').touched
                || mortgageeForm.get('zip').dirty)
                && mortgageeForm.get('zip').errors?.minlength ">Zip must be 5 digits</span>
    </div>

    <div>
      <button *ngIf="this.isReviewing===false" id="reset" (click)="this.clearInternalForm()">Reset</button>
    </div>
  </div>
  <!--end internal form-->

  <label [ngClass]="'position-label'">Position:</label>


  <select *ngIf="this.isReviewing === false" [ngClass]="'position-input'" id="position" formControlName="position" required>
    <option hidden>Select Position</option>
    <option value="1">1</option>
    <option value="2">2</option>
  </select><br />
  <select *ngIf="this.isReviewing === true" [ngClass]="'position-input local'" id="position" formControlName="position" readonly disabled>
    <option value="1">1</option>
    <option value="2">2</option>
  </select><br />
  <span [ngClass]="'help-block position-error'"
        *ngIf="(mortgageeForm.get('position').touched || mortgageeForm.get('position').dirty )
                && mortgageeForm.get('position').invalid">Position is required</span>


  <label [ngClass]="'action-label'">Action:</label>

  <select *ngIf="this.isReviewing === false" [ngClass]="'action-input'" id="action" formControlName="action" required>
    <option hidden>Select Action</option>
    <option value="replace">Replace</option>
    <option value="replaceAll">Replace All</option>
    <option value="add">Add</option>
  </select><br />
  <select *ngIf="this.isReviewing === true" [ngClass]="'action-input local'" id="action" formControlName="action" readonly disabled>
    <option value="replace">Replace</option>
    <option value="replaceAll">Replace All</option>
    <option value="add">Add</option>
  </select><br />
  <span [ngClass]="'help-block action-error'"
        *ngIf="(mortgageeForm.get('action').touched || mortgageeForm.get('action').dirty)
                && mortgageeForm.get('action').invalid">Action is required</span>
  <span [ngClass]="'help-block half action-error'"
        *ngIf="(mortgageeForm.errors?.['InvalidActionPositionCombination'])">Action can only be Replace All if the position is 1</span>

  <label for="loanNumber" [ngClass]="'loan-number-label'">Loan Number:</label>
  <input *ngIf="this.isReviewing === false" type="text" id="loanNumber" maxlength="50" formControlName="loanNumber" required [ngClass]="'loan-number-input'" />
  <input *ngIf="this.isReviewing === true" type="text" id="loanNumber" formControlName="loanNumber" [ngClass]="'loan-number-input local'" />

  <span [ngClass]="'help-block help loan-number-error'"
        *ngIf="(mortgageeForm.get('loanNumber').touched || mortgageeForm.get('loanNumber').dirty) && mortgageeForm.get('loanNumber').errors?.required">Loan Number is required</span>



  <label for="effectiveDate" [ngClass]="'effective-date-label'">Effective Date:</label>
  <input *ngIf="this.isReviewing === false" type="date" id="effectiveDate" formControlName="effectiveDate" required [ngClass]="'effective-date-input'" />
  <input *ngIf="this.isReviewing === true" type="date" id="effectiveDate" formControlName="effectiveDate" [ngClass]="'effective-date-input local'" readonly />

  <span [ngClass]="'help-block help date-error'"
        *ngIf="(mortgageeForm.get('effectiveDate').touched || mortgageeForm.get('effectiveDate').dirty) && mortgageeForm.get('effectiveDate').errors?.required">Effective Date is required</span>
  <span [ngClass]="'help-block help date-error'"
        *ngIf="(mortgageeForm.get('effectiveDate').invalid && (mortgageeForm.get('effectiveDate').dirty && mortgageeForm.get('effectiveDate').touched))">Effective Date cannot be in the past</span>



  <label for="loanAmount" [ngClass]="'loan-amount-label'">Loan Amount:</label>
  <input *ngIf="this.isReviewing === false" [ngClass]="'loan-amount-input'" type="text" id="loanAmount" maxlength="50" prefix="$"
         mask="separator.0" thousandSeparator="," separatorLimit="1000000000" formControlName="loanAmount" />
  <input *ngIf="this.isReviewing === true" [ngClass]="'loan-amount-input local'" type="text" id="loanAmount" prefix="$" formControlName="loanAmount" readonly
         mask="separator.0" thousandSeparator="," separatorLimit="1000000000"/>
  <span [ngClass]="'help-block help loan-amount-error'"
        *ngIf="(mortgageeForm.get('loanAmount').touched || mortgageeForm.get('loanAmount').dirty)
                && mortgageeForm.get('loanAmount').invalid">Loan Amount is required</span>

  <label [ngClass]="'escrowed-label'">Escrowed:</label>

  <label for="yes" [ngClass]="'yes yes-label'">Yes</label>
  <input *ngIf="this.isReviewing === false" type="radio" id="yes" value="true" name="escrow" formControlName="escrow" required [ngClass]="'yes yes-circle custom-radio-button'" />
  <input *ngIf="this.isReviewing === true" type="radio" id="yes" value="true" name="escrow" formControlName="escrow" [ngClass]="'yes yes-circle custom-radio-button'" disabled readonly />
  <span [ngClass]="'help-block help escrow-error'"
        *ngIf="(mortgageeForm.get('escrow').touched || mortgageeForm.get('escrow').dirty) && mortgageeForm.get('escrow').errors?.required">Escrow Status is required</span>


  <label for="no" [ngClass]="'no no-label'">No</label>
  <input *ngIf="this.isReviewing === false" type="radio" id="no" value="false" name="escrow" formControlName="escrow" required [ngClass]="'no no-cirlce custom-radio-button'" />
  <input *ngIf="this.isReviewing === true" type="radio" id="no" value="false" name="escrow" formControlName="escrow" [ngClass]="'no no-cirlce custom-radio-button local'" disabled readonly />
  <span [ngClass]="'help-block help escrow-error'"
        *ngIf="(mortgageeForm.errors?.['InvalidEscrowPositionCombination'])">Escrowed status can only be yes if the mortgagee position is 1</span>

</div>
